import { Transaction } from '@/common/types/transaction';
import {
  PaymentStatusResponse,
  RefundStatus,
} from '@/common/types/transaction.base';
import { css } from '@emotion/react';
import { Box, useTheme } from '@mui/material';
import { PaymentStatus } from '../transaction-types';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import useTransactionUtils from '@/feat/transaction/util';
import { parseISO } from 'date-fns';
import { getStatusScheme } from '@/feat/reservation/utils';

interface IProps {
  original: Transaction | null | undefined;
  hideTime?: boolean;
}

const PaymentStatusBox = (props: IProps) => {
  const { original, hideTime } = props;
  const { formatDate } = useI18nTimeUtils();
  const theme = useTheme();
  const { getTransactionPaymentStatus, generateStatusLabel } =
    useTransactionUtils();

  if (!original) return null;

  const status: PaymentStatusResponse =
    original.status || 'waiting_payment_result';
  const refundStatus: RefundStatus = original?.statusRefund || 'succeeded';
  const type = original.type;
  const time = original.timeRefund
    ? original.timeRefund
    : original.timeStatusLastest;

  const statusText: PaymentStatus = getTransactionPaymentStatus(
    status,
    type,
    refundStatus,
    original.source
  );

  const statusScheme = getStatusScheme(statusText);
  return (
    <Box
      css={css`
        min-width: 105px;
      `}
    >
      <Box
        css={css`
          background-color: ${statusScheme.background};
          color: ${statusScheme.color};
          padding: 0 8px 2px;
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 20px;
          display: inline-block;
          border-radius: 6px;
          white-space: nowrap;
        `}
      >
        {generateStatusLabel(statusText)}
      </Box>
      {!hideTime && (
        <Box
          css={css`
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            color: ${theme.palette.grey[800]};
          `}
        >
          {time &&
            formatDate(parseISO(time), { en: 'd LLL yyyy', zh: 'yyyy LLL do' })}
        </Box>
      )}
    </Box>
  );
};

export default PaymentStatusBox;

import {
  Box,
  Card,
  CardContent,
  CardProps,
  Checkbox,
  FormControlLabel,
  Typography,
  useTheme,
} from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import { TouchTooltip } from '@/common/components/tooltip';
import { useEffect, useMemo } from 'react';
import { useFeatureFlags } from '@/common/hooks/use-feature-flag';

export type CustomerNotificationsProps = {
  emailEnabled?: boolean;
  emailProvided?: boolean;
  emailOnToggle?: (checked: boolean) => void;
  smsEnabled?: boolean;
  smsProvided?: boolean;
  automatedCallEnabled?: boolean;
  smsOnToggle?: (checked: boolean) => void;
  automatedCallOnToggle?: (checked: boolean) => void;
  readOnlyPanel?: boolean;
};

export default function CustomerNotifications({
  emailEnabled = false,
  emailProvided = true,
  emailOnToggle,
  smsEnabled = false,
  automatedCallEnabled = false,
  smsProvided = true,
  smsOnToggle,
  automatedCallOnToggle,
  readOnlyPanel = false,
  ...props
}: CustomerNotificationsProps & CardProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const toolTipNotify =
    smsEnabled || emailEnabled
      ? t(
          'Notifications include reservation confirmation, modification, cancellation and reminder.'
        )
      : t(
          'Notifications for reservation confirmation, modification, cancellation and reminder.'
        );

  useEffect(() => {
    if (emailProvided) {
      emailOnToggle && emailOnToggle(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailProvided]);

  const { confirmationNotificationEnabled } = useFeatureFlags();

  const notifyMethod = useMemo(() => {
    if (!confirmationNotificationEnabled) {
      if (smsEnabled && emailEnabled) {
        return t('SMS and email');
      } else if (smsEnabled) {
        return t('SMS');
      } else if (emailEnabled) {
        return t('Email');
      }
    } else {
      if (smsEnabled && automatedCallEnabled && emailEnabled) {
        return t('SMS, automated call and email');
      } else if (smsEnabled && automatedCallEnabled) {
        return t('SMS and automated call');
      } else if (smsEnabled && emailEnabled) {
        return t('SMS and email');
      } else if (automatedCallEnabled && emailEnabled) {
        return t('automated call and email');
      } else if (smsEnabled) {
        return t('SMS');
      } else if (automatedCallEnabled) {
        return t('automated call');
      } else if (emailEnabled) {
        return t('email');
      }
    }
    return '';
  }, [
    automatedCallEnabled,
    confirmationNotificationEnabled,
    emailEnabled,
    smsEnabled,
    t,
  ]);

  return (
    <Card
      {...props}
      sx={{
        width: '100%',
        boxShadow: theme.customShadows.card,
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1" sx={{ color: '#000000' }}>
            <Trans>Customer Notifications</Trans>
          </Typography>
          <TouchTooltip
            sx={{
              '.MuiTooltip-tooltip': {
                py: 2,
              },
            }}
            title={toolTipNotify}
            arrow
          >
            <HelpTwoToneIcon color="action" sx={{ marginLeft: '9px' }} />
          </TouchTooltip>
        </Box>

        <Typography
          variant="body2"
          sx={{ marginTop: '12px', color: theme.palette.action.active }}
        >
          {smsEnabled || emailEnabled || automatedCallEnabled ? (
            <Trans>
              All notifications will be sent by you via{' '}
              {{
                notifyMethod,
              }}
              .
            </Trans>
          ) : (
            <Trans>No notifications will be sent by you.</Trans>
          )}
        </Typography>

        {!readOnlyPanel && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '10px',
                paddingLeft: '10px',
              }}
            >
              <FormControlLabel
                control={<Checkbox />}
                label={<Trans>SMS (Local Charges Apply)</Trans>}
                disabled={!smsProvided}
                checked={smsProvided && smsEnabled}
                onChange={(e: React.SyntheticEvent) => {
                  smsOnToggle &&
                    smsOnToggle((e?.target as HTMLInputElement)?.checked);
                }}
              />
              {confirmationNotificationEnabled && (
                <FormControlLabel
                  control={<Checkbox />}
                  label={<Trans>Automated call</Trans>}
                  disabled={!smsProvided}
                  checked={smsProvided && automatedCallEnabled}
                  onChange={(e: React.SyntheticEvent) => {
                    automatedCallOnToggle &&
                      automatedCallOnToggle(
                        (e?.target as HTMLInputElement)?.checked
                      );
                  }}
                />
              )}
              <FormControlLabel
                control={<Checkbox />}
                label={<Trans>Email</Trans>}
                disabled={!emailProvided}
                checked={emailProvided && emailEnabled}
                onChange={(e: React.SyntheticEvent) => {
                  emailOnToggle &&
                    emailOnToggle((e?.target as HTMLInputElement)?.checked);
                }}
              />
            </div>
            {!emailProvided && (
              <div style={{ display: 'flex', marginTop: '9px' }}>
                <WarningTwoToneIcon color="warning" />
                <Typography
                  variant="caption"
                  style={{ color: theme.palette.warning.dark }}
                  sx={{ marginLeft: '4px' }}
                >
                  <Trans>Customer’s email has not been provided</Trans>
                </Typography>
              </div>
            )}
          </>
        )}
      </CardContent>
    </Card>
  );
}

import { ResrvSrc } from '@/common/types/reservation-status-flow';
import { ReactElement } from 'react';
import { Trans } from 'next-i18next';

export const titleSource: Record<ResrvSrc, ReactElement> = {
  google: <Trans>Reserved via Google</Trans>,
  restaurant: <Trans>Reserved via Host</Trans>,
  customer: <Trans>Reserved via Web</Trans>,
  walkIn: <Trans>Walk-in</Trans>,
};

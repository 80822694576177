import React from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
} from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import CloseIcon from '@mui/icons-material/Close';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import { Stack } from '@mui/material';

interface IProps {
  onClose: () => void;
  onSubmit: () => void;
  isOpen: boolean;
}

export const ConfirmationResendReservation = ({
  onClose,
  onSubmit,
  isOpen,
}: IProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      sx={{
        '& > .MuiBackdrop-root': {
          background: 'rgba(22, 28, 36, 0.72)',
        },
        // '& .MuiDialog-paper': {
        //   maxWidth: '900px',
        // },
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle sx={{ px: 3, pb: '0 !important' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ fontSize: 28 }} />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <WarningTwoToneIcon
            sx={{
              color: 'warning.main',
              fontSize: 50,
              alignSelf: 'center',
              mb: 2,
            }}
          />

          <Typography
            variant="h5"
            sx={{ alignSelf: 'center', display: 'flex' }}
          >
            <Trans>Do you want to resend payment link?</Trans>
          </Typography>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          pb: 0,
        }}
      >
        <Typography
          variant="body1"
          sx={{ color: 'grey.600', textAlign: 'center' }}
        >
          <Trans>
            Clicking "Resend" will creates a new reservation. <br />
            Please avoid overlapping bookings to prevent conflicts.
          </Trans>
        </Typography>
      </DialogContent>

      <DialogActions>
        <Stack sx={{ flexDirection: 'row', width: '100%' }} gap={2}>
          <Button
            variant="outlined"
            sx={{ color: 'grey.800', width: '50%' }}
            onClick={onClose}
          >
            {t('Cancel')}
          </Button>
          <Button
            color="primary"
            onClick={onSubmit}
            sx={{
              width: '50%',
            }}
          >
            {t('Resend')}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

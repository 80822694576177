import { atom, useAtom, useSetAtom } from 'jotai';
import { ReservationItem } from '@/common/types/reservation';
import ReservationChargeOrRefundConfirmation, {
  ResChargeOrRefundReason,
  ResChargeOrRefundType,
} from './charge-or-refund-reservation-confirmation';

export type ResChargeOrRefundConfirmPayload = {
  type: ResChargeOrRefundType;
  reservation: ReservationItem;
  onClose?: () => void;
  onSubmit?: (reason?: ResChargeOrRefundReason) => void;
};
const resChargeOrRefundConfirmPayloadAtom =
  atom<ResChargeOrRefundConfirmPayload | null>(null);
export const useShowResChargeOrRefundConfirmModal = () => {
  const setPayload = useSetAtom(resChargeOrRefundConfirmPayloadAtom);
  return (payload: ResChargeOrRefundConfirmPayload) =>
    new Promise<
      [action: 'ABORTED' | 'CONFIRMED', reason?: ResChargeOrRefundReason]
    >((resolve) => {
      setPayload({
        ...payload,
        onClose: () => {
          payload.onClose?.();
          resolve(['ABORTED']);
          setPayload(null);
        },
        onSubmit: (reason) => {
          payload.onSubmit?.(reason);
          resolve(['CONFIRMED', reason]);
          setPayload(null);
        },
      });
    });
};
export default function GlobalChargeOrRefundResConfirmModal() {
  const [payload] = useAtom(resChargeOrRefundConfirmPayloadAtom);
  if (!payload) return null;
  return (
    <ReservationChargeOrRefundConfirmation
      type={payload.type || 'REFUND'}
      reservation={payload.reservation}
      onClose={payload.onClose || function () {}}
      onSubmit={payload.onSubmit || function () {}}
    />
  );
}

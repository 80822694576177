import { css } from '@emotion/react';
import Typography from '@mui/material/Typography';
import { ReservationItem } from '@/common/types/reservation';
import { ReactNode, useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'next-i18next';
import {
  Box,
  Divider,
  Grid,
  useTheme,
  Stack,
  Button,
  Chip,
} from '@mui/material';
import {
  ticketColor,
  TICKET_TYPE_PAYMENT,
  PRTicketTypePaymentInt,
} from '@/common/types/ticket.base';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { occasionLabels } from '../../state';
import { totalPaidReservationPayment } from '../../utils';
import { isPRTicket } from '@/common/types/ticket';
import { MerchantReservation } from '@/common/types/merchant-reservation';
import { useMoneyFmt } from '@/common/hooks/use-money-fmt';
import PaymentStatusBox from '@/feat/transaction/components/payment-status';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { TouchTooltip } from '@/common/components/tooltip';
import { usePwaOrMobileView } from '@/common/hooks/use-pwa';
import { ViewReservationModalProps } from '@/feat/reservation/components/view-reservation-modal';

export function ReservationDetailPanel({
  reservationData,
  dataMerchantReservation,
  onEdit,
}: {
  reservationData: ReservationItem;
  dataMerchantReservation?: MerchantReservation;
  onEdit?: ViewReservationModalProps['onEdit'];
}) {
  const { moneyFmt } = useMoneyFmt();
  const { t } = useTranslation();
  const theme = useTheme();
  const { formatLongDateTime } = useI18nTimeUtils();
  const { isMobileView } = usePwaOrMobileView();

  const occasionSection = useMemo(() => {
    const occasionsFilter = (reservationData?.occasions || '')
      .split(', ')
      .filter(
        (ocs) =>
          dataMerchantReservation?.occasions?.findIndex(
            (occasionData) => occasionData.name === ocs
          ) !== -1
      )
      .map((ocs) => occasionLabels(ocs));

    return [
      ...occasionsFilter,
      !!reservationData?.otherOccasions &&
        `${t('Others')}: ${reservationData.otherOccasions}`,
    ]
      .filter(Boolean)
      .flatMap((value, index, array) => {
        if (index === array.length - 1) {
          return [value];
        }
        return [value];
      });
  }, [reservationData, dataMerchantReservation, t]);

  const reservationTags = useMemo(() => {
    if (!reservationData?.tags) return [];
    return reservationData.tags.map((tag) => tag.title);
  }, [reservationData]);

  const formattedTime = useCallback((time: number) => {
    const hours = time / 3600;
    return `${Number.isInteger(hours) ? hours : hours.toFixed(1)}h`;
  }, []);

  const renderPaidTicket = useMemo(() => {
    const ticket = reservationData?.ticket;
    if (!isPRTicket(ticket)) return null;

    const labels: Record<PRTicketTypePaymentInt, ReactNode> = {
      [TICKET_TYPE_PAYMENT['Deposit']]: t('Deposit'),
      [TICKET_TYPE_PAYMENT['Prepaid']]: t('Prepaid'),
      [TICKET_TYPE_PAYMENT['CardGuarantee']]: t('Card Guarantee'),
    };
    const labelTicketPayment = labels[ticket.typePayment];

    return (
      <>
        <Typography
          variant="body2"
          sx={{ color: 'grey.600', marginLeft: '20px' }}
        >
          {moneyFmt(
            totalPaidReservationPayment({
              pax: reservationData?.pax || 0,
              price: ticket.price,
              typePrice: ticket.typePrice,
            })
          )}{' '}
          {labelTicketPayment}
        </Typography>
      </>
    );
  }, [moneyFmt, reservationData?.pax, reservationData?.ticket, t]);

  const chipStyles = {
    mr: 1,
    padding: '3px',
    background: theme.palette.grey[500_16],
    borderRadius: '6px',
    width: 'fit-content',
    '& .MuiChip-label': {
      fontWeight: 400,
      color: theme.palette.grey[700],
    },
    '& .MuiSvgIcon-root': {
      fontSize: 18,
    },
  };

  return (
    <Box
      css={css`
        padding: 16px;
        box-shadow: ${theme.customShadows.card};
        border-radius: 16px;

        .MuiTypography-overline {
          letter-spacing: 1.2px;
          text-transform: uppercase;
          color: #919eab;
        }
      `}
    >
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2,
        }}
      >
        <Typography variant="subtitle1">
          {reservationData?.isWalkIn ? (
            <Trans>Walk-in Details</Trans>
          ) : (
            <Trans>Reservation Details</Trans>
          )}
        </Typography>
        {isMobileView && (
          <TouchTooltip
            title={
              !reservationData?.isEditable ? (
                reservationData?.rTimeState === 'PAST' ? (
                  <Trans>
                    Reservation is in the past and cannot be edited.
                  </Trans>
                ) : (
                  <Trans>
                    To edit reservation, update reservation status first.
                  </Trans>
                )
              ) : (
                ''
              )
            }
            arrow
            enterTouchDelay={0}
          >
            <div>
              <Button
                variant="text"
                startIcon={<EditTwoToneIcon />}
                disabled={!reservationData?.isEditable}
                sx={{ width: 50 }}
                onClick={() => {
                  onEdit && onEdit(reservationData?.id || '');
                }}
              >
                {t('Edit')}
              </Button>
            </div>
          </TouchTooltip>
        )}
      </Stack>
      {reservationData?.ticket && (
        <Box
          css={css`
            margin-bottom: 16px;
          `}
        >
          <Box
            css={css`
              display: flex;
              align-items: center;
              justify-content: flex-start;
            `}
          >
            <Box
              component="span"
              css={css`
                display: block;
                margin-right: 8px;
                height: 12px;
                width: 12px;
                background: ${ticketColor(reservationData?.ticket?.color)
                  .swatch};
                border-radius: 50%;
              `}
            />
            <Typography
              variant="body2"
              css={css`
                max-width: calc(100% - 20px);
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
              `}
            >
              {reservationData?.ticket?.name
                ? reservationData?.ticket?.name
                : '-'}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-end',
            }}
          >
            {renderPaidTicket}
            {reservationData?.ticket?.typePayment !==
              TICKET_TYPE_PAYMENT['No Payment'] && (
              <Box sx={{ ml: 2 }}>
                <PaymentStatusBox
                  original={reservationData?.transaction}
                  hideTime
                />
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Grid
        container
        spacing={5}
        sx={{
          marginTop: 'unset',
          '>.MuiGrid-item': {
            paddingTop: 'unset',
          },
          marginBottom: '16px',
        }}
      >
        <Grid item xs={12} sm={6}>
          <Typography variant="overline">
            <Trans>Date and Time</Trans>
          </Typography>
          <Typography variant="body2" sx={{ marginTop: '8px' }}>
            {reservationData?.rDateTime && (
              <>
                {formatLongDateTime(reservationData.rDateTime)}
                {reservationData?.diningInterval &&
                  ` (${formattedTime(reservationData?.diningInterval || 0)})`}
              </>
            )}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={6} sx={{ marginTop: 1 }}>
              <Typography variant="overline">
                <Trans>Pax</Trans>
              </Typography>
              <Typography variant="body2" sx={{ marginTop: '8px' }}>
                {reservationData?.pax ? reservationData?.pax : '-'}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ marginTop: 1 }}>
              <Typography variant="overline">
                <Trans>Table No</Trans>.
              </Typography>
              <Typography variant="body2" sx={{ marginTop: '8px' }}>
                {Array.isArray(reservationData?.tables) &&
                !!reservationData?.tables?.length
                  ? reservationData?.tables.map((item) => item?.name).join(', ')
                  : '-'}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Box
        css={css`
          padding: 16px 0;
        `}
      >
        <Typography variant="overline">
          <Trans>Occasions</Trans>
        </Typography>
        <Box sx={{ mt: 2 }}>
          {occasionSection.length
            ? occasionSection.map((occasion, index) => (
                <Chip
                  size="small"
                  key={index}
                  label={occasion}
                  sx={chipStyles}
                />
              ))
            : '-'}
        </Box>
      </Box>
      <Divider />
      <Box
        css={css`
          padding: 16px 0;
        `}
      >
        <Typography variant="overline">
          <Trans>Reservation Tag</Trans>
        </Typography>
        <Box sx={{ mt: 2 }}>
          {!!reservationTags.length
            ? reservationTags.map((tagTitle, index) => (
                <Chip
                  size="small"
                  key={index}
                  label={tagTitle}
                  sx={chipStyles}
                />
              ))
            : '-'}
        </Box>
      </Box>
      <Divider />
      <Box
        css={css`
          padding: 16px 0;
        `}
      >
        <Typography variant="overline">
          {' '}
          <Trans>Customer’s Special Request</Trans>
        </Typography>
        <Typography variant="body2" sx={{ marginTop: '8px' }}>
          {reservationData?.notes ? reservationData?.notes : '-'}
        </Typography>
      </Box>
      <Divider />
      <Box
        css={css`
          padding-top: 16px;
        `}
      >
        <Typography variant="overline">
          <Trans>Staff Notes</Trans>
        </Typography>
        <Typography variant="body2" sx={{ marginTop: '8px' }}>
          {reservationData?.staffNotes ? reservationData?.staffNotes : '-'}
        </Typography>
      </Box>
    </Box>
  );
}

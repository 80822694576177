import { ComplexButton, SecondaryButton } from '@/common/components/buttons';
import {
  Box,
  Card,
  Divider,
  FormHelperText,
  Grid,
  InputAdornment,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  Backdrop,
  Button,
  IconButton,
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import MenuPopover from '@/minimals.cc/components/MenuPopover';

import React, { forwardRef, memo, useEffect, useMemo, useState } from 'react';
import { add, parse, set } from 'date-fns';
import {
  filterPriorityTablesWithSchedule,
  getProcessedTable,
  handleFilterTableErrors,
} from '@/feat/reservation/components/add-reservation/assign-table-menu/utils';
import {
  SelectedTable,
  TableError,
  TableInAssignMenu,
} from '@/feat/reservation/components/add-reservation/assign-table-menu/type';
import { Trans, useTranslation } from 'next-i18next';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import { Area } from '@/common/types/area';
import useCallbackRef from '@/common/hooks/use-callback-ref';
import Label from '@/common/components/label';
import { ZReservationItem } from '@/common/types/reservation';
import { useTablesLayout } from '@/common/hooks/use-table-dashboard-layout';
import { ServiceTiming } from '@/common/types/schedule';
import { useUnavailTableIds } from '@/feat/affected-reservations/atoms';
import { ReservationType } from '../../action-reservation-modal';
import { Person } from '@mui/icons-material';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { assertUnreachable } from '@/common/utils';
import { getBlockStartEndTime } from '@/common/components/table-block';
import { useFeatureFlags } from '@/common/hooks/use-feature-flag';
import { usePwaOrMobileView } from '@/common/hooks/use-pwa';
import { useGetGroupPaxCombinations } from '@/common/hooks/use-table-combinations';

export type AssignTableMenuProps = {
  areas: Area[];
  time?: string;
  date?: string;
  paxCount: number;
  selectedDiningInterval: number;
  selectedTables: SelectedTable[] | null;
  selectTables: (tables: SelectedTable[]) => void;
  error?: string;
  isLoading?: boolean;
  disabled?: boolean;
  selectedServiceTiming?: ServiceTiming | null;
  editedReservationId?: string;
  /**
   * param to check if editedReservationId is affected reservation
   */
  isAffectedReservation?: boolean;
  reservationType?: ReservationType;
};

const WarningMessage = ({
  error,
  formatDateInView,
}: {
  error: TableError;
  formatDateInView: (
    date: Date | number,
    formats: { en: string; zh?: string }
  ) => string;
}) => {
  const { source, value, conflictValue } = error;
  let warnText = null;
  const { t } = useTranslation();
  const { isEnableTableCombination } = useFeatureFlags();

  switch (source) {
    case 'maxPax': {
      warnText = (
        <>
          {isEnableTableCombination
            ? t(`You will have {{ pax }} additional vacant seats.`, {
                pax: conflictValue,
              })
            : t(
                `Party size of {{ pax }} pax exceeds table’s maximum
            capacity of {{ tableMaxPax }} pax.`,
                {
                  pax: conflictValue,
                  tableMaxPax: value,
                }
              )}
        </>
      );
      break;
    }
    case 'minPax': {
      warnText = (
        <>
          {isEnableTableCombination
            ? t(`Short of {{ pax }} seats. Please select more tables.`, {
                pax: conflictValue,
              })
            : t(
                `Party size of {{ pax }} pax is below table’s minimum
                capacity of {{ tableMinPax }} pax.`,
                {
                  pax: conflictValue,
                  tableMaxPax: value,
                }
              )}
        </>
      );
      break;
    }
    case 'clashed': {
      const reservationTime = formatDateInView(value as Date, { en: 'h:mm a' });
      warnText = (
        <Trans>
          Table selected has an upcoming reservation at {{ reservationTime }},
          adding a reservation will result in a clash.
        </Trans>
      );
      break;
    }
    case 'occupied': {
      const reservationTime = formatDateInView(value as Date, { en: 'h:mm a' });
      warnText = (
        <Trans>
          Table selected is occupied until {{ reservationTime }}, seating
          customer will result in a clash.
        </Trans>
      );
      break;
    }
    case 'outsideAreasSchedule': {
      warnText = (
        <Trans>
          Only tables in {{ value }} areas are bookable options online.
        </Trans>
      );
      break;
    }
    case 'blocked': {
      warnText = (
        <Trans>
          Selected table is currently blocked out, proceed to accept clash.
        </Trans>
      );
      break;
    }
    default:
      assertUnreachable(source);
      break;
  }

  return warnText;
};

const renderEmptyState = (
  <Box
    sx={{
      backgroundColor: '#F9FAFB',
      height: '94px',
      borderRadius: '16px',
      margin: '8px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Typography variant="subtitle1" sx={{ color: '#637381' }}>
      <Trans>You don’t have any tables yet</Trans>
    </Typography>
    <Typography variant="caption" sx={{ marginTop: '4px', color: '#919EAB' }}>
      <Trans>Ensure that you have tables set up in the settings page</Trans>
    </Typography>
  </Box>
);

const AssignTableMenu = forwardRef(function AssignTableMenu(
  props: AssignTableMenuProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const {
    areas,
    time,
    date,
    paxCount,
    selectedDiningInterval,
    selectedTables,
    selectTables,
    error,
    isLoading = false,
    disabled,
    selectedServiceTiming,
    editedReservationId,
    isAffectedReservation,
    reservationType,
  } = props;
  const { t } = useTranslation();
  // const locale = useLocaleCfg();
  const { formatDate } = useI18nTimeUtils();
  const theme = useTheme();
  const [openEl, setOpenEl] = useState<HTMLElement | null>(null);
  const open = Boolean(openEl);
  const matchIpadScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { isEnableTableCombination } = useFeatureFlags();
  const { isMobileView } = usePwaOrMobileView();

  const { data: listPartySize } = useGetGroupPaxCombinations({
    params: {
      sort: 'asc',
      typeSort: 'pax',
    },
    config: {
      keepPreviousData: true,
      enabled: !!isEnableTableCombination,
    },
  });

  const { data: tablesInLayout, isFetching: isFetchingTablesLayout } =
    useTablesLayout({
      date: date || '',
      config: {
        enabled: !!date,
      },
    });

  const selectedTimeslot = useMemo(() => {
    if (!(date && time)) {
      return;
    }

    const [hours, minutes] = time.split(':');
    if (!hours || !minutes) {
      return;
    }

    return set(parse(date, 'yyyy-MM-dd', new Date()), {
      hours: parseInt(hours),
      minutes: parseInt(minutes),
    });
  }, [time, date]);
  const unavailTableIds = useUnavailTableIds(selectedTimeslot);

  const [processedTablesData, tableSortedByPriority] = useMemo(() => {
    let filteredAreas = areas.filter(
      (area) => area.tables && area.tables.length
    );
    const sortedByPriorityArea = filteredAreas.sort(
      (a: Area, b: Area) => a.order! - b.order!
    );

    const processedTablesData = sortedByPriorityArea.map((area) => {
      const tablesConverted = area?.tables?.map((table) => {
        const tableFound = tablesInLayout?.find(
          (tableItem) => tableItem.tableId === table.id
        );

        const newReservation = !!editedReservationId
          ? tableFound?.reservations
              ?.filter((resrv) => resrv?.id !== editedReservationId)
              ?.map((resrv) => ZReservationItem.parse(resrv))
          : tableFound?.reservations?.map((resrv) =>
              ZReservationItem.parse(resrv)
            );

        return {
          ...table,
          reservations: newReservation || [],
        };
      });

      const isAreaOfSchedule =
        !!selectedServiceTiming &&
        selectedServiceTiming?.areas?.some(
          (areaService) => areaService?.id === area?.areaId
        );

      return {
        ...area,
        tables: getProcessedTable(
          formatDate,
          tablesConverted,
          paxCount,
          selectedDiningInterval,
          area,
          selectedTimeslot,
          isAreaOfSchedule
        ),
      };
    });

    const tableArray = processedTablesData?.map((area) => area.tables).flat();

    const tablesFilterWithSchedule =
      filterPriorityTablesWithSchedule(tableArray);

    const tableSortedByPriority = tablesFilterWithSchedule
      .filter((table) => !table.isBlocked)
      .sort(
        (tableA, tableB) => tableA.priorityByState - tableB.priorityByState
      );

    return [processedTablesData, tableSortedByPriority];
  }, [
    areas,
    selectedServiceTiming,
    formatDate,
    paxCount,
    selectedDiningInterval,
    selectedTimeslot,
    tablesInLayout,
    editedReservationId,
  ]);

  const handleOpenTableMenu = (event: React.MouseEvent<HTMLElement>) => {
    setOpenEl(event.currentTarget);
  };

  const handleCloseTableMenu = () => {
    setOpenEl(null);
  };

  const getSelectedTableError = useCallbackRef((table: TableInAssignMenu) => {
    const errors: TableError[] = [];

    if (table.isClashed) {
      errors.push({
        source: 'clashed',
        value: table.nextReservation?.rDateTime,
      });
    }

    if (table.isOccupied) {
      errors.push({
        source: 'occupied',
        value: table.nextReservation?.rLimitTime,
      });
    }

    if (
      !!selectedServiceTiming &&
      selectedServiceTiming?.areas?.length &&
      !selectedServiceTiming?.isAllArea &&
      !table.isAreaOfSchedule
    ) {
      const areasText = selectedServiceTiming?.areas
        ?.map((area) => area.name || area.displayName)
        ?.join(',');
      errors.push({
        source: 'outsideAreasSchedule',
        value: areasText,
      });
    }

    const endTime = selectedTimeslot
      ? add(selectedTimeslot, {
          seconds: selectedDiningInterval,
        })
      : null;

    const isBlockedTable =
      selectedTimeslot && endTime
        ? table.tableBlocks?.some((b) => {
            const { start: bStart, end: bEnd } = getBlockStartEndTime(b);
            return selectedTimeslot < bEnd && endTime > bStart;
          })
        : false;

    const area = areas.find((area) =>
      area.tables?.some((t) => t.id === table.id)
    );

    const isBlockedArea =
      selectedTimeslot && endTime && area
        ? area.areaBlocks?.some((b) => {
            const { start: bStart, end: bEnd } = getBlockStartEndTime(b);
            return selectedTimeslot < bEnd && endTime > bStart;
          })
        : false;

    if (!table.enabled || isBlockedTable || isBlockedArea) {
      errors.push({ source: 'blocked' });
    }

    return errors;
  });

  const handleSelectTable = useCallbackRef(
    (table: TableInAssignMenu, isAutoAssigned: boolean = false) => {
      const _selectedTable = {
        id: table.id,
        name: table.name,
        maxPax: table.maxPax,
        isAutoAssigned,
        errors: getSelectedTableError(table),
      };

      const isExistedTable = selectedTables?.some(
        (table) => table?.id === _selectedTable?.id
      );

      if (isExistedTable) return;

      if (!isEnableTableCombination) {
        selectTables &&
          selectTables(
            ([] as SelectedTable[]).concat(_selectedTable as SelectedTable)
          );
        return;
      }
      const newListSelectedTables = ([] as SelectedTable[])
        .concat(selectedTables as SelectedTable[])
        .concat(_selectedTable)
        .filter(Boolean);

      selectTables && selectTables(newListSelectedTables);
    }
  );

  const handleClickTable = useCallbackRef((table: TableInAssignMenu) => {
    const tableIsSelected = selectedTables?.some(
      (item) => item?.id === table?.id
    );

    if (!tableIsSelected) {
      handleSelectTable(table);
      return;
    }

    selectTables(
      selectedTables?.filter((item) => item?.id !== table?.id) || []
    );
  });

  const handleAutoAssign = () => {
    // Clear previously selected tables
    selectTables([]);

    // Check if there is a single table that can accommodate the entire paxCount
    const singleTable = tableSortedByPriority.find(
      (table) => table.maxPax >= paxCount && table.enabled && !table.isClashed
    );

    if (singleTable) {
      selectTables([
        {
          ...singleTable,
          isAutoAssigned: true,
          errors: getSelectedTableError(singleTable),
        },
      ]);
      return;
    }

    // Check table combinations if no single table is found
    if (isEnableTableCombination) {
      const matchedPartySize = listPartySize?.find(
        (item) => item.pax === paxCount
      );

      if (matchedPartySize && matchedPartySize.groupCombinations) {
        for (let combination of matchedPartySize.groupCombinations) {
          if (combination && combination.tables) {
            const matchedTables = combination.tables
              .filter((table) => {
                const priorityTable = tableSortedByPriority.find(
                  (pt) => pt.id === table.id
                );
                return priorityTable && !unavailTableIds.includes(table.id);
              })
              .map((table) => {
                const priorityTable = tableSortedByPriority.find(
                  (pt) => pt.id === table.id
                );
                return {
                  ...table,
                  isAutoAssigned: true,
                  errors: priorityTable
                    ? getSelectedTableError(priorityTable)
                    : [],
                };
              });

            const allTableBookableOnline = matchedTables.every(
              (table) => table.bookableOnline
            );

            if (
              matchedTables.length === combination.tables.length &&
              allTableBookableOnline
            ) {
              selectTables(matchedTables);
              return;
            }
          }
        }
      }
    }

    // Fallback to default logic if no table combination is found or matched tables are not available
    const availableTables = tableSortedByPriority.filter(
      (table) => !unavailTableIds.includes(table.id) && !table.isBlocked
    );

    const selectedTables = [];
    let totalPax = 0;

    for (const table of availableTables) {
      selectedTables.push({
        ...table,
        isAutoAssigned: true,
        errors: getSelectedTableError(table),
      });
      totalPax += table.maxPax;
      if (totalPax >= paxCount) break;
    }

    if (selectedTables.length > 0) {
      selectTables(selectedTables);
    } else {
      const firstAvailableTable = tableSortedByPriority.find(
        (table) => !unavailTableIds.includes(table.id)
      );

      if (firstAvailableTable) {
        handleSelectTable(firstAvailableTable, true);
      }
    }
  };

  const handleRrsvPropsChange = useCallbackRef(() => {
    if (date && time) {
      if (
        !selectedTables ||
        selectedTables?.some((table) => table?.isAutoAssigned)
      ) {
        // if edit affected reservation, NO need to call auto assign
        if (isAffectedReservation && editedReservationId) {
          return;
        }
        handleAutoAssign();
      } else {
        const newTable = tableSortedByPriority?.find(
          (table) => table?.id === selectedTables?.[0]?.id
        );
        if (newTable) {
          handleSelectTable(newTable);
        }
      }
    }
  });
  useEffect(() => {
    if (
      !isLoading &&
      processedTablesData.length &&
      tableSortedByPriority.length
    )
      handleRrsvPropsChange();
  }, [
    handleRrsvPropsChange,
    isLoading,
    processedTablesData,
    tableSortedByPriority,
  ]);

  const isAutoAssignButtonDisabled = useMemo(() => {
    const firstAvailableTable = tableSortedByPriority.filter(
      (table) => !unavailTableIds.includes(table.id)
    )[0];
    return (
      !tableSortedByPriority?.length ||
      (reservationType === 'Reservation' && (!date || !time)) ||
      selectedTables?.[0]?.id === firstAvailableTable?.id
    );
  }, [
    tableSortedByPriority,
    selectedTables,
    date,
    time,
    reservationType,
    unavailTableIds,
  ]);

  const tableGrid = (
    <Box
      // NOTE:  max popover height => height of body container - 8px top and 8px bottom
      sx={{
        pt: 2,
        px: 2,
        maxHeight: 'calc(100vh - 72px - 60px - 68px - 16px)',
        overflowY: 'auto',
      }}
    >
      {processedTablesData.map((area, i) => (
        <Box key={i} mb={2}>
          <Box mb={2}>
            <Typography
              variant="overline"
              sx={{ color: theme.palette.grey[600] }}
            >
              {area?.name || area?.displayName}
            </Typography>
          </Box>

          <Grid container spacing={1} rowSpacing={1}>
            {area.tables.map((table: TableInAssignMenu) => (
              <Grid
                item
                key={table.id}
                sx={{
                  flexBasis: 'calc(100% / 3)',
                  maxWidth: 'calc(100% / 3)',
                  '@media (min-width: 1151px)': {
                    flexBasis: 'calc(100% / 4)',
                    maxWidth: 'calc(100% / 4)',
                  },
                }}
              >
                <ComplexButton
                  sx={{
                    '&': {
                      height: '64px',
                      width: '100%',
                      minWidth: '96px',
                    },
                    h6: {
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      width: '100%',
                      paddingRight: 'unset',
                      paddingLeft: 'unset',
                    },
                  }}
                  onClick={() => handleClickTable(table)}
                  selected={
                    !Array.isArray(selectedTables) || !selectedTables?.length
                      ? false
                      : selectedTables?.some((item) => item?.id === table?.id)
                  }
                  value={table.id}
                  primary={table.name}
                  secondary={table.tableStateComponent}
                  secondaryWarn={table.isOccupied || table.isClashed}
                  topRightIcon={
                    <Box display="flex" alignItems="center">
                      <Person />
                      {table.maxPax}
                    </Box>
                  }
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      ))}
    </Box>
  );

  const warnRender = () => {
    if (!isEnableTableCombination) {
      let errors = [] as TableError[];
      if (Number(selectedTables?.[0]?.maxPax) > paxCount) {
        errors?.push({
          source: 'maxPax',
          value: selectedTables?.[0]?.maxPax,
          conflictValue: Number(selectedTables?.[0]?.maxPax) - paxCount,
        });
      }

      if (Number(selectedTables?.[0]?.maxPax) < paxCount) {
        errors?.push({
          source: 'minPax',
          value: selectedTables?.[0]?.maxPax,
          conflictValue: paxCount - Number(selectedTables?.[0]?.maxPax),
        });
      }

      if (selectedTables && selectedTables?.[0]?.errors?.length)
        errors = ([] as TableError[])
          .concat(errors as TableError[])
          .concat(selectedTables?.[0]?.errors as TableError[]);

      return (
        <Stack>
          {errors.map((error, index) => (
            <Stack
              key={index}
              direction="row"
              alignItems="flex-start"
              sx={{
                mt: 1,
                color: 'warning.dark',
              }}
            >
              <WarningTwoToneIcon sx={{ color: 'warning.main', mr: '5px' }} />
              <Typography variant="caption">
                <WarningMessage error={error} formatDateInView={formatDate} />
              </Typography>
            </Stack>
          ))}
        </Stack>
      );
    }
    let listTableErrorForEditAction: SelectedTable[] = [];
    if (editedReservationId) {
      const tableArray = processedTablesData?.map((area) => area.tables).flat();

      tableArray?.forEach((item) => {
        const isSelected = selectedTables?.some(
          (table) => table?.id === item?.id
        );
        if (isSelected)
          listTableErrorForEditAction.push({
            id: item.id,
            name: item.name,
            maxPax: item.maxPax,
            errors: getSelectedTableError(item),
            isAutoAssigned: false,
          });
      });
    }

    let listTableErrors = (
      editedReservationId ? listTableErrorForEditAction : selectedTables
    )?.reduce((acc, cur) => {
      if (!cur?.errors?.length) return acc;
      return ([] as TableError[]).concat(acc).concat(cur?.errors);
    }, [] as TableError[]);

    let totalPaxSelected = 0;
    selectedTables?.forEach((table) => {
      if (!!table?.maxPax) totalPaxSelected += table?.maxPax;
    });

    if (totalPaxSelected > paxCount) {
      listTableErrors?.push({
        source: 'maxPax',
        value: totalPaxSelected,
        conflictValue: totalPaxSelected - paxCount,
      });
    }

    if (totalPaxSelected < paxCount) {
      listTableErrors?.push({
        source: 'minPax',
        value: totalPaxSelected,
        conflictValue: paxCount - totalPaxSelected,
      });
    }
    const tableErrors: TableError[] =
      handleFilterTableErrors(listTableErrors as TableError[]) || [];

    if (!Array.isArray(tableErrors) || !tableErrors.length) return null;

    return (
      <Stack>
        {tableErrors?.map((error, index) => (
          <Stack
            key={index}
            direction="row"
            alignItems="flex-start"
            sx={{
              mt: 1,
              color: 'warning.dark',
            }}
          >
            {error?.source === 'maxPax' ? (
              <InfoTwoToneIcon sx={{ color: 'secondary.main', mr: '5px' }} />
            ) : (
              <WarningTwoToneIcon sx={{ color: 'warning.main', mr: '5px' }} />
            )}

            <Typography
              variant="caption"
              sx={{
                ...(error?.source === 'maxPax' && {
                  color: 'secondary.main',
                }),
              }}
            >
              <WarningMessage error={error} formatDateInView={formatDate} />
            </Typography>
          </Stack>
        ))}
      </Stack>
    );
  };

  return (
    <Box>
      <Typography
        variant="overline"
        sx={{ color: theme.palette.grey[500], letterSpacing: '1.2px' }}
      >
        {t('TABLE NO.')}
      </Typography>
      {isLoading || isFetchingTablesLayout ? (
        <Skeleton variant="rounded" width="100%" height={56} />
      ) : (
        <>
          <TextField
            fullWidth
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {selectedTables?.some((table) => table?.isAutoAssigned) && (
                    <Label variant="ghost" sx={{ fontWeight: 700 }}>
                      {t('Auto-assigned')}
                    </Label>
                  )}
                  <ChevronRightIcon
                    sx={{
                      fontSize: 24,
                      color: theme.palette.grey[600],
                      marginLeft: '8px',
                    }}
                  />
                </InputAdornment>
              ),
              readOnly: matchIpadScreen,
            }}
            value={selectedTables?.map((table) => table?.name).join(', ')}
            placeholder={t('Select table')}
            error={!!error}
            disabled={disabled}
            sx={{
              marginTop: '16px',
              '.MuiInputBase-input::placeholder': {
                color: `${
                  error ? theme.palette.error.main : theme.palette.grey['500']
                }`,
              },
            }}
            onClick={handleOpenTableMenu}
            inputRef={ref}
          />
          {!!error && <FormHelperText error>{error}</FormHelperText>}

          {warnRender()}

          <MenuPopover
            arrow="left-center"
            open={open}
            anchorEl={openEl}
            onClose={handleCloseTableMenu}
            anchorOrigin={
              isMobileView
                ? {
                    vertical: 'center',
                    horizontal: 'center',
                  }
                : {
                    vertical: 'center',
                    horizontal: 'right',
                  }
            }
            transformOrigin={
              isMobileView
                ? {
                    vertical: 'center',
                    horizontal: 'center',
                  }
                : {
                    vertical: 'center',
                    horizontal: 'left',
                  }
            }
            sx={{
              p: 0,
              width: isMobileView ? '100%' : 440,
              height: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',

              '& .MuiPaper-root': {
                border: 'unset',
                width: isMobileView ? '100%' : 'auto',
              },
              '@media (min-width: 1151px)': {
                width: isMobileView ? '100%' : 472,
              },
              ...(isMobileView && {
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }),
            }}
          >
            <Card
              variant="outlined"
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {areas?.length ? (
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      padding: 2,
                    }}
                  >
                    <Typography variant="subtitle1">
                      {t('Assign Table')}
                    </Typography>

                    <Box>
                      <SecondaryButton
                        disabled={isAutoAssignButtonDisabled}
                        onClick={handleAutoAssign}
                        sx={{ padding: '5px 12px' }}
                      >
                        <Typography variant="button">
                          {t('Auto-Assign')}
                        </Typography>
                      </SecondaryButton>
                      {isMobileView && (
                        <IconButton
                          sx={{ ml: 1 }}
                          onClick={() => setOpenEl(null)}
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                    </Box>
                  </Box>

                  <Divider sx={{ mx: 2 }} />

                  {tableGrid}
                </>
              ) : (
                renderEmptyState
              )}
            </Card>
            {isMobileView && (
              <Box sx={{ py: 2, justifyContent: 'flex-end', display: 'flex' }}>
                <Button
                  disabled={selectedTables?.length === 0}
                  onClick={() => setOpenEl(null)}
                  sx={{ padding: '5px 12px', mr: 4 }}
                >
                  <Typography variant="button">{t('Select Table')}</Typography>
                </Button>
              </Box>
            )}
          </MenuPopover>
          {isMobileView && (
            <Backdrop
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 1,
                color: '#80808060',
              }}
              open={open}
              onClick={handleCloseTableMenu}
            />
          )}
        </>
      )}
    </Box>
  );
});

export default memo(AssignTableMenu);

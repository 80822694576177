import MenuPopover from '@/minimals.cc/components/MenuPopover';
import { Trans } from 'next-i18next';
import Divider from '@mui/material/Divider';
import {
  Box,
  Grid,
  styled,
  ToggleButton,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useMemo, useState } from 'react';
import { usePwaOrMobileView } from '@/common/hooks/use-pwa';

interface IProps {
  from?: number;
  to?: number;
  value: number;
  onChange: (value: number) => void;
  titleContent: string;
  disabled?: boolean;
}

const MIN_OVERFLOW_INDEX = 7;
const MAX_OVERFLOW_INDEX = 9;
const MIN_OVERFLOW_INDEX_PWA = 4;

const ToggleButtonCustom = styled(ToggleButton)(({ theme }) => ({
  width: '100%',
  color: theme.palette.text.primary,
  '&.Mui-disabled': {
    color: theme.palette.text.disabled,
  },
  '&.Mui-selected:not(.Mui-disabled)': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.main_12,
    borderColor: theme.palette.primary.main,
  },
}));

export default function SelectPax(props: IProps) {
  const { from = 1, to = 20, value, onChange, titleContent, disabled } = props;
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const theme = useTheme();
  const matchXLWidth = useMediaQuery(theme.breakpoints.up('xl'));
  const { isMobileView } = usePwaOrMobileView();

  const overflowIndex = matchXLWidth
    ? MAX_OVERFLOW_INDEX
    : isMobileView
    ? MIN_OVERFLOW_INDEX_PWA
    : MIN_OVERFLOW_INDEX;

  const arrayPax = useMemo(
    () => Array.from(Array(to - from + 1)).map((_, i) => i + from),
    [from, to]
  );
  const isInOverflowMenu = arrayPax.indexOf(value) + 1 > overflowIndex;

  const isActiveMoreBtn = useMemo(
    () => !!anchorEl || isInOverflowMenu,
    [anchorEl, isInOverflowMenu]
  );

  const onPaxSelect = (pax: number) => {
    onChange(pax);
    if (anchorEl) handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container spacing={1} wrap="nowrap">
      {arrayPax.slice(0, overflowIndex).map((paxItem) => (
        <Grid key={paxItem} item>
          <ToggleButtonCustom
            value={paxItem}
            color={disabled ? 'standard' : 'primary'}
            selected={paxItem === value}
            onClick={() => onPaxSelect(paxItem)}
            sx={{ width: '48px' }}
            disabled={disabled}
          >
            {paxItem}
          </ToggleButtonCustom>
        </Grid>
      ))}

      <Grid item xs>
        <ToggleButtonCustom
          value=""
          color="primary"
          selected={isActiveMoreBtn}
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
          }}
          disabled={disabled}
        >
          {isInOverflowMenu ? (
            <>
              {value}
              <KeyboardArrowRightIcon sx={{ fontSize: 20 }} />
            </>
          ) : (
            <Trans>More</Trans>
          )}
        </ToggleButtonCustom>

        <MenuPopover
          arrow="left-center"
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          sx={{
            ml: 1.75,
            width: 232,
            padding: '16px 0',
            height: 'fit-content',
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{ marginBottom: '16px', padding: '0 16px' }}
          >
            {titleContent}
          </Typography>

          <Divider sx={{ margin: '0 16px' }} />
          <Box
            sx={{
              // NOTE:  max popover height => height of body container - 8px top and 8px bottom
              maxHeight: 'calc(100vh - 72px - 60px - 32px - 40px - 16px)',
              overflowY: 'auto',
              padding: '0 16px',
            }}
          >
            {arrayPax.slice(overflowIndex).map((paxItem) => (
              <ToggleButtonCustom
                value={paxItem}
                key={paxItem}
                selected={paxItem === value}
                color="primary"
                sx={{
                  marginTop: '8px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
                onClick={() => onPaxSelect(paxItem)}
              >
                {paxItem}
              </ToggleButtonCustom>
            ))}
          </Box>
        </MenuPopover>
      </Grid>
    </Grid>
  );
}

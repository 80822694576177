import React from 'react';
import 'react-phone-input-2/lib/material.css';
import { FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import ReactPhoneInput, { PhoneInputProps } from 'react-phone-input-2';
import useLocaleCfg from '@/common/hooks/use-locale-cfg';

const StyledPhoneInput = styled(ReactPhoneInput)(
  ({ theme, isValid, specialLabel }) => ({
    '&.react-tel-input': {
      fontFamily: theme.typography.fontFamily,
      fontWeight: theme.typography.fontWeightRegular,
      '.special-label': {
        display: specialLabel === '' ? 'none' : 'inline-block',
        fontSize: 12,
        opacity: 1,
        textTransform: 'capitalize',
        left: '9px',
        padding: '0 2px',
        top: '-10px',
        color: isValid ? theme.palette.text.disabled : theme.palette.error.main,
        maxWidth: '80%',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        zIndex: 'unset',
      },
      '.form-control': {
        width: '100%',
        paddingTop: '15.5px',
        paddingBottom: '15.5px',
        color: '#212B36',
        fontSize: 16,
        fontFamily: 'Public Sans,system',
        fontWeight: '500',
        borderRadius: theme.shape.borderRadius,
        ':disabled': {
          cursor: 'auto',
          backgroundColor: `${theme.palette.grey[200]} !important`,
          color: `${theme.palette.text.disabled} !important`,
          borderColor: `${theme.palette.grey[500_32]} !important`,
        },
        '&::placeholder': {
          color: '#919EAB',
        },
      },
      '.country': {
        '.flag': {
          top: '10.5px',
        },
      },
      ':focus-within': {
        '.special-label': {
          color: isValid
            ? theme.palette.primary.main
            : theme.palette.error.main,
        },
        '.form-control': {
          boxColor: isValid
            ? theme.palette.primary.main
            : theme.palette.error.main,
          borderColor: isValid
            ? theme.palette.primary.main
            : theme.palette.error.main,
          boxShadow: `0 0 0 1px ${
            isValid ? theme.palette.primary.main : theme.palette.error.main
          }`,
        },
      },
    },
  })
);

interface NewPhoneInputProps extends PhoneInputProps {
  /** Default country for the phone number */
  defaultCountry?: string;

  /** Phone number */
  value?: string;

  /** Error message of phone input */
  error?: string;

  /** Label for the phone number */
  label?: string;
}

const PhoneInput: React.FC<NewPhoneInputProps> = ({
  error,
  defaultCountry,
  label,
  ...restProps
}) => {
  const locale = useLocaleCfg();
  const country = defaultCountry || locale['ISO 3166-2'].toLowerCase();

  return (
    <>
      <StyledPhoneInput
        isValid={!error}
        country={country}
        specialLabel={label || ''}
        {...restProps}
      />
      {error && <FormHelperText error={!!error}>{error}</FormHelperText>}
    </>
  );
};

export default PhoneInput;

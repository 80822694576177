import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

export const TouchTooltip = styled(
  ({
    className,
    leaveTouchDelay,
    children,
    classes,
    ...props
  }: TooltipProps) => (
    <Tooltip
      enterTouchDelay={0}
      leaveTouchDelay={leaveTouchDelay || 30000}
      classes={{ popper: className, ...classes }}
      {...props}
    >
      {children}
    </Tooltip>
  )
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    borderRadius: 4,
    padding: '2px 6px 4px',
    fontSize: 12,
    textAlign: 'center',
  },
}));

import React from 'react';
import { Box } from '@mui/material';
import { Controller } from 'react-hook-form';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import { useTranslation } from 'next-i18next';
import { useReservationActionContext } from '../../context';
import { TouchTooltip } from '@/common/components/tooltip';
import { useGenerateExpireTime } from '@/feat/schedule/schedule-modals/contants';
import { parse } from 'date-fns';
import { useAtom } from 'jotai';
import { isErrorTimeExpireAtom, timeExpireSelectAtom } from '../../state';

export default function CustomSelectWithTooltip() {
  const { t } = useTranslation();
  const dataExpireTime = useGenerateExpireTime();

  const { reservationForm } = useReservationActionContext();
  const { control, watch, setValue } = reservationForm;
  const [isErrorLink] = useAtom(isErrorTimeExpireAtom);
  const [timeExpiredSelect, setTimeExpiredSelect] =
    useAtom(timeExpireSelectAtom);

  const watchDateTime = watch('dateTime');

  const compareNewTimeSelect: number | null =
    watchDateTime !== null
      ? parse(
          watchDateTime.toISOString(),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
          new Date()
        ).getTime() - new Date().getTime()
      : null;

  const compareNewTimeMinutes =
    watchDateTime !== null ? (compareNewTimeSelect ?? 0) / 60000 : null;

  const handleSelectExpireTime = (event: SelectChangeEvent) => {
    setTimeExpiredSelect(event.target.value);
    let selectedValue = Object.values(dataExpireTime).find(
      (item) => item.label === event.target.value
    );
    setValue('expireTime', selectedValue?.value ?? 1440);
  };

  return (
    <Box>
      <Controller
        control={control}
        name="expireTime"
        render={({ field }) => (
          <Select
            {...field}
            fullWidth={true}
            onChange={handleSelectExpireTime}
            value={timeExpiredSelect}
            placeholder={isErrorLink ? t('Unavailable') : ''}
            disabled={isErrorLink}
            sx={{
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: isErrorLink ? '#637381' : undefined,
                backgroundColor: isErrorLink ? '#919EAB24' : 'inherit',
              },
              '.MuiInputBase-formControl': {
                width: '100%',
                position: 'relative',
              },
              ...(isErrorLink && {
                '& .MuiSelect-nativeInput ': {
                  opacity: 1,
                  height: '100%',
                  backgroundColor: 'transparent',
                  padding: 2,
                  border: 'none',
                  '&::placeholder': {
                    color: '#637381',
                    fontSize: '16px',
                  },
                },
              }),
            }}
            MenuProps={{
              sx: {
                '.MuiList-root': {
                  width: '100%',
                  maxHeight: 200,
                  marginTop: '8px',
                },
              },
            }}
          >
            {Object.values(dataExpireTime).map((option) => (
              <MenuItem
                key={option.label}
                value={option.label}
                disabled={
                  compareNewTimeMinutes !== null &&
                  compareNewTimeMinutes <= option.value
                }
              >
                {compareNewTimeMinutes !== null &&
                compareNewTimeMinutes <= option.value ? (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                  >
                    {option.label}
                    <TouchTooltip
                      sx={{
                        '.MuiTooltip-tooltip': {
                          py: 2,
                        },
                      }}
                      title={t(
                        'Validity time is greater than the reservation time, change the validity time or reservation time'
                      )}
                      arrow
                    >
                      <HelpTwoToneIcon
                        color="action"
                        sx={{
                          pointerEvents: 'all',
                        }}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </TouchTooltip>
                  </Box>
                ) : (
                  option.label
                )}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </Box>
  );
}

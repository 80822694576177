import React from 'react';

const ReserveByOnlineSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        d="M9 16.5c-4.125 0-7.5-3.375-7.5-7.5S4.875 1.5 9 1.5s7.5 3.375 7.5 7.5-3.375 7.5-7.5 7.5zm0 0"
        fill="#f4f6f8"
      />
      <path
        d="M9 16.5a7.56 7.56 0 01-2.926-.602c-.898-.375-1.722-.898-2.398-1.574-.676-.676-1.2-1.5-1.574-2.398A7.56 7.56 0 011.5 9c0-1.05.227-2.023.602-2.926.375-.898.898-1.722 1.574-2.398.676-.676 1.5-1.2 2.398-1.574A7.56 7.56 0 019 1.5a7.56 7.56 0 012.926.602c.898.375 1.722.898 2.398 1.574.676.676 1.2 1.5 1.574 2.398A7.56 7.56 0 0116.5 9a7.56 7.56 0 01-.602 2.926c-.375.898-.898 1.722-1.574 2.398-.676.676-1.5 1.2-2.398 1.574A7.56 7.56 0 019 16.5zM8.25 15v-1.5c-.45 0-.75-.148-1.05-.45-.302-.3-.45-.675-.45-1.05v-.75L3.148 7.648c-.074.227-.074.454-.074.676C3 8.551 3 8.774 3 9c0 1.5.523 2.852 1.5 3.977S6.75 14.773 8.25 15zm5.176-1.95c.222-.3.449-.6.676-.902.222-.296.375-.671.523-.972.148-.375.227-.676.3-1.051C15 9.75 15 9.375 15 9c0-1.2-.375-2.324-1.05-3.375-.677-1.05-1.575-1.727-2.7-2.176v.301c0 .45-.148.75-.45 1.05-.3.302-.6.45-1.05.45h-1.5v1.5a.667.667 0 01-.227.523.667.667 0 01-.523.227H6V9h4.5c.227 0 .375.074.523.227a.667.667 0 01.227.523V12H12c.3 0 .602.074.898.3.227.15.454.45.528.75zm0 0"
        fill="#64adea"
      />
    </svg>
  );
};

export default ReserveByOnlineSvg;

import Drawer from '@mui/material/Drawer';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { Trans, useTranslation } from 'next-i18next';
import Grid from '@mui/material/Grid';
import {
  Divider,
  FormHelperText,
  Stack,
  styled,
  Switch,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  alpha,
  Chip,
} from '@mui/material';
import CustomerReservationInfor from '../customer-reservation-infor';
import CustomerNotifications from '../customer-notifications';
import TicketSelection, { TicketItem } from './ticket-selection';
import { useMemo } from 'react';
import DiningInterval from '../dining-interval';
import SelectPax from '../select-pax';
import DateTimeReservation from '../modal-date-time';
import { getRoundingCurrentTime } from '../modal-date-time/get-rounding-current-time';
import AssignTableMenu from '../add-reservation/assign-table-menu';
import {
  useMerchantReservation,
  useReservation,
} from '@/common/hooks/use-reservations';
import { useSchedules } from '@/common/hooks/use-schedules';
import { ServiceTiming } from '@/common/types/schedule';
import LoadingButton from '@mui/lab/LoadingButton';
import { Controller, FieldValues, SubmitHandler } from 'react-hook-form';
import { format, parse } from 'date-fns';
import useUnsavedChange from '@/common/hooks/use-unsaved-change';
import LoadingScreen from '@/common/components/loading-screen';
import { useTickets } from '@/common/hooks/use-tickets';
import ReservationConfirmation from '../confirmation/reservation-confirmation';
import { ReservationItem } from '@/common/types/reservation';
import { useGetAreas } from '@/common/hooks/use-areas';
import { assertUnreachable } from '@/common/utils';
import StickyBottomToolbar from '@/common/components/sticky-bottom-toolbar';
import {
  EDITABLE_RESERVATION_FIELDS,
  NO_PAYMENT_TICKET_EDITABLE_FIELDS,
  PAID_TICKET_EDITABLE_FIELDS,
  WalkinStatus,
  WALKIN_TICKET_EDITABLE_FIELDS,
} from '@/common/types/reservation-status-flow';
import { TICKET_TYPE_PAYMENT } from '@/common/types/ticket.base';
import clsx from 'clsx';
import { useUnavailTableIds } from '@/feat/affected-reservations/atoms';
import {
  PaymentType,
  ReservationActionContextProvider,
  useReservationActionContext,
} from '../../context';
import useCustomerNotificationSettings from '@/common/hooks/use-customer-notification-settings';
import {
  automatedCallNotifyAtom,
  customerInfoActionAtom,
  emailNotifyAtom,
  isErrorTimeExpireAtom,
  timeExpireSelectAtom,
  occasionLabels,
  reservationTypeAtom,
  smsNotifyAtom,
  currentPaymentRequestStatusAtom,
  createTagsModalAtom,
  stepActivePWA,
  reservationTagsAtom,
} from '../../state';
import { useAtom, useSetAtom } from 'jotai';
import { SelectedTable } from '@/feat/reservation/components/add-reservation/assign-table-menu/type';
import PaymentRequestPaxDetail from '../payment-request-detail/payment-request-detail-pax';
import { useGenerateExpireTime } from '@/feat/schedule/schedule-modals/contants';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { PaymentStatusResponse } from '@/common/types/transaction.base';
import { PaymentRequestEditProps } from '../payment-request-detail/type';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import { TouchTooltip } from '@/common/components/tooltip';
import { PR_TICKET_TYPE_PAYMENT } from '@/common/types/ticket.base';
import PaymentRequestOnlineDetail from '../payment-request-detail/payment-request-detail-online';
import PaymentRequestOtherDetail from '../payment-request-detail/payment-request-detail-other';
import { useFeatureFlags } from '@/common/hooks/use-feature-flag';
import AddIcon from '@mui/icons-material/Add';
import ActionTagModal from '@/common/components/reserve-intelligence/action-tag-modal';

import ArrowBackIosTwoToneIcon from '@mui/icons-material/ArrowBackIosTwoTone';
import {
  CreateCustomerPayload,
  useAddCustomer,
  useCustomerDetail,
} from '@/common/hooks/use-customers';
import { usePwaOrMobileView } from '@/common/hooks/use-pwa';
import HorizontalLabelStepper from './horizontal-label-stepper';
import CustomerAlertModal from './customer-alert-modal';

export type ActionReservationType = 'Add' | 'Edit';
export type ReservationType = 'Reservation' | 'WalkIn';

const DEFAULT_DINING_INTERVAL = 5400;

const TitleField = ({ children, ...props }: TypographyProps) => (
  <Typography
    variant="overline"
    sx={{
      letterSpacing: '1.2px',
      color: '#919EAB',
      display: 'block',
      textTransform: 'uppercase',
    }}
    {...props}
  >
    {children}
  </Typography>
);

const Form = styled('form')(({ theme }) => ({
  boxShadow: theme.customShadows.z16,
  borderRadius: '16px',
  padding: '16px',
}));

type ActionReservationModalProps = {
  actionType: ActionReservationType;
  openModal: boolean;
  closeModal: () => void;
  editedReservationId?: string;
  /**
   * param to check if editedReservationId is affected reservation
   */
  isAffectedReservation?: boolean;
  onView?: (reservationId: string) => void;
  onSuccess?: (updated: ReservationItem) => void;
  mcaIdPrefillCustomer?: string;
  disableConfirmModal?: boolean;
  className?: string;
  disableTransition?: boolean;
  dataResendPayment?: ReservationItem;
  initialValue?: {
    tables?: {
      id: string;
      name: string;
      maxPax: number;
    }[];
    reservationDate?: string;
    reservationTime?: string;
    diningInterval?: number;
  };
};
function ActionReservationModal(props: ActionReservationModalProps) {
  const {
    actionType,
    openModal,
    closeModal,
    editedReservationId,
    isAffectedReservation,
    onView,
    onSuccess,
    mcaIdPrefillCustomer,
    disableConfirmModal,
    className,
    disableTransition,
    initialValue,
    dataResendPayment,
  } = props;
  const {
    reservationForm,
    customerForm,
    handleAddReservation,
    isAddingWalkIn,
    isAddingReservation,
    isUpdatingReservation,
    isUpdatingWalkIn,
    isAddingCustomer,
  } = useReservationActionContext();

  const [reservationType, setReservationType] = useAtom(reservationTypeAtom);
  const [createTagsModal, setCreateTagsModal] = useAtom(createTagsModalAtom);
  const [customerInfoActionState, setCustomerInfoActionState] = useAtom(
    customerInfoActionAtom
  );
  const [rersvSelectedTags, setRersvSelectedTags] =
    useAtom(reservationTagsAtom);

  // pax
  const [allowChildrenDining, setAllowChildrenDining] =
    useState<boolean>(false);

  // service timing
  const [listServiceId, setListServiceId] = useState<string[]>([]);
  const [serviceTimingItem, setServiceTimingItem] =
    useState<ServiceTiming | null>(null);

  //confirmation modal
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [dataSubmit, setDataSubmit] = useState<ReservationItem | null>(null);
  const [dataBeforeSubmit, setDataBeforeSubmit] =
    useState<ReservationItem | null>(null);

  // customer notification
  const [emailNotify, setEmailNotify] = useAtom(emailNotifyAtom);
  const [smsNotify, setSMSNotify] = useAtom(smsNotifyAtom);
  const [automatedCallNotify, setAutomatedCallNotify] = useAtom(
    automatedCallNotifyAtom
  );
  const [isCustomerNeedUpdate, setIsCustomerNeedUpdate] =
    useState<boolean>(false);
  const [dateRersvEdited, setDateRersvEdited] = useState('');
  const [timeRersvEdited, setTimeRersvEdited] = useState('');
  const [typePaymentTicketEdit, setTypePaymentTicketEdit] = useState<
    number | null
  >(null);

  // payment request detail
  const setIsErrorTimeExpire = useSetAtom(isErrorTimeExpireAtom);
  const setTimeExpiredSelect = useSetAtom(timeExpireSelectAtom);
  const setCurrentPaymentRequestStatus = useSetAtom(
    currentPaymentRequestStatusAtom
  );

  const theme = useTheme();
  const { t } = useTranslation();

  // PWA Add reservation/walkIn feature
  const { isMobileView } = usePwaOrMobileView();

  const [activeStepPWA, setActiveStepPWA] = useAtom(stepActivePWA);

  const submitFormRef = useRef<HTMLButtonElement>();

  const { isEnableTableCombination } = useFeatureFlags();

  const customerFormEmail = customerForm.watch('email');

  const {
    formState: { isDirty, errors },
    control,
    reset: resetReactHookForm,
    watch,
    clearErrors,
    register,
    setValue,
    setError,
  } = reservationForm;
  const { mutateAsync: addCustomer } = useAddCustomer();
  const {
    handleSubmit: handleSubmitCustomer,
    reset,
    getValues: valuesCustomerForm,
  } = customerForm;

  const onSubmit: SubmitHandler<FieldValues> = async () => {
    const {
      firstName,
      lastName,
      salutation,
      phoneNumber,
      receivePromotions,
      email,
      customerNotes,
      birthday,
    } = valuesCustomerForm();

    const customer: CreateCustomerPayload = {
      firstName,
      lastName,
      ...(salutation && { salutation }),
      phone: `+${phoneNumber}`,
      additionalPhone: undefined,
      organisationName: undefined,
      canSendEmailMarketing: receivePromotions,
      email: email || null,
      notesDiner: customerNotes,
      ...(birthday && { birthday: format(birthday as Date, 'yyyy-MM-dd') }),
    };

    const resCustomerData = await addCustomer(customer);

    if (!!resCustomerData) {
      reset();
      setActiveStepPWA(2);
    }
  };
  const handlePWASubmitCustomer = handleSubmitCustomer(onSubmit);

  const { ref: customerDetailRef } = register('customerDetailData', {
    required: isMobileView
      ? activeStepPWA === 1 && reservationType === 'Reservation'
      : reservationType === 'Reservation',
  });

  const watchDateTime = watch('dateTime');
  const watchTicket = watch('ticket');
  const watchStaffNote = watch('staffNote');
  const watchSpecialRequest = watch('specialRequest');
  const watchOtherOccasion = watch('otherOccasion');
  const watchDiningInterval = watch('diningInterval');
  const watchAdultsPax = watch('adultsPax');
  const watchChildrenPax = watch('childrenPax');
  const watchOccasionsSelected = watch('occasionsSelected');
  const watchCustomerDetailData = watch('customerDetailData');
  const watchPaymentType = watch('paymentType');
  const watchPaymentStatus = watch('paymentStatus');
  const watchPaymentNote = watch('notePaymentRequest');
  const watchTables = watch('tables');
  const dataExpireTime = useGenerateExpireTime();

  useEffect(() => {
    if (watchPaymentType === 'OTHER_METHOD') {
      setValue('paymentStatus', isCardGuaranteeTicket ? 'guaranteed' : 'paid');
    } else {
      setValue('paymentStatus', 'pending');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchTicket]);

  useEffect(() => {
    if (dataResendPayment) {
      const {
        diningInterval,
        staffNotes,
        notes,
        numberOfAdults,
        numberOfChildren,
        reservationDate,
        reservationTime,
        tables,
        reservationPaymentRequest,
        otherOccasions,
        ticket,
        tags,
      } = dataResendPayment;
      setValue('diningInterval', diningInterval || 5400);
      setValue('staffNote', staffNotes || '');
      setValue('specialRequest', notes || '');
      setValue('adultsPax', numberOfAdults);
      setValue('childrenPax', numberOfChildren);

      if (tags) {
        setRersvSelectedTags(tags);
      }
      if (numberOfChildren > 0) {
        setAllowChildrenDining(true);
      }
      if (ticket) {
        setValue('ticket', {
          color: ticket?.color,
          label: ticket?.name,
          price: ticket?.price,
          typePayment: ticket?.typePayment,
          value: ticket?.id,
        } as TicketItem);
      }

      if (tables) {
        if (isEnableTableCombination) {
          setValue(
            'tables',
            tables.map<SelectedTable>((table) => ({
              ...table,
              isAutoAssigned: false,
              errors: [],
            }))
          );
        } else {
          setValue('tables', [
            {
              ...tables[0],
              isAutoAssigned: false,
              errors: [],
            } as SelectedTable,
          ]);
        }
      }

      if (reservationDate && reservationTime) {
        const reservationDateTime = parse(
          reservationDate + ' ' + reservationTime,
          'yyyy-MM-dd HH:mm:ss',
          new Date()
        );
        const currentDate = new Date();

        if (reservationDateTime > currentDate) {
          setValue('dateTime', reservationDateTime);
        } else {
          const newDateTime = parse(reservationTime, 'HH:mm:ss', new Date());
          setValue('dateTime', newDateTime);
        }
      }

      const occasionsFmt = String(dataResendPayment?.occasions)
        .split(', ')
        .concat(otherOccasions ? 'Others' : []);

      setValue('occasionsSelected', occasionsFmt);
      setValue('otherOccasion', otherOccasions ?? '');
      setValue('paymentType', reservationPaymentRequest?.type as PaymentType);
      setValue('notePaymentRequest', reservationPaymentRequest?.notes || '');
    }
  }, [
    dataResendPayment,
    isEnableTableCombination,
    setRersvSelectedTags,
    setValue,
  ]);

  const compareNewTimeSelect: number | null = useMemo(() => {
    return watchDateTime !== null
      ? parse(
          watchDateTime.toISOString(),
          "yyyy-MM-dd'T'HH:mm:ss.SSSxxx",
          new Date()
        ).getTime() - new Date().getTime()
      : null;
  }, [watchDateTime]);

  const compareNewTimeMinutes = useMemo(
    () => (watchDateTime !== null ? (compareNewTimeSelect ?? 0) / 60000 : null),
    [compareNewTimeSelect, watchDateTime]
  );

  useEffect(() => {
    if (compareNewTimeMinutes !== null) {
      const availableOptions = Object.values(dataExpireTime).filter(
        (option) =>
          compareNewTimeMinutes === null || compareNewTimeMinutes > option.value
      );
      const has24HoursOption = availableOptions.some(
        (option) => option.value === 1440
      );
      const lastAvailableOption = availableOptions[availableOptions.length - 1];
      if (has24HoursOption) {
        setTimeExpiredSelect('24 hours');
        setValue('expireTime', 1440);
        setIsErrorTimeExpire(false);
      } else if (lastAvailableOption) {
        setTimeExpiredSelect(lastAvailableOption.label || '24 hours');
        setValue('expireTime', lastAvailableOption.value);
        setIsErrorTimeExpire(false);
      } else {
        setIsErrorTimeExpire(true);
        setTimeExpiredSelect('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchDateTime, compareNewTimeMinutes]);

  const { valueDate, valueTimeSlot } = useMemo(() => {
    if (!watchDateTime)
      return {
        valueDate: '',
        valueTimeSlot: '',
      };
    const [valueDate = '', valueTimeSlot = ''] = format(
      watchDateTime,
      'yyyy-MM-dd,HH:mm:ss'
    ).split(',');

    return {
      valueDate,
      valueTimeSlot,
    };
  }, [watchDateTime]);

  const ticketId = useMemo(
    () => (watchTicket ? watchTicket.value : ''),
    [watchTicket]
  );

  const { data: dataMerchantReservation } = useMerchantReservation({
    config: {
      enabled: openModal,
    },
  });

  const { data: dataServiceTimings, isFetching: isFetchingSchedules } =
    useSchedules({
      params: {
        outletId: dataMerchantReservation?.outlet?.id,
        date: valueDate,
      },
      config: {
        initialData: [],
        enabled:
          openModal && !!valueDate && !!dataMerchantReservation?.outlet?.id,
      },
    });

  const { data: dataTickets, isFetching: isFetchingTickets } = useTickets({
    params: {
      brandId: dataMerchantReservation?.brand?.id || '',
    },
    config: {
      enabled:
        reservationType === 'Reservation' &&
        openModal &&
        !!dataMerchantReservation?.brand?.id,
    },
  });

  const {
    data: editedReservationData,
    isFetching: isFetchingEditedResrv,
    error,
  } = useReservation({
    reservationId: editedReservationId ? editedReservationId : '',
    config: {
      enabled: !!editedReservationId && openModal,
    },
  });

  const {
    smsNotify: defaultSMSNotify,
    emailNotify: defaultEmailNotify,
    automatedCallNotify: defaultAutomatedCallNotify,
  } = useCustomerNotificationSettings(editedReservationData);

  const editedReservationSelectedTable: SelectedTable[] = useMemo(() => {
    //
    if (
      !Array.isArray(editedReservationData?.tables) ||
      !editedReservationData?.tables?.length
    )
      return [];

    if (!isEnableTableCombination) {
      const table = editedReservationData?.tables?.[0];
      if (table) {
        return [
          {
            id: table.id,
            name: table.name,
            isAutoAssigned: false,
          },
        ];
      }
    }

    return editedReservationData?.tables?.map((table) => ({
      id: table.id,
      name: table.name,
      maxPax: table.maxPax,
      isAutoAssigned: false,
      errors: [],
    }));
  }, [editedReservationData?.tables, isEnableTableCombination]);

  const editedReservationPaymentRequest: PaymentRequestEditProps =
    useMemo(() => {
      const dataReservationPR =
        editedReservationData?.reservationPaymentRequest;

      if (!dataReservationPR) {
        return null;
      }

      return {
        expireDateTime: dataReservationPR.expireDateTime || '',
        type: dataReservationPR.type || '',
        link: dataReservationPR.link || '',
        notes: dataReservationPR.notes || '',
        status: dataReservationPR.status || '',
      };
    }, [editedReservationData]);

  const isCardGuaranteeTicket = useMemo(() => {
    if (!watchTicket) return false;
    if (actionType === 'Edit' && editedReservationPaymentRequest) {
      return (
        editedReservationData?.ticket?.typePayment ===
        PR_TICKET_TYPE_PAYMENT.CardGuarantee
      );
    }
    return watchTicket.typePayment === PR_TICKET_TYPE_PAYMENT.CardGuarantee;
  }, [
    actionType,
    editedReservationData?.ticket?.typePayment,
    editedReservationPaymentRequest,
    watchTicket,
  ]);

  useEffect(() => {
    if (actionType === 'Edit' && editedReservationPaymentRequest) {
      const { type, notes, status } = editedReservationPaymentRequest;

      setCurrentPaymentRequestStatus(status);
      setValue('paymentType', type as PaymentType);
      setValue('notePaymentRequest', notes);
      setValue('paymentStatus', status as PaymentStatusResponse);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    actionType,
    editedReservationPaymentRequest,
    watchPaymentNote,
    watchTicket,
  ]);

  useEffect(() => {
    if (actionType === 'Edit') {
      setTypePaymentTicketEdit(
        editedReservationData?.ticket?.typePayment || null
      );
    }
  }, [actionType, editedReservationData]);

  const { data: customerInfo } = useCustomerDetail({
    userId: editedReservationData?.user?.id || '',
  });

  useEffect(() => {
    if (
      actionType === 'Edit' &&
      activeStepPWA === 0 &&
      editedReservationData?.user?.id
    ) {
      customerInfo && setValue('customerDetailData', customerInfo);
      setCustomerInfoActionState('SEE_DETAIL');
    }
  }, [
    actionType,
    activeStepPWA,
    customerInfo,
    editedReservationData?.user?.id,
    setCustomerInfoActionState,
    setValue,
  ]);

  const paymentRequestProps = useMemo(
    () => ({
      actionType,
      editedReservationPaymentRequest,
    }),
    [actionType, editedReservationPaymentRequest]
  );

  const { data: dataAreas, isFetching: isFetchingAreas } = useGetAreas({
    enabled: openModal,
  });

  const unavailTableIds = useUnavailTableIds();

  const handleSelectOccasion = (occasion: string) => {
    const isActiveOccasion = watchOccasionsSelected?.includes(occasion);

    setValue(
      'occasionsSelected',
      isActiveOccasion
        ? watchOccasionsSelected?.filter(
            (occasionItem) => occasionItem !== occasion
          )
        : [...watchOccasionsSelected, occasion]
    );
  };

  const handleUnSelectTag = useCallback(
    (id: string) => {
      setRersvSelectedTags((prevTags) =>
        prevTags.filter((tag) => tag.id !== id)
      );
    },
    [setRersvSelectedTags]
  );

  const handleNotePaymentRequest = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setValue('notePaymentRequest', event.target.value);
  };

  const resetState = () => {
    setAllowChildrenDining(false);
    setEmailNotify(true);
    setSMSNotify(true);
    setAutomatedCallNotify(true);
    setServiceTimingItem(null);
    setDateRersvEdited('');
    setTimeRersvEdited('');
    setTimeExpiredSelect('24 hours');
    setIsErrorTimeExpire(false);
    setActiveStepPWA(0);
    setRersvSelectedTags([]);

    resetReactHookForm({
      ticket: null,
      dateTime: null,
      tables: null,
      specialRequest: '',
      staffNote: '',
      otherOccasion: '',
      diningInterval: DEFAULT_DINING_INTERVAL,
      adultsPax: 2,
      childrenPax: 0,
      occasionsSelected: [],
      customerDetailData: null,
      paymentType: 'ONLINE_PAYMENT_LINK',
      notePaymentRequest: '',
    });
  };

  const isLoadingSubmit =
    isAddingReservation ||
    isAddingWalkIn ||
    isUpdatingReservation ||
    isUpdatingWalkIn ||
    isAddingCustomer;

  const handleCloseModal = async () => {
    if (isLoadingSubmit) return;
    const { isDirty, resolution } = await checkBeforeLeave();
    if (isDirty && resolution === 'CANCEL') return;
    closeModal();
    resetState();
  };

  const handleChangeDefaultPaymentStatus = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    let value = (event.target as HTMLInputElement).value;

    setValue(
      'paymentStatus',
      value === 'ONLINE_PAYMENT_LINK'
        ? 'pending'
        : value === 'OTHER_METHOD' && isCardGuaranteeTicket
        ? 'guaranteed'
        : 'paid'
    );
  };

  const isAvailableSlotTime = useMemo(() => {
    const isOtherMethodAndPending =
      watchPaymentType === 'OTHER_METHOD' && watchPaymentStatus === 'pending';
    const isPaidOrGuaranteed =
      watchPaymentStatus === 'paid' || watchPaymentStatus === 'guaranteed';
    const hasSufficientTime =
      compareNewTimeMinutes !== null && compareNewTimeMinutes > 30;
    if (isPaidOrGuaranteed) {
      return true;
    }

    return (
      (watchPaymentType === 'ONLINE_PAYMENT_LINK' || isOtherMethodAndPending) &&
      hasSufficientTime
    );
  }, [watchPaymentType, watchPaymentStatus, compareNewTimeMinutes]);

  const handleSubmit = async () => {
    if (
      reservationType === 'Reservation' &&
      actionType === 'Add' &&
      selectedTicket?.typePayment !== TICKET_TYPE_PAYMENT['No Payment'] &&
      !isAvailableSlotTime
    ) {
      return;
    }
    if (isRequiredCustomerInfor) {
      setIsCustomerNeedUpdate(true);
    } else {
      const res = await handleAddReservation();
      if (res) {
        setDataSubmit(res);
        setDataBeforeSubmit(
          actionType === 'Edit' && !!editedReservationData
            ? editedReservationData
            : null
        );
        onSuccess && onSuccess(res);
        !disableConfirmModal && setOpenConfirmModal(true);
        closeModal();
        resetState();
      }
    }
  };

  const editableReservationFields = useMemo(() => {
    if (!editedReservationData) return EDITABLE_RESERVATION_FIELDS;

    const { isWalkIn, rStatus, ticket } = editedReservationData;

    if (isWalkIn) {
      return WALKIN_TICKET_EDITABLE_FIELDS[rStatus as WalkinStatus];
    }

    const type = ticket?.typePayment;
    switch (type) {
      case null:
      case undefined:
        return [];
      case TICKET_TYPE_PAYMENT['No Payment']:
        return NO_PAYMENT_TICKET_EDITABLE_FIELDS[rStatus];
      case TICKET_TYPE_PAYMENT.Deposit:
      case TICKET_TYPE_PAYMENT.Prepaid:
      case TICKET_TYPE_PAYMENT.CardGuarantee:
        return PAID_TICKET_EDITABLE_FIELDS[rStatus];
      default:
        assertUnreachable(type);
        return [];
    }
  }, [editedReservationData]);

  const switchReservationType = (type: ReservationType) => {
    if (reservationType === type) return;
    setReservationType(type);
    clearErrors();
    setValue('tables', null);
    if (type === 'WalkIn') {
      setServiceTimingItem(null);
      setValue(
        'dateTime',
        parse(getRoundingCurrentTime(), 'HH:mm:ss', new Date())
      );
    } else {
      setValue('dateTime', null);
    }
  };

  const occasionSection = useMemo(() => {
    if (!dataMerchantReservation?.occasions) return <></>;
    return (
      <>
        <Divider sx={{ margin: '24px 0' }} />
        <TitleField>
          <Trans>Occasions (Optional)</Trans>
        </TitleField>
        {[...dataMerchantReservation?.occasions, { name: 'Others' }].map(
          (occasion) => {
            const isDisabled = !editableReservationFields.includes('OCCASION');
            const isSelected = watchOccasionsSelected?.includes(occasion?.name);
            return (
              <Chip
                label={occasionLabels(occasion?.name)}
                key={occasion?.name}
                onClick={() => {
                  if (!isDisabled) {
                    handleSelectOccasion(occasion?.name);
                  }
                }}
                sx={{
                  padding: '7px 8px',
                  color: isSelected
                    ? theme.palette.primary.main
                    : theme.palette.grey[700],
                  backgroundColor: isSelected
                    ? theme.palette.primary.main_16
                    : theme.palette.grey[500_16],
                  '&.Mui-disabled': {
                    color: theme.palette.text.disabled,
                  },
                  borderRadius: '6px',
                  marginRight: '8px',
                  marginTop: '12px',
                  '&:hover': {
                    backgroundColor: isSelected
                      ? theme.palette.primary.main_12
                      : theme.palette.grey[500_16],
                  },
                }}
                size="small"
                disabled={isDisabled}
                clickable={!isDisabled}
              />
            );
          }
        )}
        {watchOccasionsSelected.includes('Others') && (
          <Box>
            <TextField
              multiline
              fullWidth
              rows={4}
              inputProps={{
                maxLength: 120,
              }}
              sx={{ marginTop: '13px' }}
              placeholder={t('Other occasions e.g. Proposals')}
              {...register('otherOccasion')}
            />
            <FormHelperText
              sx={{ textAlign: 'right', color: theme.palette.text.disabled }}
            >
              <Trans>
                {{ characterNumber: watchOtherOccasion?.length }}/120 characters
              </Trans>
            </FormHelperText>
          </Box>
        )}
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataMerchantReservation?.occasions,
    watchOccasionsSelected,
    watchOtherOccasion,
    register,
    editableReservationFields,
  ]);

  const reservationTagsSection = useMemo(() => {
    return (
      <>
        <Divider sx={{ mt: 3, borderColor: 'transparent' }} />

        <TitleField>
          <Trans>Reservation Tags</Trans>
        </TitleField>

        {!!rersvSelectedTags?.length
          ? rersvSelectedTags?.map((tag) => {
              return (
                <Chip
                  label={tag.title}
                  key={tag.id}
                  onDelete={() => handleUnSelectTag(tag?.id)}
                  sx={{
                    padding: theme.spacing(0.5),
                    color: theme.palette.grey[700],
                    backgroundColor: theme.palette.grey[500_16],
                    '&.Mui-disabled': {
                      color: theme.palette.text.disabled,
                    },
                    borderRadius: '6px',
                    mr: theme.spacing(1),
                    mt: theme.spacing(1.5),
                    '& .MuiSvgIcon-root': {
                      fontSize: 18,
                    },
                  }}
                  size="small"
                />
              );
            })
          : null}

        <Button
          size="medium"
          variant="text"
          startIcon={<AddIcon />}
          onClick={() =>
            setCreateTagsModal({
              isOpen: true,
              type: 'reservation',
              customerMcaId: '',
              customerId: '',
            })
          }
          sx={{
            padding: theme.spacing(0.875, 1.25),
            mr: theme.spacing(1),
            mt: theme.spacing(1.5),
            color: theme.palette.grey[600],
            '&:hover': {
              backgroundColor: alpha(theme.palette.grey[600], 0.08),
            },
          }}
        >
          <Trans>Add Tag</Trans>
        </Button>
      </>
    );
  }, [rersvSelectedTags, theme, handleUnSelectTag, setCreateTagsModal]);

  const selectedTicket = useMemo(
    () => dataTickets?.items.find((t) => t.id === watchTicket?.value) || null,
    [watchTicket, dataTickets]
  );

  const isRequiredCustomerInfor = useMemo(() => {
    const hasUserData =
      !!editedReservationData?.user ||
      mcaIdPrefillCustomer ||
      dataResendPayment?.user ||
      watchCustomerDetailData;

    if (!hasUserData) {
      return false;
    } else {
      return (
        reservationType === 'Reservation' &&
        selectedTicket?.typePayment !== TICKET_TYPE_PAYMENT['No Payment'] &&
        watchPaymentType === 'ONLINE_PAYMENT_LINK' &&
        (!watchCustomerDetailData?.email || !watchCustomerDetailData?.lastName)
      );
    }
  }, [
    reservationType,
    selectedTicket?.typePayment,
    watchPaymentType,
    editedReservationData?.user,
    mcaIdPrefillCustomer,
    dataResendPayment?.user,
    watchCustomerDetailData,
  ]);

  const isOnlinePaymentRequest = useMemo(
    () =>
      reservationType === 'Reservation' &&
      selectedTicket?.typePayment !== TICKET_TYPE_PAYMENT['No Payment'] &&
      watchPaymentType === 'ONLINE_PAYMENT_LINK',
    [reservationType, selectedTicket?.typePayment, watchPaymentType]
  );

  useEffect(() => {
    if (!openModal) return;

    setEmailNotify(defaultEmailNotify);
    setSMSNotify(defaultSMSNotify);
    setAutomatedCallNotify(defaultAutomatedCallNotify);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    openModal,
    defaultEmailNotify,
    defaultSMSNotify,
    defaultAutomatedCallNotify,
  ]);

  const emailProvided = !!watchCustomerDetailData?.email || !!customerFormEmail;

  // find available service timing with ticket, date time.
  useEffect(() => {
    if (!isFetchingSchedules && dataServiceTimings?.length && ticketId) {
      if (!listServiceId?.length) {
        setServiceTimingItem(null);
        return;
      }

      const listServiceMatchTimeSlot = dataServiceTimings?.filter(
        (service) =>
          listServiceId?.includes(service?.id) && service?.ticketId === ticketId
      );

      setServiceTimingItem(
        listServiceMatchTimeSlot?.length
          ? listServiceMatchTimeSlot?.[0] || null
          : null
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataServiceTimings?.length,
    isFetchingSchedules,
    ticketId,
    listServiceId,
  ]);

  // validate data edited reservation
  useEffect(() => {
    if (!isFetchingEditedResrv && editedReservationData) {
      const {
        ticket,
        rDateTime,
        tables,
        isWalkIn,
        diningInterval,
        numberOfChildren,
        occasions,
        notes,
        staffNotes,
        numberOfAdults,
        otherOccasions,
        reservationPaymentRequest,
        tags,
      } = editedReservationData;

      //  NOTE:
      // handle old data occasion, must to remove content Others in data occasion
      // example: "Anniversary, Couple Date, Others: test"
      // => "Anniversary, Couple Date"
      const occasionsFilter = (occasions || '')
        ?.split(', ')
        ?.filter(
          (ocs) =>
            dataMerchantReservation?.occasions?.findIndex(
              (occasionData) => occasionData.name === ocs
            ) !== -1
        );

      const tableId = tables?.[0]?.id;
      if (tags) {
        setRersvSelectedTags(tags);
      }
      if (!isWalkIn && rDateTime) {
        const [valueDate = '', valueTimeSlot = ''] = format(
          rDateTime,
          'yyyy-MM-dd,HH:mm:ss'
        ).split(',');
        setDateRersvEdited(valueDate);
        setTimeRersvEdited(valueTimeSlot);
      }

      const tablesValue = isEnableTableCombination
        ? !!tables?.length &&
          !tables?.some((item) => unavailTableIds.includes(item?.id))
          ? tables?.map((item) => ({
              id: item?.id,
              name: item?.name,
              maxPax: item?.maxPax,
              isAutoAssigned: false,
              errors: [],
            }))
          : null
        : tableId && !unavailTableIds?.includes(tableId)
        ? [
            {
              errors: [],
              id: tableId,
              name: tables?.[0]?.name || '',
            },
          ]
        : null;

      resetReactHookForm({
        ticket: {
          color: ticket?.color || '',
          isActive: ticket?.isActive || false,
          label: ticket?.name || '',
          value: ticket?.id || '',
        },
        tables: tablesValue,
        specialRequest: notes || '',
        staffNote: staffNotes || '',
        otherOccasion: otherOccasions || '',
        diningInterval: diningInterval || DEFAULT_DINING_INTERVAL,
        adultsPax: numberOfAdults,
        childrenPax: numberOfChildren,
        occasionsSelected: !!otherOccasions
          ? [...occasionsFilter, 'Others']
          : occasionsFilter,
        dateTime: !isWalkIn ? rDateTime : null,
        paymentType:
          reservationPaymentRequest &&
          reservationPaymentRequest.type === 'OTHER_METHOD'
            ? 'OTHER_METHOD'
            : 'ONLINE_PAYMENT_LINK',
        notePaymentRequest:
          (reservationPaymentRequest && reservationPaymentRequest.notes) || '',
      });

      setReservationType(isWalkIn ? 'WalkIn' : 'Reservation');
      setAllowChildrenDining(numberOfChildren > 0);

      if (isEnableTableCombination) {
        if (
          actionType === 'Edit' &&
          tables?.some((item) => unavailTableIds.includes(item?.id)) //
        ) {
          setError('tables', { type: 'tableAvailCheck' });
        }
      } else {
        if (actionType === 'Edit' && unavailTableIds?.includes(tableId || '')) {
          setError('tables', { type: 'tableAvailCheck' });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editedReservationData,
    isFetchingEditedResrv,
    dataMerchantReservation?.occasions,
  ]);

  // update diningInterval base on selected schedule
  useEffect(() => {
    setValue(
      'diningInterval',
      serviceTimingItem?.diningInterval || DEFAULT_DINING_INTERVAL
    );
  }, [serviceTimingItem, setValue]);

  // set initial value
  useEffect(() => {
    if (initialValue) {
      const { diningInterval, reservationDate, reservationTime, tables } =
        initialValue;
      setValue('diningInterval', diningInterval || DEFAULT_DINING_INTERVAL);
      if (isEnableTableCombination) {
        if (tables) {
          setValue(
            'tables',
            tables?.map((item) => ({
              ...item,
              isAutoAssigned: false,
              errors: [],
            }))
          );
        }
      } else {
        if (tables) {
          setValue('tables', [
            {
              ...tables[0],
              isAutoAssigned: false,
              errors: [],
            } as SelectedTable,
          ]);
        }
      }

      if (reservationDate && reservationTime) {
        setValue(
          'dateTime',
          parse(
            reservationDate + ' ' + reservationTime,
            'yyyy-MM-dd HH:mm:ss',
            new Date()
          )
        );
      }
    }
  }, [initialValue, setValue, reservationType, isEnableTableCombination]);

  const { checkBeforeLeave } = useUnsavedChange(
    openModal ? isDirty : openModal
  );

  const renderItemRadioGroup = (value: PaymentType) => (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <FormControlLabel
        value={value}
        control={<Radio onClick={handleChangeDefaultPaymentStatus} />}
        label={
          value === 'ONLINE_PAYMENT_LINK'
            ? t('Online Payment Link')
            : t('Manual Payment')
        }
        disabled={!editableReservationFields.includes('PAYMENT_TYPE')}
        sx={{ mr: 0 }}
      />
      <TouchTooltip
        sx={{
          '.MuiTooltip-tooltip': {
            py: 2,
          },
        }}
        title={
          value === 'ONLINE_PAYMENT_LINK'
            ? t('Payment is done online and through a link sent by Oddle')
            : t('Payment is done in person or through external means')
        }
        arrow
      >
        <HelpTwoToneIcon color="action" />
      </TouchTooltip>
    </Box>
  );
  // Logic feature add PWA reservation

  const isTicketPrice = useMemo(() => {
    return selectedTicket?.typePayment !== TICKET_TYPE_PAYMENT['No Payment'];
  }, [selectedTicket]);

  const handleNextStepPWA = async () => {
    const hasDate = watchDateTime !== null;
    const hasTables = watchTables && watchTables.length;
    const hasCustomer = watchCustomerDetailData !== null;

    const proceedToNextStep = (nextStep: number) =>
      actionType === 'Add' ? setActiveStepPWA(nextStep) : handleSubmit();

    switch (activeStepPWA) {
      case 0:
        if (reservationType === 'Reservation') {
          if (!hasDate || !hasTables) {
            setError(hasDate ? 'tables' : 'dateTime', { type: 'required' });
          } else {
            proceedToNextStep(1);
          }
        } else {
          if (!hasTables) {
            setError(hasDate ? 'tables' : 'dateTime', { type: 'required' });
          } else {
            proceedToNextStep(1);
          }
        }
        break;
      case 1:
        if (reservationType === 'Reservation') {
          if (!hasCustomer) {
            if (customerInfoActionState === 'ADD') {
              isTicketPrice ? handlePWASubmitCustomer() : handleSubmit();
            } else {
              setError('customerDetailData', { type: 'required' });
            }
          } else {
            actionType === 'Add' && isTicketPrice
              ? setActiveStepPWA(2)
              : handleSubmit();
          }
        } else {
          handleSubmit();
        }
        break;
      case 2:
        handleSubmit();
        break;
      default:
        break;
    }
  };
  const getButtonText = () => {
    switch (true) {
      case actionType === 'Edit':
        return <Trans>Save Changes</Trans>;
      case reservationType === 'Reservation':
        switch (true) {
          case (!isTicketPrice && activeStepPWA < 1) ||
            (isTicketPrice && activeStepPWA < 2):
            return <Trans>Next</Trans>;
          case (!isTicketPrice && activeStepPWA === 1) ||
            (isTicketPrice && activeStepPWA === 2):
            return <Trans>Add Reservation</Trans>;
        }
        break;
      case reservationType === 'WalkIn':
        switch (true) {
          case activeStepPWA < 1:
            return <Trans>Next</Trans>;
          case activeStepPWA === 1:
            return <Trans>Add Walk-in</Trans>;
        }
        break;
      default:
        return null;
    }
  };

  const IconPWAModal = () => {
    if (actionType === 'Edit' || activeStepPWA === 0) {
      return (
        <IconButton
          sx={{ position: 'absolute', left: 16 }}
          onClick={handleCloseModal}
        >
          <CloseIcon />
        </IconButton>
      );
    } else
      return (
        <IconButton
          sx={{ position: 'absolute', left: 16 }}
          onClick={() => setActiveStepPWA(activeStepPWA - 1)}
        >
          <ArrowBackIosTwoToneIcon />
        </IconButton>
      );
  };

  const renderStepPWA = (stepIndex: number) => {
    switch (stepIndex) {
      case 0:
        return (
          <>
            {reservationType === 'Reservation' && (
              <Controller
                control={control}
                name="ticket"
                rules={{ required: true }}
                render={({
                  field: { onChange, value, ref },
                  fieldState: { error },
                }) => (
                  <TicketSelection
                    dataTickets={dataTickets?.items || []}
                    isLoading={isFetchingTickets}
                    valueTicket={value}
                    actionType={actionType}
                    typePaymentTicketEdit={typePaymentTicketEdit}
                    onChangeTicket={(e: TicketItem | null) => {
                      onChange(e);
                    }}
                    error={
                      error?.type === 'required' ? t('Ticket is required') : ''
                    }
                    disabled={
                      !editableReservationFields.includes('TICKET_TYPE')
                    }
                    ref={ref}
                  />
                )}
              />
            )}
            <Stack>
              {reservationType === 'Reservation' && (
                <Box sx={{ flex: 1 }}>
                  <TitleField
                    style={{ marginTop: '24px', marginBottom: '16px' }}
                  >
                    <Trans>DATE AND TIME</Trans>
                  </TitleField>
                  <Controller
                    control={control}
                    name="dateTime"
                    rules={{ required: true }}
                    render={({
                      field: { onChange, ref },
                      fieldState: { error },
                    }) => (
                      <DateTimeReservation
                        selectedTicket={selectedTicket}
                        selectDateTimeReservation={(date, timeSlot) => {
                          setListServiceId(timeSlot.listServiceTimingIds);
                          onChange(
                            parse(
                              `${date} ${timeSlot.time}`,
                              'yyyy-MM-dd HH:mm:ss',
                              new Date()
                            )
                          );
                        }}
                        error={
                          error?.type === 'required'
                            ? t('Date and time is required')
                            : ''
                        }
                        disabled={!editableReservationFields.includes('DATE')}
                        dateReservation={
                          valueDate
                            ? parse(valueDate, 'yyyy-MM-dd', new Date())
                            : dateRersvEdited
                            ? parse(dateRersvEdited, 'yyyy-MM-dd', new Date())
                            : null
                        }
                        timeReservation={valueTimeSlot || timeRersvEdited}
                        ref={ref}
                        dateRersvEdited={
                          dateRersvEdited
                            ? parse(dateRersvEdited, 'yyyy-MM-dd', new Date())
                            : null
                        }
                        timeRersvEdited={timeRersvEdited}
                      />
                    )}
                  />
                </Box>
              )}
            </Stack>

            <Box>
              <Box
                sx={{
                  marginTop: 3,
                  marginBottom: 2,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <TitleField>
                  <Trans>Dining Duration</Trans>
                </TitleField>
              </Box>
              <Controller
                control={control}
                name="diningInterval"
                render={({ field: { onChange, value } }) => (
                  <DiningInterval
                    value={value}
                    onChange={onChange}
                    disabled={
                      !editableReservationFields.includes('DINING_INTERVAL')
                    }
                  />
                )}
              />
            </Box>

            <Box
              sx={{
                marginTop: '24px',
                marginBottom: '16px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <TitleField>
                <Trans>NO. OF ADULTS</Trans>
              </TitleField>
              {!!serviceTimingItem && reservationType === 'Reservation' && (
                <Typography
                  variant="caption"
                  sx={{
                    color: theme.palette.grey['50080'],
                  }}
                >
                  *
                  <Trans>
                    Recommended for this service:{' '}
                    {{ minPax: serviceTimingItem?.minPartySize || 0 }}-
                    {{ maxPax: serviceTimingItem?.maxPartySize || 0 }}
                    pax
                  </Trans>
                </Typography>
              )}
            </Box>
            <Controller
              control={control}
              name="adultsPax"
              render={({ field: { onChange, value } }) => (
                <SelectPax
                  onChange={onChange}
                  value={value}
                  titleContent={t('No. of Adults')}
                  disabled={!editableReservationFields.includes('#_OF_ADULTS')}
                  to={200}
                />
              )}
            />

            <Box
              sx={{
                marginTop: '24px',
                marginBottom: '16px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <TitleField>
                <Trans>Dining with CHILDREN?</Trans>
              </TitleField>
              <Switch
                checked={allowChildrenDining}
                onChange={(e) => {
                  if (!e.target.checked) setValue('childrenPax', 0);
                  setAllowChildrenDining(e.target.checked);
                }}
              />
            </Box>

            {allowChildrenDining && (
              <Controller
                control={control}
                name="childrenPax"
                render={({ field: { onChange, value } }) => (
                  <SelectPax
                    onChange={onChange}
                    value={value}
                    titleContent={t('No. of Children')}
                    disabled={
                      !editableReservationFields.includes('#_OF_CHILDREN')
                    }
                    to={serviceTimingItem?.maxPartySize || 200}
                  />
                )}
              />
            )}

            <Box sx={{ marginTop: '24px' }}>
              <Controller
                control={control}
                name="tables"
                rules={{
                  required: true,
                  validate: {
                    tableAvailCheck: (tables) => {
                      if (!unavailTableIds?.length) return true;
                      if (!tables) return false;

                      if (!isEnableTableCombination)
                        return !unavailTableIds.includes(
                          String(tables?.[0]?.id)
                        );

                      return !tables?.some((item) =>
                        unavailTableIds.includes(item?.id)
                      );
                    },
                  },
                }}
                render={({ field: { ref }, fieldState: { error } }) => (
                  <AssignTableMenu
                    areas={dataAreas || []}
                    date={valueDate}
                    time={valueTimeSlot}
                    paxCount={watchAdultsPax + watchChildrenPax}
                    selectedDiningInterval={watchDiningInterval}
                    selectedTables={
                      watchTables || editedReservationSelectedTable
                    }
                    selectTables={(tables) => {
                      setValue('tables', tables);
                      setError('tables', {});
                    }}
                    reservationType={reservationType}
                    error={
                      error?.type === 'required'
                        ? t('Table is required')
                        : error?.type === 'tableAvailCheck'
                        ? t(
                            'Table assigned previously is no longer in use, please select another table.'
                          )
                        : ''
                    }
                    isLoading={isFetchingAreas}
                    disabled={!editableReservationFields.includes('TABLE')}
                    selectedServiceTiming={serviceTimingItem}
                    editedReservationId={editedReservationId}
                    isAffectedReservation={isAffectedReservation}
                    ref={ref}
                  />
                )}
              />
            </Box>

            {occasionSection}
            {reservationTagsSection}

            <TitleField style={{ marginTop: '24px' }}>
              <Trans>CUSTOMER’S SPECIAL REQUEST (OPTIONAL)</Trans>
            </TitleField>
            <TextField
              multiline
              fullWidth
              rows={4}
              inputProps={{
                maxLength: 120,
              }}
              sx={{ marginTop: '13px' }}
              placeholder={t('e.g. Dietary requests, allergies, instructions')}
              {...register('specialRequest')}
              disabled={
                !editableReservationFields.includes('SPECIAL_REQUEST_BY_DINER')
              }
            />
            <FormHelperText
              sx={{
                textAlign: 'right',
                color: theme.palette.text.disabled,
              }}
            >
              <Trans>
                {{ characterNumber: watchSpecialRequest?.length }}/120
                characters
              </Trans>
            </FormHelperText>

            <TitleField style={{ marginTop: '24px' }}>
              <Trans>STAFF NOTES (OPTIONAL)</Trans>
            </TitleField>
            <TextField
              multiline
              fullWidth
              rows={4}
              inputProps={{
                maxLength: 120,
              }}
              sx={{ marginTop: '13px' }}
              placeholder={t('e.g. Table instructions, requests')}
              {...register('staffNote')}
              disabled={
                !editableReservationFields.includes(
                  'RESERVATION_NOTES_BY_STAFF'
                )
              }
            />
            <FormHelperText
              sx={{
                textAlign: 'right',
                color: theme.palette.text.disabled,
              }}
            >
              <Trans>
                {{ characterNumber: watchStaffNote?.length }}/120 characters
              </Trans>
            </FormHelperText>
          </>
        );
      case 1:
        return (
          <Grid item xs={5} sx={{ mt: 3 }}>
            <CustomerReservationInfor
              action={
                !!editedReservationData?.user ||
                mcaIdPrefillCustomer ||
                dataResendPayment?.user ||
                watchCustomerDetailData
                  ? 'SEE_DETAIL'
                  : 'SEARCH'
              }
              requireCustomer={reservationType === 'Reservation'}
              isRequireEmailForOnlinePayment={isOnlinePaymentRequest}
              getCustomerData={(customerData) => {
                setValue('customerDetailData', customerData);
              }}
              error={
                errors?.customerDetailData ? t('Customer is required') : ''
              }
              idCustomerToSee={
                editedReservationData?.user?.mcaId ||
                mcaIdPrefillCustomer ||
                dataResendPayment?.user?.mcaId ||
                watchCustomerDetailData?.mcaId ||
                ''
              }
              disabled={
                !editableReservationFields.includes('ASSOCIATED_CUSTOMER')
              }
              brandName={dataMerchantReservation?.brand?.brandName || ''}
              forwardedRef={customerDetailRef}
              onActionStateChange={setCustomerInfoActionState}
            />
            {reservationType === 'Reservation' && (
              <CustomerNotifications
                emailEnabled={emailProvided ? emailNotify : false}
                smsEnabled={smsNotify}
                emailOnToggle={(isActive) => {
                  setEmailNotify(isActive);
                }}
                emailProvided={emailProvided}
                smsOnToggle={(isActive) => {
                  setSMSNotify(isActive);
                }}
                automatedCallEnabled={automatedCallNotify}
                automatedCallOnToggle={(isActive) => {
                  setAutomatedCallNotify(isActive);
                }}
                style={{ marginTop: '24px' }}
                readOnlyPanel={actionType === 'Edit'}
              />
            )}
          </Grid>
        );
      case 2:
        if (
          reservationType === 'Reservation' &&
          selectedTicket?.typePayment !== TICKET_TYPE_PAYMENT['No Payment']
        ) {
          return (
            <Card
              sx={{
                width: '100%',
                boxShadow: theme.customShadows.card,
                mt: '24px',
              }}
            >
              <CardContent>
                <PaymentRequestPaxDetail
                  numberOfAdults={watchAdultsPax}
                  numberOfChildren={watchChildrenPax}
                  ticketPrice={selectedTicket?.price || 0}
                  typePayment={selectedTicket?.typePayment || 2}
                  typePrice={selectedTicket?.typePrice}
                  style={{ marginTop: '24px' }}
                  readOnlyPanel={actionType === 'Edit'}
                />

                <Box>
                  <Divider sx={{ marginBottom: '24px' }} />
                  <Box sx={{ paddingBottom: '4px' }}>
                    <Typography
                      sx={{
                        color: '#919EAB',
                        fontSize: '12px',
                        fontWeight: '700',
                      }}
                    >
                      <Trans>PAYMENT TYPE</Trans>
                    </Typography>
                  </Box>
                  <Controller
                    control={control}
                    name="paymentType"
                    render={({ field }) => (
                      <RadioGroup
                        {...field}
                        sx={{ padding: '0 0 24px 10px' }}
                        defaultValue={watchPaymentType}
                      >
                        {renderItemRadioGroup('ONLINE_PAYMENT_LINK')}
                        {renderItemRadioGroup('OTHER_METHOD')}
                      </RadioGroup>
                    )}
                  />

                  {watchPaymentType === 'ONLINE_PAYMENT_LINK' ? (
                    <PaymentRequestOnlineDetail
                      {...paymentRequestProps}
                      defaultSMSNotify={defaultSMSNotify}
                      defaultEmailNotify={defaultEmailNotify}
                    />
                  ) : (
                    <PaymentRequestOtherDetail
                      {...paymentRequestProps}
                      isTicketGuaranteed={isCardGuaranteeTicket}
                    />
                  )}
                </Box>

                <Box>
                  <Divider sx={{ margin: '12px 0px 24px 0px' }} />
                  <Typography
                    sx={{
                      color: '#919EAB',
                      fontSize: '12px',
                      fontWeight: '700',
                    }}
                  >
                    <Trans>NOTE FOR PAYMENT REQUEST</Trans>
                  </Typography>
                  <Box sx={{ paddingTop: '12px' }}>
                    <TextareaAutosize
                      {...register('notePaymentRequest')}
                      onChange={handleNotePaymentRequest}
                      disabled={
                        !editableReservationFields.includes('PAYMENT_TYPE')
                      }
                      css={css`
                        ::placeholder {
                          color: #919eab;
                        }
                      `}
                      placeholder={t(
                        'e.g. Amount payable as discussed with guest'
                      )}
                      maxLength={120}
                      style={{
                        width: '100%',
                        height: 104,
                        borderRadius: 8,
                        border: `1px solid ${theme.palette.grey[500_32]}`,
                        padding: '16px 14px',
                        color: '#212B36',
                        fontWeight: '500',
                        fontSize: 16,
                        fontFamily: 'Public Sans,system',
                      }}
                    />

                    <Box sx={{ textAlign: 'end' }}>
                      <Typography
                        sx={{
                          color: '#919EAB',
                          fontSize: '12px',
                          fontWeight: '500',
                        }}
                      >
                        <Trans>
                          {{
                            characterNumber: watchPaymentNote?.length,
                          }}
                          /120 characters
                        </Trans>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          );
        }

      default:
        return null;
    }
  };

  return (
    <>
      <Drawer
        transitionDuration={disableTransition ? 0 : undefined}
        anchor="right"
        open={openModal}
        onClose={handleCloseModal}
        css={css`
          .MuiDrawer-paper {
            width: calc(100% - 80px);
            min-width: 880px;
            max-width: 1080px;
            @media (max-width: 880px) {
              width: 100%;
              min-width: unset;
              max-width: unset;
            }
          }
        `}
        className={clsx('ActionReservationModal-root', className)}
      >
        {actionType === 'Edit' && (error || isFetchingEditedResrv) ? (
          <LoadingScreen />
        ) : !isMobileView ? (
          <Box
            css={css`
              width: auto;
              display: flex;
              flex-direction: column;
              flex: 0 0 auto;
              height: 100%;

              @media (max-width: 880px) {
                width: 880px;
              }
            `}
          >
            <DialogTitle
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                boxShadow: theme.customShadows.z1,
                zIndex: 1,
                padding: '16px',
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: 600 }}>
                {actionType === 'Add' ? (
                  reservationType === 'Reservation' ? (
                    <Trans>Add Reservation</Trans>
                  ) : (
                    <Trans>Add Walk-in</Trans>
                  )
                ) : reservationType === 'Reservation' ? (
                  <Trans>Edit Reservation</Trans>
                ) : (
                  <Trans>Edit Walk-in</Trans>
                )}
              </Typography>
              <IconButton
                sx={{ marginLeft: '16px' }}
                onClick={handleCloseModal}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent
              css={css`
                &.MuiDialogContent-root {
                  padding: 16px;
                }
              `}
            >
              <Grid container spacing={2}>
                <Grid item xs={7}>
                  <Form>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="flex-start"
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{ color: theme.palette.common.black }}
                      >
                        {reservationType === 'Reservation' ? (
                          <Trans>Reservation Details</Trans>
                        ) : (
                          <Trans>Walk-in Details</Trans>
                        )}
                      </Typography>
                      {actionType === 'Add' && (
                        <ToggleButtonGroup
                          color="primary"
                          value={reservationType}
                          sx={{
                            borderColor: 'rgba(145, 158, 171, 0.32)',
                            overflow: 'hidden',
                            '.MuiToggleButton-root': {
                              margin: 'unset',
                              borderRadius: 'unset !important',
                              paddingLeft: '21px',
                              paddingRight: '21px',
                              color: theme.palette.text.primary,
                              '&.Mui-selected': {
                                color: theme.palette.primary.main,
                                backgroundColor: theme.palette.primary.main_12,
                              },
                            },
                          }}
                        >
                          <ToggleButton
                            value="Reservation"
                            onClick={() => {
                              switchReservationType('Reservation');
                            }}
                          >
                            <Trans>Reservation</Trans>
                          </ToggleButton>
                          <ToggleButton
                            value="WalkIn"
                            onClick={() => {
                              switchReservationType('WalkIn');
                            }}
                          >
                            <Trans>Walk-In</Trans>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      )}
                    </Grid>

                    {reservationType === 'Reservation' && (
                      <Controller
                        control={control}
                        name="ticket"
                        rules={{ required: true }}
                        render={({
                          field: { onChange, value, ref },
                          fieldState: { error },
                        }) => (
                          <TicketSelection
                            dataTickets={dataTickets?.items || []}
                            isLoading={isFetchingTickets}
                            valueTicket={value}
                            actionType={actionType}
                            typePaymentTicketEdit={typePaymentTicketEdit}
                            onChangeTicket={(e: TicketItem | null) => {
                              onChange(e);
                            }}
                            error={
                              error?.type === 'required'
                                ? t('Ticket is required')
                                : ''
                            }
                            disabled={
                              !editableReservationFields.includes('TICKET_TYPE')
                            }
                            ref={ref}
                          />
                        )}
                      />
                    )}
                    <Stack direction="row" sx={{ gap: theme.spacing(5) }}>
                      {reservationType === 'Reservation' && (
                        <Box sx={{ flex: 1 }}>
                          <TitleField
                            style={{ marginTop: '24px', marginBottom: '16px' }}
                          >
                            <Trans>DATE AND TIME</Trans>
                          </TitleField>
                          <Controller
                            control={control}
                            name="dateTime"
                            rules={{ required: true }}
                            render={({
                              field: { onChange, ref },
                              fieldState: { error },
                            }) => (
                              <DateTimeReservation
                                selectedTicket={selectedTicket}
                                selectDateTimeReservation={(date, timeSlot) => {
                                  setListServiceId(
                                    timeSlot.listServiceTimingIds
                                  );
                                  onChange(
                                    parse(
                                      `${date} ${timeSlot.time}`,
                                      'yyyy-MM-dd HH:mm:ss',
                                      new Date()
                                    )
                                  );
                                }}
                                error={
                                  error?.type === 'required'
                                    ? t('Date and time is required')
                                    : ''
                                }
                                disabled={
                                  !editableReservationFields.includes('DATE')
                                }
                                dateReservation={
                                  valueDate
                                    ? parse(valueDate, 'yyyy-MM-dd', new Date())
                                    : dateRersvEdited
                                    ? parse(
                                        dateRersvEdited,
                                        'yyyy-MM-dd',
                                        new Date()
                                      )
                                    : null
                                }
                                timeReservation={
                                  valueTimeSlot || timeRersvEdited
                                }
                                ref={ref}
                                dateRersvEdited={
                                  dateRersvEdited
                                    ? parse(
                                        dateRersvEdited,
                                        'yyyy-MM-dd',
                                        new Date()
                                      )
                                    : null
                                }
                                timeRersvEdited={timeRersvEdited}
                              />
                            )}
                          />
                        </Box>
                      )}
                      <Box>
                        <Box
                          sx={{
                            marginTop: 3,
                            marginBottom: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          <TitleField>
                            <Trans>Dining Duration</Trans>
                          </TitleField>
                        </Box>
                        <Controller
                          control={control}
                          name="diningInterval"
                          render={({ field: { onChange, value } }) => (
                            <DiningInterval
                              value={value}
                              onChange={onChange}
                              disabled={
                                !editableReservationFields.includes(
                                  'DINING_INTERVAL'
                                )
                              }
                            />
                          )}
                        />
                      </Box>
                    </Stack>

                    <Box
                      sx={{
                        marginTop: '24px',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <TitleField>
                        <Trans>NO. OF ADULTS</Trans>
                      </TitleField>
                      {!!serviceTimingItem &&
                        reservationType === 'Reservation' && (
                          <Typography
                            variant="caption"
                            sx={{
                              color: theme.palette.grey['50080'],
                            }}
                          >
                            *
                            <Trans>
                              Recommended for this service:{' '}
                              {{ minPax: serviceTimingItem?.minPartySize || 0 }}
                              -
                              {{ maxPax: serviceTimingItem?.maxPartySize || 0 }}
                              pax
                            </Trans>
                          </Typography>
                        )}
                    </Box>
                    <Controller
                      control={control}
                      name="adultsPax"
                      render={({ field: { onChange, value } }) => (
                        <SelectPax
                          onChange={onChange}
                          value={value}
                          titleContent={t('No. of Adults')}
                          disabled={
                            !editableReservationFields.includes('#_OF_ADULTS')
                          }
                          to={200}
                        />
                      )}
                    />

                    <Box
                      sx={{
                        marginTop: '24px',
                        marginBottom: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <TitleField>
                        <Trans>Dining with CHILDREN?</Trans>
                      </TitleField>
                      <Switch
                        checked={allowChildrenDining}
                        onChange={(e) => {
                          if (!e.target.checked) setValue('childrenPax', 0);
                          setAllowChildrenDining(e.target.checked);
                        }}
                      />
                    </Box>

                    {allowChildrenDining && (
                      <Controller
                        control={control}
                        name="childrenPax"
                        render={({ field: { onChange, value } }) => (
                          <SelectPax
                            onChange={onChange}
                            value={value}
                            titleContent={t('No. of Children')}
                            disabled={
                              !editableReservationFields.includes(
                                '#_OF_CHILDREN'
                              )
                            }
                            to={serviceTimingItem?.maxPartySize || 200}
                          />
                        )}
                      />
                    )}

                    <Box sx={{ marginTop: '24px' }}>
                      <Controller
                        control={control}
                        name="tables"
                        rules={{
                          required: true,
                          validate: {
                            tableAvailCheck: (tables) => {
                              if (!unavailTableIds?.length) return true;
                              if (!tables) return false;

                              if (!isEnableTableCombination)
                                return !unavailTableIds.includes(
                                  String(tables?.[0]?.id)
                                );

                              return !tables?.some((item) =>
                                unavailTableIds.includes(item?.id)
                              );
                            },
                          },
                        }}
                        render={({ field: { ref }, fieldState: { error } }) => (
                          <AssignTableMenu
                            areas={dataAreas || []}
                            date={valueDate}
                            time={valueTimeSlot}
                            paxCount={watchAdultsPax + watchChildrenPax}
                            selectedDiningInterval={watchDiningInterval}
                            selectedTables={
                              watchTables || editedReservationSelectedTable
                            }
                            selectTables={(tables) => {
                              setValue('tables', tables);
                              setError('tables', {});
                            }}
                            reservationType={reservationType}
                            error={
                              error?.type === 'required'
                                ? t('Table is required')
                                : error?.type === 'tableAvailCheck'
                                ? t(
                                    'Table assigned previously is no longer in use, please select another table.'
                                  )
                                : ''
                            }
                            isLoading={isFetchingAreas}
                            disabled={
                              !editableReservationFields.includes('TABLE')
                            }
                            selectedServiceTiming={serviceTimingItem}
                            editedReservationId={editedReservationId}
                            isAffectedReservation={isAffectedReservation}
                            ref={ref}
                          />
                        )}
                      />
                    </Box>

                    {occasionSection}

                    {reservationTagsSection}

                    <TitleField style={{ marginTop: '24px' }}>
                      <Trans>CUSTOMER’S SPECIAL REQUEST (OPTIONAL)</Trans>
                    </TitleField>
                    <TextField
                      multiline
                      fullWidth
                      rows={4}
                      inputProps={{
                        maxLength: 120,
                      }}
                      sx={{ marginTop: '13px' }}
                      placeholder={t(
                        'e.g. Dietary requests, allergies, instructions'
                      )}
                      {...register('specialRequest')}
                      disabled={
                        !editableReservationFields.includes(
                          'SPECIAL_REQUEST_BY_DINER'
                        )
                      }
                    />
                    <FormHelperText
                      sx={{
                        textAlign: 'right',
                        color: theme.palette.text.disabled,
                      }}
                    >
                      <Trans>
                        {{ characterNumber: watchSpecialRequest?.length }}/120
                        characters
                      </Trans>
                    </FormHelperText>

                    <TitleField style={{ marginTop: '24px' }}>
                      <Trans>STAFF NOTES (OPTIONAL)</Trans>
                    </TitleField>
                    <TextField
                      multiline
                      fullWidth
                      rows={4}
                      inputProps={{
                        maxLength: 120,
                      }}
                      sx={{ marginTop: '13px' }}
                      placeholder={t('e.g. Table instructions, requests')}
                      {...register('staffNote')}
                      disabled={
                        !editableReservationFields.includes(
                          'RESERVATION_NOTES_BY_STAFF'
                        )
                      }
                    />
                    <FormHelperText
                      sx={{
                        textAlign: 'right',
                        color: theme.palette.text.disabled,
                      }}
                    >
                      <Trans>
                        {{ characterNumber: watchStaffNote?.length }}/120
                        characters
                      </Trans>
                    </FormHelperText>
                    <Button
                      type="submit"
                      ref={submitFormRef as React.RefObject<HTMLButtonElement>}
                      sx={{ display: 'none' }}
                    ></Button>
                  </Form>
                </Grid>
                <Grid item xs={5}>
                  <CustomerReservationInfor
                    action={
                      !!editedReservationData?.user ||
                      mcaIdPrefillCustomer ||
                      dataResendPayment?.user ||
                      watchCustomerDetailData
                        ? 'SEE_DETAIL'
                        : 'SEARCH'
                    }
                    requireCustomer={reservationType === 'Reservation'}
                    isRequireEmailForOnlinePayment={isOnlinePaymentRequest}
                    getCustomerData={(customerData) => {
                      setValue('customerDetailData', customerData);
                    }}
                    error={
                      errors?.customerDetailData
                        ? t('Customer is required')
                        : ''
                    }
                    idCustomerToSee={
                      editedReservationData?.user?.mcaId ||
                      mcaIdPrefillCustomer ||
                      dataResendPayment?.user?.mcaId ||
                      watchCustomerDetailData?.mcaId ||
                      ''
                    }
                    disabled={
                      !editableReservationFields.includes('ASSOCIATED_CUSTOMER')
                    }
                    brandName={dataMerchantReservation?.brand?.brandName || ''}
                    forwardedRef={customerDetailRef}
                    onActionStateChange={setCustomerInfoActionState}
                  />
                  {reservationType === 'Reservation' && (
                    <CustomerNotifications
                      emailEnabled={emailProvided ? emailNotify : false}
                      smsEnabled={smsNotify}
                      emailOnToggle={(isActive) => {
                        setEmailNotify(isActive);
                      }}
                      emailProvided={emailProvided}
                      smsOnToggle={(isActive) => {
                        setSMSNotify(isActive);
                      }}
                      automatedCallEnabled={automatedCallNotify}
                      automatedCallOnToggle={(isActive) => {
                        setAutomatedCallNotify(isActive);
                      }}
                      style={{ marginTop: '24px' }}
                      readOnlyPanel={actionType === 'Edit'}
                    />
                  )}

                  {/* New UI payment request detail*/}
                  {reservationType === 'Reservation' &&
                    selectedTicket?.typePayment !==
                      TICKET_TYPE_PAYMENT['No Payment'] && (
                      <Card
                        sx={{
                          width: '100%',
                          boxShadow: theme.customShadows.z16,
                          mt: '24px',
                        }}
                      >
                        <CardContent>
                          <PaymentRequestPaxDetail
                            numberOfAdults={watchAdultsPax}
                            numberOfChildren={watchChildrenPax}
                            ticketPrice={selectedTicket?.price || 0}
                            typePayment={selectedTicket?.typePayment || 2}
                            typePrice={selectedTicket?.typePrice}
                            style={{ marginTop: '24px' }}
                            readOnlyPanel={actionType === 'Edit'}
                          />

                          <Box>
                            <Divider sx={{ marginBottom: '24px' }} />
                            <Box sx={{ paddingBottom: '4px' }}>
                              <Typography
                                sx={{
                                  color: '#919EAB',
                                  fontSize: '12px',
                                  fontWeight: '700',
                                }}
                              >
                                <Trans>PAYMENT TYPE</Trans>
                              </Typography>
                            </Box>
                            <Controller
                              control={control}
                              name="paymentType"
                              render={({ field }) => (
                                <RadioGroup
                                  {...field}
                                  sx={{ padding: '0 0 24px 10px' }}
                                  defaultValue={watchPaymentType}
                                >
                                  {renderItemRadioGroup('ONLINE_PAYMENT_LINK')}
                                  {renderItemRadioGroup('OTHER_METHOD')}
                                </RadioGroup>
                              )}
                            />

                            {watchPaymentType === 'ONLINE_PAYMENT_LINK' ? (
                              <PaymentRequestOnlineDetail
                                {...paymentRequestProps}
                                defaultSMSNotify={defaultSMSNotify}
                                defaultEmailNotify={defaultEmailNotify}
                              />
                            ) : (
                              <PaymentRequestOtherDetail
                                {...paymentRequestProps}
                                isTicketGuaranteed={isCardGuaranteeTicket}
                              />
                            )}
                          </Box>

                          <Box>
                            <Divider sx={{ margin: '12px 0px 24px 0px' }} />
                            <Typography
                              sx={{
                                color: '#919EAB',
                                fontSize: '12px',
                                fontWeight: '700',
                              }}
                            >
                              <Trans>NOTE FOR PAYMENT REQUEST</Trans>
                            </Typography>
                            <Box sx={{ paddingTop: '12px' }}>
                              <TextareaAutosize
                                {...register('notePaymentRequest')}
                                onChange={handleNotePaymentRequest}
                                disabled={
                                  !editableReservationFields.includes(
                                    'PAYMENT_TYPE'
                                  )
                                }
                                css={css`
                                  ::placeholder {
                                    color: #919eab;
                                  }
                                `}
                                placeholder={t(
                                  'e.g. Amount payable as discussed with guest'
                                )}
                                maxLength={120}
                                style={{
                                  width: '100%',
                                  height: 104,
                                  borderRadius: 8,
                                  border: `1px solid ${theme.palette.grey[500_32]}`,
                                  padding: '16px 14px',
                                  color: '#212B36',
                                  fontWeight: '500',
                                  fontSize: 16,
                                  fontFamily: 'Public Sans,system',
                                }}
                              />

                              <Box sx={{ textAlign: 'end' }}>
                                <Typography
                                  sx={{
                                    color: '#919EAB',
                                    fontSize: '12px',
                                    fontWeight: '500',
                                  }}
                                >
                                  <Trans>
                                    {{
                                      characterNumber: watchPaymentNote?.length,
                                    }}
                                    /120 characters
                                  </Trans>
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </CardContent>
                      </Card>
                    )}
                </Grid>
              </Grid>
            </DialogContent>

            <StickyBottomToolbar>
              <Button
                onClick={handleCloseModal}
                size="medium"
                variant="outlined"
                color="inherit"
              >
                <Trans>Cancel</Trans>
              </Button>
              <Box
                css={css`
                  margin-left: 12px;
                `}
              >
                <LoadingButton
                  size="medium"
                  loading={isLoadingSubmit}
                  variant="contained"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {actionType === 'Edit' ? (
                    <Trans>Save Changes</Trans>
                  ) : reservationType === 'Reservation' ? (
                    <Trans>Add Reservation</Trans>
                  ) : (
                    <Trans>Add Walk-in</Trans>
                  )}
                </LoadingButton>
              </Box>
            </StickyBottomToolbar>
          </Box>
        ) : (
          <Box
            css={css`
              width: auto;
              display: flex;
              flex-direction: column;
              flex: 0 0 auto;
              height: 100%;
            `}
          >
            <DialogTitle
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: theme.customShadows.z1,
                zIndex: 1,
                padding: '16px',
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  fontSize: 21,
                  color: 'black',
                }}
              >
                {actionType === 'Add' ? (
                  reservationType === 'Reservation' ? (
                    <Trans>Add Reservation</Trans>
                  ) : (
                    <Trans>Add Walk-in</Trans>
                  )
                ) : reservationType === 'Reservation' ? (
                  activeStepPWA === 1 ? (
                    <Trans>Edit Customer</Trans>
                  ) : (
                    <Trans>Edit Reservation</Trans>
                  )
                ) : activeStepPWA === 1 ? (
                  <Trans>Edit Customer</Trans>
                ) : (
                  <Trans>Edit Walk-in</Trans>
                )}
              </Typography>
              <IconPWAModal />
            </DialogTitle>

            <DialogContent
              css={css`
                &.MuiDialogContent-root {
                  padding: 16px;
                  width: auto;
                }
              `}
            >
              <Box>
                {activeStepPWA === 0 && (
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: '100%' }}
                  >
                    {actionType === 'Add' && (
                      <>
                        <ToggleButtonGroup
                          color="primary"
                          value={reservationType}
                          fullWidth
                          sx={{
                            justifyContent: 'center',
                            borderColor: 'rgba(145, 158, 171, 0.32)',
                            overflow: 'hidden',
                            '.MuiToggleButton-root': {
                              margin: 'unset',
                              borderRadius: 'unset !important',
                              color: theme.palette.text.primary,
                              '&.Mui-selected': {
                                color: theme.palette.primary.main,
                                backgroundColor: theme.palette.primary.main_12,
                              },
                            },
                          }}
                        >
                          <ToggleButton
                            value="Reservation"
                            onClick={() => {
                              switchReservationType('Reservation');
                            }}
                          >
                            <Trans>Reservation</Trans>
                          </ToggleButton>
                          <ToggleButton
                            value="WalkIn"
                            onClick={() => {
                              switchReservationType('WalkIn');
                            }}
                          >
                            <Trans>Walk-In</Trans>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </>
                    )}
                  </Grid>
                )}
                {actionType === 'Add' && (
                  <HorizontalLabelStepper
                    reservationType={reservationType}
                    isTicketPrice={isTicketPrice}
                    activeStepPWA={activeStepPWA}
                  />
                )}
              </Box>

              {renderStepPWA(activeStepPWA)}
            </DialogContent>
            <StickyBottomToolbar>
              <LoadingButton
                size="large"
                loading={isLoadingSubmit}
                sx={{ width: '100%', display: 'flex' }}
                variant="contained"
                onClick={() => {
                  isMobileView ? handleNextStepPWA() : handleSubmit();
                }}
              >
                {getButtonText()}
              </LoadingButton>
            </StickyBottomToolbar>
          </Box>
        )}
      </Drawer>
      {openConfirmModal && !disableConfirmModal && (
        <ReservationConfirmation
          dataBeforeEdited={dataBeforeSubmit}
          data={dataSubmit}
          onClose={() => {
            setOpenConfirmModal(false);
          }}
          autoHide={10}
          redirectToViewModal={(id) => {
            onView && onView(id);
            setOpenConfirmModal(false);
          }}
        />
      )}
      <ActionTagModal
        open={createTagsModal.isOpen}
        customerMcaId={createTagsModal.customerMcaId}
        customerId={createTagsModal.customerId}
        type={createTagsModal.type}
      />
      <CustomerAlertModal
        open={isCustomerNeedUpdate}
        onClose={() => setIsCustomerNeedUpdate(false)}
      />
    </>
  );
}

const ActionReservationModalWithContext = (
  props: ActionReservationModalProps
) => {
  return (
    <ReservationActionContextProvider {...props}>
      <ActionReservationModal {...props} />
    </ReservationActionContextProvider>
  );
};

export default memo(ActionReservationModalWithContext);

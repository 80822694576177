import { ForwardedRef, memo, useEffect, useState } from 'react';
import style from './index.module.scss';
import CustomerDetail from './components/customer-detail';
import CustomerSearch from './components/customer-search';
import Typography from '@mui/material/Typography';
import { Trans } from 'next-i18next';
import clsx from 'clsx';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import Button from '@mui/material/Button';
import AddCustomer from '../add-customer/add-customer';
import { useTheme } from '@mui/material';
import { Customer } from '@/common/types/customer';
import { useSuggestPhoneNum } from '@/common/lib/phone-number';
import { Reservation } from '@/common/types/reservation';
import { useSetAtom } from 'jotai';
import { stepActivePWA } from '@/feat/reservation/state';
import { usePwaOrMobileView } from '@/common/hooks/use-pwa';

export type ActionWithCustomerInfor = 'SEE_DETAIL' | 'SEARCH' | 'ADD';

const regexTextOnly = /^\D+$/;

function CustomerReservationInfor(props: {
  customerAtTimeOfReservation?: Reservation['user'];
  action: ActionWithCustomerInfor;
  requireCustomer?: boolean;
  error?: string;
  changeCustomerProvided?: boolean;
  idCustomerToSee?: string;
  isWalkInCustomerAdded?: boolean;
  getCustomerData?: (data: Customer | null) => void;
  redirectToEditModal?: () => void;
  onChangeMode?: (mode: ActionWithCustomerInfor) => void;
  disabled?: boolean;
  brandName?: string;
  forwardedRef?: ForwardedRef<HTMLInputElement>;
  onActionStateChange?: (action: ActionWithCustomerInfor) => void;
  isRequireEmailForOnlinePayment?: boolean;
  isEditCustomer?: boolean;
}) {
  const { suggestPhoneNum, isPartialPhoneNum } = useSuggestPhoneNum();
  const {
    customerAtTimeOfReservation,
    action,
    requireCustomer = true,
    error,
    changeCustomerProvided = true,
    idCustomerToSee = '',
    isWalkInCustomerAdded = true,
    getCustomerData,
    redirectToEditModal,
    onChangeMode,
    disabled,
    brandName = '',
    forwardedRef,
    onActionStateChange,
    isRequireEmailForOnlinePayment,
    isEditCustomer,
  } = props;

  const [actionState, setActionState] = useState(action || 'SEARCH');
  const [customerMcaId, setCustomerMcaId] = useState(idCustomerToSee);
  const [suggestedName, setSuggestedName] = useState('');
  const [suggestedPhone, setSuggestedPhone] = useState('');
  const [suggestedEmail, setSuggestedEmail] = useState('');
  const setActiveStepPWA = useSetAtom(stepActivePWA);

  const resetSuggestedAddField = () => {
    setSuggestedName('');
    setSuggestedPhone('');
    setSuggestedEmail('');
  };
  const theme = useTheme();
  const { isMobileView } = usePwaOrMobileView();

  useEffect(() => {
    onChangeMode && onChangeMode(actionState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionState]);

  const handleSelectCustomer = (mcaId: string) => {
    setCustomerMcaId(mcaId);
    setActionState('SEE_DETAIL');
  };

  const handleAddCustomer = (searchCustomerInfo?: string) => {
    resetSuggestedAddField();
    if (searchCustomerInfo) {
      if (searchCustomerInfo?.includes('@')) {
        setSuggestedEmail(searchCustomerInfo);
      } else if (regexTextOnly.exec(searchCustomerInfo)) {
        setSuggestedName(searchCustomerInfo);
      } else if (isPartialPhoneNum(searchCustomerInfo)) {
        setSuggestedPhone(suggestPhoneNum(searchCustomerInfo));
      }
    }
    setActionState('ADD');
  };

  const titleCustomerReservationInfor = () => {
    const actionTitle = () => {
      if (
        actionState === 'SEE_DETAIL' &&
        changeCustomerProvided &&
        !isMobileView
      )
        return (
          <Button
            variant="text"
            color="primary"
            startIcon={<PersonSearchOutlinedIcon />}
            sx={{
              fontSize: '13px',
              lineHeight: '22px',
            }}
            onClick={() => {
              getCustomerData && getCustomerData(null);
              setActionState('SEARCH');
            }}
            disabled={disabled}
          >
            <Trans>Change Customer</Trans>
          </Button>
        );
      if (
        actionState === 'SEE_DETAIL' &&
        changeCustomerProvided &&
        isMobileView
      )
        return (
          <Button
            variant="text"
            color="primary"
            startIcon={<PersonSearchOutlinedIcon />}
            sx={{
              fontSize: '13px',
              lineHeight: '22px',
            }}
            onClick={() => {
              redirectToEditModal?.();
              setActiveStepPWA(1);
              getCustomerData && getCustomerData(null);
              setActionState('SEARCH');
            }}
            disabled={isEditCustomer}
          >
            <Trans>Change Customer</Trans>
          </Button>
        );
      if (actionState === 'ADD')
        return (
          <Button
            variant="text"
            color="primary"
            startIcon={<PersonSearchOutlinedIcon />}
            sx={{
              fontSize: '13px',
              lineHeight: '22px',
            }}
            onClick={() => {
              getCustomerData && getCustomerData(null);
              setActionState('SEARCH');
            }}
          >
            <Trans>Search</Trans>
          </Button>
        );
      if (actionState === 'SEARCH') {
        if (requireCustomer)
          return (
            <Typography
              variant="body2"
              className={clsx(style.search, !!error && style.error)}
            >
              <Trans>* required</Trans>
            </Typography>
          );
        return (
          <Typography variant="body2" className={style.search}>
            <Trans>optional</Trans>
          </Typography>
        );
      }

      return null;
    };

    return (
      <div className={style.titleContainer}>
        <Typography variant="subtitle1">
          {actionState === 'ADD' ? (
            <Trans>Add New Customer</Trans>
          ) : (
            <Trans>Customer Details</Trans>
          )}
        </Typography>
        {actionTitle()}
      </div>
    );
  };

  const contentCustomerReservationInfor = () => {
    if (actionState === 'SEE_DETAIL')
      return (
        <CustomerDetail
          customerAtTimeOfReservation={customerAtTimeOfReservation}
          customerMcaId={customerMcaId}
          getCustomerData={(customerData) => {
            getCustomerData && getCustomerData(customerData);
          }}
          viewFullProfileInNewTab
          isRequireEmailForOnlinePayment={isRequireEmailForOnlinePayment}
        />
      );
    if (actionState === 'SEARCH')
      return (
        <CustomerSearch
          selectCustomer={handleSelectCustomer}
          error={error}
          addCustomer={handleAddCustomer}
          isWalkInCustomerAdded={isWalkInCustomerAdded}
          redirectToEditModal={redirectToEditModal}
          forwardedRef={forwardedRef}
        />
      );
    if (actionState === 'ADD')
      return (
        <AddCustomer
          brandName={brandName}
          suggestedName={suggestedName}
          suggestedPhone={suggestedPhone}
          suggestedEmail={suggestedEmail}
          selectCustomer={handleSelectCustomer}
          isRequireEmailForOnlinePayment={isRequireEmailForOnlinePayment}
        />
      );
    return null;
  };

  // TODO: Refactor this component to avoid this effect
  useEffect(() => {
    if (onActionStateChange) {
      onActionStateChange(actionState);
    }
  }, [actionState, onActionStateChange]);

  return (
    <div
      className={style.customerInforContainer}
      style={{ boxShadow: theme.customShadows.card }}
    >
      {titleCustomerReservationInfor()}
      {contentCustomerReservationInfor()}
    </div>
  );
}

export default memo(CustomerReservationInfor);

import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
  useTheme,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import { forwardRef } from 'react';

type SearchInputProps = {
  onClearInput: () => void;
} & TextFieldProps;

const SearchInput = forwardRef(function SearchInput(
  { value, sx, error, onClearInput, ...restProps }: SearchInputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const theme = useTheme();
  return (
    <TextField
      value={value}
      error={error}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: value ? (
          <InputAdornment position="end">
            <IconButton sx={{ padding: 1 }} onClick={onClearInput}>
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ) : null,
      }}
      fullWidth
      sx={{
        '.MuiInputBase-input::placeholder': {
          color: `${
            error ? theme.palette.error.main : theme.palette.grey['500']
          }`,
        },
        ...sx,
      }}
      {...restProps}
      inputRef={ref}
    />
  );
});

export default SearchInput;

import { ReservationItem } from '@/common/types/reservation';
import {
  Box,
  useTheme,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import CloseIcon from '@mui/icons-material/Close';
import RestaurantSourceIcon from '@/common/components/restaurant-src-icon';
import { ResrvSrc } from '@/common/types/reservation-status-flow';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { css } from '@emotion/react';
import { titleSource } from './common';

const renderSourceHeader = (source: ResrvSrc | null, codeId: string) => {
  return (
    <Box
      css={css`
        display: flex;
        align-items: baseline;
        > svg {
          position: relative;
          top: 3px;
        }
      `}
    >
      {source && <RestaurantSourceIcon source={source} />}
      <Box
        css={css`
          display: flex;
          flex-direction: column;
          margin-left: 9px;
        `}
      >
        {source && (
          <Typography variant="body2">{titleSource[source]}</Typography>
        )}
        <Typography variant="caption" sx={{ color: '#637381' }}>
          #{codeId}
        </Typography>
      </Box>
    </Box>
  );
};

export const DrawerTitle = ({
  reservationData,
  onClose,
}: {
  reservationData: ReservationItem;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const { formatDate } = useI18nTimeUtils();
  const theme = useTheme();

  return (
    <DialogTitle
      sx={{
        display: { xs: 'none', md: 'flex' },
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: theme.customShadows.z1,
        zIndex: 1,
        padding: '16px',
      }}
    >
      <Box
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        {(() => {
          const dateTime =
            (reservationData?.rTimeState === 'TODAY' ? t('Today') + ', ' : '') +
            formatDate(reservationData?.rDateTime, {
              en: 'd LLL, h:mm a',
              zh: 'LLL do, h:mm a',
            });
          return (
            <Typography variant="h5" sx={{ marginLeft: '16px' }}>
              {reservationData?.source === 'walkIn' ? (
                <Trans>Walk-in on {{ dateTime }}</Trans>
              ) : (
                <Trans>Reservation on {{ dateTime }}</Trans>
              )}
            </Typography>
          );
        })()}
      </Box>
      <Box
        css={css`
          display: flex;
          align-items: center;
        `}
      >
        {renderSourceHeader(reservationData?.source, reservationData?.code)}
        <IconButton sx={{ marginLeft: '16px' }} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
    </DialogTitle>
  );
};

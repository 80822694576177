import { ReactNode } from 'react';
import { Table } from '@/common/types/table';
import { ReservationItem } from '@/common/types/reservation';
import { ReservationStatus } from '@/common/types/reservation-status-flow';

export interface TableInAssignMenu {
  id: string;
  name: string;
  minPax: number;
  maxPax: number;
  isBelowMinPax: boolean;
  isAboveMaxPax: boolean;
  isClashed: boolean;
  isBlocked: boolean;
  hasUpcoming: boolean;
  isOccupied: boolean;
  priorityByState: number;
  bookableOnline: boolean;
  nextReservation?: ReservationItem;
  tableStateComponent: ReactNode;
  isAreaOfSchedule: boolean;
  enabled: boolean;
  tableBlocks?: {
    id: string;
    date: string;
    from: string;
    to: string;
  }[];
}

export interface AvailableTableApiRes {
  id: string;
  outletId: string;
  layoutId?: string | null;
  name: string;
  displayName: string;
  order: number;
  color: string;
  bookableOnline: boolean;
  combineAllTable?: boolean | null;
  createdTimestamp: string;
  modifiedTimestamp: string;
  deletedTimestamp?: string | null;
  tables: Table[];
}

export const ALLOWED_BOOKING_RESERVATION_STATUS: ReservationStatus[] = [
  'R::NO_SHOW',
  'R::CANCELLED',
  'R::COMPLETED',
];

type TableErrorSource =
  | 'minPax'
  | 'maxPax'
  | 'clashed'
  | 'occupied'
  | 'outsideAreasSchedule'
  | 'blocked';

export type TableError = {
  source: TableErrorSource;
  value?: string | number | Date;
  conflictValue?: string | number | Date;
};

export type SelectedTable = {
  id: string;
  name: string;
  maxPax?: number;
  isAutoAssigned: boolean;
  errors?: TableError[];
};

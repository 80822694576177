import {
  AsYouType,
  CountryCode,
  formatNumber,
  getCountries,
  parsePhoneNumber,
} from 'libphonenumber-js';
import { useMemo } from 'react';
import useCallbackRef from '../hooks/use-callback-ref';
import useLocaleCfg from '../hooks/use-locale-cfg';

export const isValidPhoneNumber = (rawPhone: string) => {
  try {
    if (rawPhone.length === 0) return false;
    const formattedPhone = rawPhone[0] === '+' ? rawPhone : '+' + rawPhone;
    return parsePhoneNumber(formattedPhone).isValid();
  } catch (e) {
    // the error could be because the invalid in country phone input and libphonenumber-js
    // i.e. input +00 0000, parsePhoneNumber has error in this case, and this is an invalid phone number
    // for all errors which make the phone cannot be parsed to valid format, return false
    return false;
  }
};

export const getFormattedPhone = (rawPhone?: string | null) => {
  if (!rawPhone || !isValidPhoneNumber(rawPhone)) {
    return '';
  }
  return formatNumber(rawPhone, 'INTERNATIONAL');
};

export const useSuggestPhoneNum = () => {
  const { 'ISO 3166-2': countryCode } = useLocaleCfg();

  const libPhoneCode: CountryCode = useMemo(
    () => getCountries().find((x) => x === countryCode) || 'SG',
    [countryCode]
  );

  const suggestPhoneNum = useCallbackRef((partialPhoneNumber: string) => {
    if (isValidPhoneNumber(partialPhoneNumber)) return partialPhoneNumber;

    const asYouType = new AsYouType(libPhoneCode);
    asYouType.input(partialPhoneNumber);
    return (
      asYouType.getNumber()?.number?.replace(/^\+/, '') || partialPhoneNumber
    );
  });

  const isPartialPhoneNum = useCallbackRef((str: string) => {
    if (isValidPhoneNumber(str)) return true;
    if (/^\+?\d+$/.exec(str)) return true;
    return false;
  });

  return { suggestPhoneNum, isPartialPhoneNum };
};

import useI18nTimeUtils from '@/common/i18n-time-utils';
import { Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Trans } from 'next-i18next';
import { parse } from 'date-fns';
import style from './customer-detail.module.scss';
import DateRangeTwoToneIcon from '@mui/icons-material/DateRangeTwoTone';
import LocalPhoneTwoToneIcon from '@mui/icons-material/LocalPhoneTwoTone';
import CakeTwoToneIcon from '@mui/icons-material/CakeTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import NoteAltTwoToneIcon from '@mui/icons-material/NoteAltTwoTone';
import OpenInNewTwoToneIcon from '@mui/icons-material/OpenInNewTwoTone';
import { getTimeDifference } from '@/feat/reservation/utils';
import { useRouter } from 'next/router';
import { env } from '@/env.mjs';
import { useMemo } from 'react';

const iconStyle = {
  width: 16,
  height: 16,
  color: '#919EAB',
  transform: 'translateY(3px)',
};

const TypographyStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));
interface CustomerInformationProps {
  customer: {
    name?: string | null | undefined;
    phone?: string | null | undefined;
    birthday?: string | null | undefined;
    email?: string | null | undefined;
    lastName?: string | null | undefined;
    notesDiner?: string | null | undefined;
    lastVisited?: number | null | undefined;
    isProfileView?: boolean;
    mcaId?: string | null | undefined;
  };
  isRequireEmailForOnlinePayment?: boolean;
}
interface ItemProps {
  icon?: React.ReactNode;
  label: React.ReactNode;
  visible?: boolean;
}
export default function CustomerInformation({
  customer,
  isRequireEmailForOnlinePayment = false,
}: CustomerInformationProps) {
  const {
    name,
    phone,
    birthday,
    email,
    notesDiner,
    lastVisited,
    isProfileView = false,
    mcaId,
    lastName,
  } = customer;
  const { formatDate } = useI18nTimeUtils();

  const router = useRouter();
  const pathLocale =
    router.locale === 'DEFAULT_LOCALE' ? 'en_SG' : router.locale;

  const customerDetailPanel = useMemo(() => {
    return `${env.NEXT_PUBLIC_LINK_CUSTOMER_DETAILS}/${pathLocale}/customers/${mcaId}`;
  }, [pathLocale, mcaId]);

  const warningCustomerInfo = useMemo(() => {
    if (!lastName && !email) {
      return (
        <Trans>
          Email and Last Name is required to send an online payment link
        </Trans>
      );
    }
    if (!lastName) {
      return (
        <Trans>Last Name is required to send an online payment link</Trans>
      );
    }
    if (!email) {
      return <Trans>Email is required to send an online payment link</Trans>;
    }
  }, [email, lastName]);

  return (
    <Stack gap={1} alignItems="flex-start">
      <CustomerInformationItem
        label={
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h4" sx={{ fontWeight: 700 }}>
              {name}
            </Typography>

            {isProfileView ? (
              <OpenInNewTwoToneIcon
                onClick={() => {
                  window.open(customerDetailPanel, '_blank');
                }}
                sx={{
                  width: 21,
                  height: 21,
                  color: (theme) => theme.palette.grey[500],
                }}
              />
            ) : null}
          </Stack>
        }
      />

      <CustomerInformationItem
        icon={<DateRangeTwoToneIcon />}
        label={
          <TypographyStyled variant="body2">
            <Trans>
              Last {isProfileView ? 'interaction' : 'visited'}{' '}
              {{
                timeDifference: getTimeDifference(lastVisited as number),
              }}{' '}
              ago
            </Trans>
          </TypographyStyled>
        }
        visible={!!lastVisited}
      />

      <CustomerInformationItem
        icon={<LocalPhoneTwoToneIcon />}
        label={<TypographyStyled variant="body2">{phone}</TypographyStyled>}
        visible={!!phone}
      />

      <CustomerInformationItem
        icon={<CakeTwoToneIcon />}
        label={
          <TypographyStyled variant="body2">
            {birthday &&
              formatDate(parse(birthday, 'yyyy-MM-dd', new Date()), {
                en: 'dd MMM yyyy',
                zh: 'yyyy MMM do',
              })}
          </TypographyStyled>
        }
        visible={!!birthday}
      />

      <CustomerInformationItem
        icon={<EmailTwoToneIcon />}
        label={
          <TypographyStyled variant="body2" sx={{ wordWrap: 'break-word' }}>
            {email}
          </TypographyStyled>
        }
        visible={!!email}
      />

      <CustomerInformationItem
        icon={<NoteAltTwoToneIcon />}
        label={
          <TypographyStyled
            variant="body2"
            className={style.customerDetail__infor__note}
          >
            {notesDiner}
          </TypographyStyled>
        }
        visible={!!notesDiner}
      />

      {isRequireEmailForOnlinePayment && (
        <Typography
          variant="caption"
          sx={{ color: (theme) => theme.palette.error.main }}
        >
          {warningCustomerInfo}
        </Typography>
      )}
    </Stack>
  );
}

function CustomerInformationItem({ icon, label, visible = true }: ItemProps) {
  if (!visible) return null;
  return (
    <Stack
      direction="row"
      gap={0.75}
      alignItems="flex-start"
      sx={{
        '& > .MuiSvgIcon-root': {
          ...iconStyle,
        },
      }}
    >
      {icon ? icon : null}
      {label}
    </Stack>
  );
}

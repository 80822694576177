import { format } from 'date-fns';

export const getRoundingCurrentTime = () => {
  const [currHour, currMinutes] = format(new Date(), 'HH:mm:ss')?.split(':');
  if (!currHour || !currMinutes) return '';

  const minutesArray = ['00', '15', '30', '45'];
  let minute = minutesArray[0];

  for (let i = 0; i < minutesArray.length; i++) {
    if (
      Math.abs(Number(currMinutes) - Number(minutesArray[i])) <
      Math.abs(Number(currMinutes) - Number(minute))
    ) {
      minute = minutesArray[i];
    }
  }

  return [currHour, minute, '00'].join(':');
};

import React from 'react';

const ReserveByRestaurantSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="8" cy="8" r="8" fill="#F4F6F8" />
      <circle cx="8" cy="8" r="7.5" stroke="#919EAB" strokeOpacity="0.32" />
      <path
        opacity="0.3"
        d="M11.4996 10.735C11.0596 10.7 10.6246 10.625 10.1996 10.51L9.60465 11.105C10.2046 11.31 10.8446 11.44 11.5046 11.48V10.735H11.4996ZM4.51465 4.5C4.55965 5.16 4.68965 5.795 4.88965 6.4L5.48965 5.8C5.37465 5.38 5.29965 4.945 5.26965 4.5H4.51465Z"
        fill="#3D0099"
      />
      <path
        d="M6.535 5.785C6.35 5.225 6.25 4.625 6.25 4C6.25 3.725 6.025 3.5 5.75 3.5H4C3.725 3.5 3.5 3.725 3.5 4C3.5 8.695 7.305 12.5 12 12.5C12.275 12.5 12.5 12.275 12.5 12V10.255C12.5 9.98 12.275 9.755 12 9.755C11.38 9.755 10.775 9.655 10.215 9.47C10.165 9.45 10.11 9.445 10.06 9.445C9.93 9.445 9.805 9.495 9.705 9.59L8.605 10.69C7.19 9.965 6.03 8.81 5.31 7.395L6.41 6.295C6.55 6.155 6.59 5.96 6.535 5.785ZM10.2 10.51C10.625 10.63 11.06 10.705 11.5 10.735V11.48C10.84 11.435 10.205 11.305 9.6 11.105L10.2 10.51ZM4.895 6.4C4.69 5.795 4.56 5.16 4.515 4.5H5.265C5.3 4.945 5.375 5.38 5.495 5.795L4.895 6.4Z"
        fill="#3D0099"
      />
    </svg>
  );
};

export default ReserveByRestaurantSvg;

import { Box } from '@mui/material';
import { css } from '@emotion/react';

const CallingAnimation = () => {
  return (
    <Box
      css={css`
        .lds-ellipsis {
          display: inline-block;
          position: relative;
          width: 18px;
          height: 20px;
        }
        .lds-ellipsis div {
          position: absolute;
          top: 10px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: #fff;
        }
        .lds-ellipsis div:nth-child(1) {
          animation: lds-ellipsis1 1.5s infinite;
        }
        .lds-ellipsis div:nth-child(2) {
          left: 9px;
          animation: lds-ellipsis2 1.5s infinite;
        }
        .lds-ellipsis div:nth-child(3) {
          left: 18px;
          animation: lds-ellipsis3 1.5s infinite;
        }
        @keyframes lds-ellipsis1 {
          0% {
            top: 10px;
          }
          30% {
            top: 4px;
          }
          60% {
            top: 10px;
          }
        }

        @keyframes lds-ellipsis2 {
          20% {
            top: 10px;
          }
          50% {
            top: 4px;
          }
          80% {
            top: 10px;
          }
        }
        @keyframes lds-ellipsis3 {
          40% {
            top: 10px;
          }
          70% {
            top: 4px;
          }
          100% {
            top: 10px;
          }
        }
      `}
    >
      <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Box>
  );
};

export default CallingAnimation;

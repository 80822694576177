import {
  Box,
  CardProps,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  styled,
  Stack,
} from '@mui/material';
import { Trans } from 'next-i18next';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonTwoToneIcon from '@mui/icons-material/PersonTwoTone';
import useLocaleCfg from '@/common/hooks/use-locale-cfg';
import { formatCurrency } from '@/common/lib/money';
import {
  PRTicketTypePaymentInt,
  TICKET_TYPE_PRICE,
} from '@/common/types/ticket.base';
import useTicketUtils from '@/feat/settings/tickets/utils';
import { ValueOf } from '@/common/utils';

const AccordionCustom = styled(Accordion)(() => ({
  '&.MuiPaper-root': {
    height: 'fit-content ',
    background: 'unset',
    boxShadow: 'unset',
    margin: '16px 0px',
  },
  '&:before': {
    display: 'none',
  },
  '& .MuiAccordionDetails-root': {
    padding: '0',
  },
  '&.Mui-expanded': {
    '&:last-of-type, &:first-of-type': {
      margin: '16px 0px',
    },
  },
  '&.MuiButtonBase-root-MuiAccordionSummary-root': {
    padding: '0',
  },
}));

const AccordionSummaryCustom = styled(AccordionSummary)(() => ({
  flexDirection: 'row',
  minHeight: '40px',
  padding: '0 8px',
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0',
    '&.Mui-expanded': {
      margin: '0',
    },
  },
  '&.Mui-expanded': {
    minHeight: '40px',
  },
}));

const titleTextStyle = {
  color: '#212B36',
  fontSize: '16px',
  fontWeight: '400',
};

const grayTextStyle = {
  color: 'grey.600',
  fontSize: '16px',
  fontWeight: '400',
  mr: '8px',
};

export type PaymentRequestPaxProps = {
  readOnlyPanel?: boolean;
  numberOfAdults: number;
  numberOfChildren?: number;
  ticketPrice: number;
  typePayment: PRTicketTypePaymentInt;
  defaultExpand?: boolean;
  typePrice: ValueOf<typeof TICKET_TYPE_PRICE> | null | undefined;
};

export default function PaymentRequestPaxDetail({
  readOnlyPanel = false,
  numberOfAdults,
  numberOfChildren = 0,
  ticketPrice,
  typePayment,
  defaultExpand = false,
  typePrice,
}: PaymentRequestPaxProps & CardProps) {
  const { paymentDetailsPrice } = useTicketUtils();
  const locale = useLocaleCfg();
  const [isExpand, setIsExpand] = useState(defaultExpand);

  const typeTicketPayment = paymentDetailsPrice(typePayment);
  const totalPrice =
    typePrice === 1
      ? formatCurrency(
          (numberOfAdults + numberOfChildren) * ticketPrice,
          locale
        )
      : formatCurrency(ticketPrice, locale);

  const customRenderItems = (count: number, type: string) =>
    Array.from({ length: count }, (_, index) => (
      <Box
        key={index + 1}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: 0,
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Typography variant="body1" sx={titleTextStyle}>
            1&nbsp;
          </Typography>
          <Typography variant="body1" sx={titleTextStyle}>
            {type}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex' }}>
          <Typography variant="body1" sx={grayTextStyle}>
            {typeTicketPayment}
          </Typography>
          <Typography>{formatCurrency(ticketPrice, locale)}</Typography>
        </Box>
      </Box>
    ));

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: '24px' }}>
        <Typography variant="subtitle1" sx={{ color: '#000000' }}>
          {!readOnlyPanel ? (
            <Trans>Payment Request Details</Trans>
          ) : (
            <Trans>Payment Request Configuration</Trans>
          )}
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          sx={{ color: '#919EAB', fontSize: '12px', fontWeight: '700' }}
        >
          <Trans>PAYMENT BREAKDOWN</Trans>
        </Typography>
      </Box>
      <Box>
        <AccordionCustom
          expanded={isExpand}
          onChange={() => setIsExpand(!isExpand)}
        >
          <AccordionSummaryCustom
            expandIcon={<ExpandMoreIcon sx={{ fontSize: '24px' }} />}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <PersonTwoToneIcon fontSize="small" sx={{ pb: 0.25 }} />
              <Typography variant="body1" sx={titleTextStyle}>
                {numberOfAdults}&nbsp;
              </Typography>
              <Typography variant="body1" sx={titleTextStyle}>
                <Trans>Adult</Trans>
              </Typography>
              {numberOfChildren !== 0 && (
                <>
                  <Typography>,&nbsp;</Typography>
                  <Typography variant="body1" sx={titleTextStyle}>
                    {numberOfChildren}&nbsp;
                  </Typography>
                  <Typography variant="body1" sx={titleTextStyle}>
                    <Trans>Child</Trans>
                  </Typography>
                </>
              )}
            </Box>
            <Typography variant="body1" sx={titleTextStyle}>
              {totalPrice}
            </Typography>
          </AccordionSummaryCustom>

          <AccordionDetails>
            <Stack direction="column" spacing={1} sx={{ px: 1 }}>
              {typePrice === 1 ? (
                <>
                  {customRenderItems(numberOfAdults, 'Adult')}
                  {numberOfChildren !== 0 &&
                    customRenderItems(numberOfChildren, 'Child')}
                </>
              ) : (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    margin: 0,
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body1" sx={titleTextStyle}>
                      {numberOfAdults}&nbsp;
                    </Typography>
                    <Typography variant="body1" sx={titleTextStyle}>
                      <Trans>Adult</Trans>
                    </Typography>
                    {numberOfChildren !== 0 && (
                      <>
                        <Typography>,&nbsp;</Typography>
                        <Typography variant="body1" sx={titleTextStyle}>
                          {numberOfChildren}&nbsp;
                        </Typography>
                        <Typography variant="body1" sx={titleTextStyle}>
                          <Trans>Child</Trans>
                        </Typography>
                      </>
                    )}
                  </Box>

                  <Box sx={{ display: 'flex' }}>
                    <Typography variant="body1" sx={grayTextStyle}>
                      {typeTicketPayment}
                    </Typography>
                    <Typography>
                      {formatCurrency(ticketPrice, locale)}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Stack>
          </AccordionDetails>
        </AccordionCustom>
      </Box>
    </>
  );
}

import { useCustomerNameFmt } from '@/common/hooks/use-customers';
import useLocaleCfg from '@/common/hooks/use-locale-cfg';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { formatCurrency } from '@/common/lib/money';
import { MerchantReservation } from '@/common/types/merchant-reservation';
import { ReservationItem } from '@/common/types/reservation';
import { Typography } from '@mui/material';
import { isAfter, isEqual, isToday, parseISO } from 'date-fns';
import { Trans, useTranslation } from 'next-i18next';
import { useMemo } from 'react';

export const ReservationUpdateLog = ({
  reservationData,
  dataMerchantReservation,
}: {
  reservationData: ReservationItem;
  dataMerchantReservation: MerchantReservation | undefined;
}) => {
  const userNameFmt = useCustomerNameFmt();
  const { t } = useTranslation();
  const locale = useLocaleCfg();
  const { formatDate } = useI18nTimeUtils();

  const checkReservationUpdatedBy = useMemo(() => {
    if (reservationData?.reservationPaymentRequest?.status === 'paid') {
      if (
        !!reservationData?.transaction?.timeStatusLastest &&
        !!reservationData?.modifiedTimestamp &&
        (isAfter(
          parseISO(reservationData?.transaction?.timeStatusLastest),
          parseISO(reservationData?.modifiedTimestamp)
        ) ||
          isEqual(
            parseISO(reservationData?.transaction?.timeStatusLastest),
            parseISO(reservationData?.modifiedTimestamp)
          ))
      )
        return 'Customer';
    }
    return 'Restaurant';
  }, [
    reservationData?.modifiedTimestamp,
    reservationData?.reservationPaymentRequest?.status,
    reservationData?.transaction?.timeStatusLastest,
  ]);

  const stateChangedByAgentText = (isConditionTrue: boolean) => {
    if (isConditionTrue)
      return `${dataMerchantReservation?.outlet?.outletName || t('Host')}`;
    return reservationData?.user
      ? userNameFmt(reservationData?.user)
      : t('Guest');
  };

  if (!reservationData) return null;

  if (reservationData?.statusStr === 'CANCELLED') {
    if (!reservationData?.cancelledAt) return <></>;
    return (
      <Typography
        variant="caption"
        sx={{ color: '#637381', marginTop: '24px', display: 'block' }}
      >
        {reservationData?.transaction?.status === 'refunded' ? (
          <Trans>
            Cancelled and Refunded{' '}
            {{
              isToday: isToday(parseISO(reservationData?.cancelledAt))
                ? t('Today') + ', '
                : '',
            }}
            {{
              time: formatDate(parseISO(reservationData?.cancelledAt), {
                en: 'd LLL yyyy, h:mm a',
                zh: 'yyyy LLL do, h:mm a',
              }),
            }}{' '}
            by{' '}
            {{
              cancelAgent: stateChangedByAgentText(
                reservationData?.cancelledByRestaurant || false
              ),
            }}
          </Trans>
        ) : (
          <Trans>
            Cancelled{' '}
            {{
              isToday: isToday(parseISO(reservationData?.cancelledAt))
                ? t('Today') + ', '
                : '',
            }}
            {{
              time: formatDate(parseISO(reservationData?.cancelledAt), {
                en: 'd LLL yyyy, h:mm a',
                zh: 'yyyy LLL do, h:mm a',
              }),
            }}{' '}
            by{' '}
            {{
              cancelAgent: stateChangedByAgentText(
                reservationData?.cancelledByRestaurant || false
              ),
            }}
          </Trans>
        )}
      </Typography>
    );
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        marginTop: '24px',
      }}
    >
      {reservationData?.modifiedTimestamp && (
        <Typography variant="caption" sx={{ color: '#637381' }}>
          <Trans>
            Updated{' '}
            {{
              isToday: isToday(parseISO(reservationData?.modifiedTimestamp))
                ? t('Today') + ', '
                : '',
            }}
            {{
              time: formatDate(parseISO(reservationData?.modifiedTimestamp), {
                en: 'd LLL yyyy, h:mm a',
                zh: 'yyyy LLL do, h:mm a',
              }),
            }}{' '}
            by{' '}
            {{
              updateAgent: stateChangedByAgentText(
                checkReservationUpdatedBy === 'Restaurant'
              ),
            }}
          </Trans>
        </Typography>
      )}

      <Typography variant="caption" sx={{ color: '#637381' }}>
        {Number(reservationData?.transaction?.amount) > 0 ? (
          <>
            {reservationData?.transaction?.status === 'succeeded' ||
            reservationData?.transaction?.status === 'paid' ||
            reservationData?.transaction?.status === 'guaranteed' ? (
              <Trans>
                Created and paid{' '}
                {{
                  amount: formatCurrency(
                    Number(reservationData?.transaction?.amount || 0),
                    locale
                  ),
                }}{' '}
                {{
                  isToday: isToday(parseISO(reservationData?.createdTimestamp))
                    ? t('Today') + ', '
                    : '',
                }}
                {{
                  time: formatDate(
                    parseISO(reservationData?.createdTimestamp),
                    {
                      en: 'd LLL yyyy, h:mm a',
                      zh: 'yyyy LLL do, h:mm a',
                    }
                  ),
                }}{' '}
                by{' '}
                {{
                  createAgent: stateChangedByAgentText(
                    reservationData?.source === 'walkIn' ||
                      reservationData?.source === 'restaurant'
                  ),
                }}
              </Trans>
            ) : (
              <Trans>
                Created{' '}
                {{
                  isToday: isToday(parseISO(reservationData?.createdTimestamp))
                    ? t('Today') + ', '
                    : '',
                }}
                {{
                  time: formatDate(
                    parseISO(reservationData?.createdTimestamp),
                    {
                      en: 'd LLL yyyy, h:mm a',
                      zh: 'yyyy LLL do, h:mm a',
                    }
                  ),
                }}{' '}
                by{' '}
                {{
                  createAgent: stateChangedByAgentText(
                    reservationData?.source === 'walkIn' ||
                      reservationData?.source === 'restaurant'
                  ),
                }}
              </Trans>
            )}
          </>
        ) : (
          <Trans>
            Created{' '}
            {{
              isToday: isToday(parseISO(reservationData?.createdTimestamp))
                ? t('Today') + ', '
                : '',
            }}
            {{
              time: formatDate(parseISO(reservationData?.createdTimestamp), {
                en: 'd LLL yyyy, h:mm a',
                zh: 'yyyy LLL do, h:mm a',
              }),
            }}{' '}
            by{' '}
            {{
              createAgent: stateChangedByAgentText(
                reservationData?.source === 'walkIn' ||
                  reservationData?.source === 'restaurant'
              ),
            }}
          </Trans>
        )}
      </Typography>
    </div>
  );
};

import { SxProps, Theme, useTheme } from '@mui/material';
import { Trans } from 'next-i18next';
import RepeatIcon from '@mui/icons-material/Repeat';
import AssignmentIndTwoToneIcon from '@mui/icons-material/AssignmentIndTwoTone';
import { Stack } from '@mui/material';
import { Chip } from '@mui/material';
import { Typography } from '@mui/material';
import CrossDinersSvg from '@/common/assets/reserve-intelligence-icon/cross-diners-icon';
import FirstTimeVisitorSvg from '@/common/assets/reserve-intelligence-icon/first-time-visitor-icon';
import { SegmentItem } from '@/common/types/segment';
import { getSegmentType } from '@/feat/reservation/utils';
import { TouchTooltip } from '../tooltip';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';
import PortraitTwoToneIcon from '@mui/icons-material/PortraitTwoTone';
import HourglassBottomTwoToneIcon from '@mui/icons-material/HourglassBottomTwoTone';
import TimelapseTwoToneIcon from '@mui/icons-material/TimelapseTwoTone';
import { useMemo } from 'react';
interface Props {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  disableTooltip?: boolean;
  segments: SegmentItem[];
  type: 'RESERVE' | 'CUSTOMER';
}

const iconSx = {
  width: 16,
  height: 16,
  marginLeft: '0 !important',
  color: '#637381',
};

const frequencyTags = [
  {
    icon: <FirstTimeVisitorSvg style={iconSx} />,
    label: <Trans>First Time Visitor</Trans>,
    value: 'FIRST_TIME_VISITOR',
  },
  {
    icon: <RepeatIcon sx={iconSx} />,
    label: <Trans>Repeat Visitor</Trans>,
    value: 'REPEAT_VISITOR',
  },
  {
    icon: <AssignmentIndTwoToneIcon sx={iconSx} />,
    label: <Trans>Regular Visitor</Trans>,
    value: 'REGULAR_CUSTOMER',
  },
  {
    icon: <CrossDinersSvg style={iconSx} />,
    label: <Trans>Cross-diners</Trans>,
    value: 'CROSS_DINERS',
  },
  {
    icon: <EmojiEventsTwoToneIcon style={iconSx} />,
    label: <Trans>Best Customers</Trans>,
    value: 'BEST_CUSTOMERS',
  },
  {
    icon: <HandshakeTwoToneIcon style={iconSx} />,
    label: <Trans>Promising</Trans>,
    value: 'PROMISING',
  },
  {
    icon: <TimelapseTwoToneIcon style={iconSx} />,
    label: <Trans>At Risk</Trans>,
    value: 'AT_RISK',
  },
  {
    icon: <HourglassBottomTwoToneIcon style={iconSx} />,
    label: <Trans>Lapsed</Trans>,
    value: 'LAPSED',
  },
  {
    icon: <PortraitTwoToneIcon style={iconSx} />,
    label: <Trans>Window Shoppers</Trans>,
    value: 'WINDOW_SHOPPERS',
  },
];

export default function CustomerSegmentTags({
  sx,
  disableTooltip = false,
  segments,
  children,
  type,
}: Props) {
  const theme = useTheme();

  const filteredSegments = useMemo(() => {
    if (!segments) {
      return [];
    }
    const includesCategory = (segment: SegmentItem, category: string) =>
      segment.categories && segment.categories.includes(category);

    const segmentsWithoutAllCustomers = segments.filter(
      (segment) => segment.name !== 'All Customers'
    );

    if (type === 'RESERVE') {
      return segmentsWithoutAllCustomers.filter((segment) =>
        includesCategory(segment, 'RESERVE')
      );
    }
    if (type === 'CUSTOMER') {
      return segmentsWithoutAllCustomers.filter(
        (segment) =>
          includesCategory(segment, 'RESERVE') ||
          includesCategory(segment, 'LIFECYCLE')
      );
    }
    return [];
  }, [segments, type]);

  return (
    <Stack direction="row" gap={0.5} sx={{ ...sx }}>
      {filteredSegments.map((item) => {
        const formattedSegmentName = getSegmentType(`${item?.name}`);

        const tag = frequencyTags.find(
          (tag) => tag.value === formattedSegmentName
        );
        if (!tag) {
          return null;
        }

        return (
          <TouchTooltip
            key={tag.value}
            sx={{
              '.MuiTooltip-tooltip': {
                p: 2,
              },
            }}
            title={!disableTooltip ? <Trans>{item?.desc}</Trans> : null}
            leaveTouchDelay={500}
            arrow
          >
            <Chip
              key={tag.value}
              label={
                <Typography variant="caption2" color="grey.600">
                  {tag.label}
                </Typography>
              }
              icon={tag.icon}
              sx={{
                borderRadius: theme.spacing(0.75),
                background:
                  item?.categories?.[0] === 'RESERVE'
                    ? theme.palette.info.main_16
                    : theme.palette.grey[50016],
                px: 1,
                '& .MuiChip-icon': {
                  marginLeft: 0,
                },
                '& .MuiChip-label': {
                  paddingRight: 0,
                },
              }}
            />
          </TouchTooltip>
        );
      })}
      {children}
    </Stack>
  );
}

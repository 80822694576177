import React, { memo, useEffect, useMemo, useState } from 'react';
import style from './customer-detail.module.scss';
import { Trans } from 'next-i18next';
import {
  useCustomerDetail,
  useCustomerNameFmt,
} from '@/common/hooks/use-customers';
import { getFallbackUserAvatar } from '@/common/utils';
import { Customer } from '@/common/types/customer';
import { API_RSRV_STATUS_MAP } from '@/common/types/reservation-status-flow';
import NextLink from '@/common/components/next-link';
import { Reservation } from '@/common/types/reservation';
import {
  Stack,
  Grid,
  Button,
  Typography,
  Skeleton,
  Avatar,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CustomerInformation from './customer-infomation';
import CustomerSegmentTags from '@/common/components/reserve-intelligence/customer-segment-tags';
import CustomerTags from '@/common/components/customer-tags';
import { createTagsModalAtom } from '@/feat/reservation/state';
import { usePwaOrMobileView } from '@/common/hooks/use-pwa';
import { useAtom } from 'jotai';
import { useGetReservationSegmentTag } from '@/common/hooks/use-reservations';

type CustomerDetailProps = {
  customerAtTimeOfReservation?: Reservation['user'];
  customerMcaId: string;
  getCustomerData?: (data: Customer) => void;
  viewFullProfileInNewTab?: boolean;
  isRequireEmailForOnlinePayment?: boolean;
};

const calcVisitCustomer = (dataReservation: Customer['reservations']) => {
  let cancelled = 0;
  let noShow = 0;
  let completed = 0;
  dataReservation?.forEach((reservation) => {
    if (reservation?.status === API_RSRV_STATUS_MAP.CANCELLED) {
      cancelled += 1;
    } else if (reservation?.status === API_RSRV_STATUS_MAP.NO_SHOW) {
      noShow += 1;
    } else if (reservation.status === API_RSRV_STATUS_MAP.COMPLETED) {
      completed += 1;
    }
  });
  return {
    cancelled,
    completed,
    noShow,
  };
};

const customerReservationStatisticLabel: Record<string, React.ReactNode> = {
  completed: <Trans>Visits</Trans>,
  noShow: <Trans>No Shows</Trans>,
  cancelled: <Trans>Cancellations</Trans>,
};

function CustomerDetail(props: CustomerDetailProps) {
  const {
    customerMcaId,
    getCustomerData,
    viewFullProfileInNewTab,
    customerAtTimeOfReservation,
    isRequireEmailForOnlinePayment,
  } = props;
  const customerNameFmt = useCustomerNameFmt();
  const { isMobileView } = usePwaOrMobileView();

  const [createTagsModal, setCreateTagsModal] = useAtom(createTagsModalAtom);
  const [loading, setLoading] = useState<boolean>(false);

  const { data: customerInfo, isFetching } = useCustomerDetail({
    userId: customerMcaId,
  });

  const {
    flatData: dataCustomerCDP,
    refetch,
    isLoading: isGetCustomerCDP,
  } = useGetReservationSegmentTag({
    customerIds: [customerInfo?.id ?? ''],
  });

  useEffect(() => {
    if (isFetching && !createTagsModal?.isOpen) {
      setLoading(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerMcaId]);

  useEffect(() => {
    if (!isFetching && customerMcaId && customerInfo) {
      setLoading(false);
      getCustomerData && getCustomerData(customerInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, customerMcaId]);

  const customerDetailRender = () => {
    const name = customerAtTimeOfReservation
      ? customerNameFmt(customerAtTimeOfReservation)
      : customerInfo
      ? customerNameFmt(customerInfo)
      : null;
    const phone = customerAtTimeOfReservation?.phone || customerInfo?.phone;
    const birthday =
      customerAtTimeOfReservation?.birthday || customerInfo?.birthday;
    const email = customerAtTimeOfReservation?.email || customerInfo?.email;
    const notesDiner = customerInfo?.notesDiner;
    const lastVisited = dataCustomerCDP[0]?.reservationLastVisitedDate;
    const lastName = customerAtTimeOfReservation
      ? customerAtTimeOfReservation?.lastName
      : customerInfo?.lastName;

    return (
      <CustomerInformation
        customer={{
          name,
          lastName,
          phone,
          birthday,
          email,
          notesDiner,
          lastVisited,
        }}
        isRequireEmailForOnlinePayment={isRequireEmailForOnlinePayment}
      />
    );
  };

  const countCustomerVisited: Record<string, number> = useMemo(
    () => calcVisitCustomer(customerInfo?.reservations),
    [customerInfo]
  );

  return (
    <>
      <div className={style.customerDetail}>
        <div className={style.customerDetail__top}>
          {loading ? (
            <Skeleton variant="circular" width={40} height={40} />
          ) : (
            <Avatar
              src={
                customerInfo?.photoUrl ||
                getFallbackUserAvatar(customerInfo?.salutation)
              }
            />
          )}

          {loading ? (
            <Skeleton
              variant="rounded"
              width="100%"
              height={90}
              sx={{ marginLeft: '8px' }}
            />
          ) : (
            <Stack gap={3}>
              {customerDetailRender()}

              <Stack gap={1}>
                <Typography variant="overline" color="text.secondary">
                  <Trans>SEGMENTS</Trans>
                </Typography>

                {isGetCustomerCDP ? (
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={24}
                    sx={{ width: '100%' }}
                  />
                ) : (
                  <CustomerSegmentTags
                    segments={dataCustomerCDP[0]?.segments ?? []}
                    type={'CUSTOMER'}
                    sx={{
                      flexWrap: 'wrap',
                      '& .MuiChip-root': {
                        height: 24,
                      },
                    }}
                  />
                )}
              </Stack>

              <Stack gap={1}>
                <Typography variant="overline" color="text.secondary">
                  <Trans>CUSTOMER TAGS</Trans>
                </Typography>

                {isGetCustomerCDP ? (
                  <Skeleton
                    variant="rounded"
                    width="100%"
                    height={24}
                    sx={{ width: '100%' }}
                  />
                ) : (
                  <CustomerTags
                    tags={dataCustomerCDP[0]?.tags || []}
                    customerMcaId={customerMcaId}
                    onDeleted={refetch}
                    onOpenModalAddTag={() =>
                      setCreateTagsModal({
                        isOpen: true,
                        type: 'customer',
                        customerMcaId: customerMcaId,
                        customerId: customerInfo?.id || '',
                      })
                    }
                    sx={{
                      flexWrap: 'wrap',
                      '& .MuiChip-root': {
                        height: 22,
                      },
                    }}
                  />
                )}
              </Stack>
            </Stack>
          )}
        </div>

        {!isMobileView &&
          (loading ? (
            <Skeleton
              variant="rounded"
              width="100%"
              height={38}
              sx={{ marginTop: '16px', display: { md: 'flex' } }}
            />
          ) : (
            <Button
              LinkComponent={NextLink}
              variant="outlined"
              href={`/customers/${customerMcaId}`}
              target={viewFullProfileInNewTab ? '_blank' : undefined}
              color="inherit"
              sx={{
                width: '100%',
                padding: '6px 0px',
                marginTop: '16px',
                display: { md: 'flex' },
              }}
            >
              <Trans>View Full Profile</Trans>
            </Button>
          ))}

        {loading ? (
          <Skeleton
            variant="rounded"
            width="100%"
            height={78}
            sx={{ marginTop: '24px' }}
          />
        ) : (
          <Grid
            container
            spacing={0.75}
            sx={{
              height: '70px',
            }}
            mt={3}
          >
            {Object.entries(countCustomerVisited).map((it) => (
              <CustomerReservationStatisticItem
                key={it[0]}
                countValue={countCustomerVisited[it[0]] || 0}
                itemLabel={customerReservationStatisticLabel[it[0]]}
              />
            ))}
          </Grid>
        )}
      </div>
    </>
  );
}

export default memo(CustomerDetail);

function CustomerReservationStatisticItem({
  countValue,
  itemLabel,
}: {
  countValue: number;
  itemLabel: React.ReactNode;
}) {
  const theme = useTheme();
  return (
    <Grid item xs={4}>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: theme.palette.grey[500_8],
          borderRadius: theme.spacing(1),
          padding: theme.spacing(1),
        }}
      >
        <Typography variant="h5">{countValue}</Typography>
        <Typography variant="caption" color="text.secondary">
          {itemLabel}
        </Typography>
      </Stack>
    </Grid>
  );
}

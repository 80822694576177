import React from 'react';
import { ResrvSrc } from '@/common/types/reservation-status-flow';
import LogoGoogleSvg from './logo-google-svg';
import ReserveByRestaurantSvg from './reserve-by-restaurant-svg';
import WalkInSvg from './walk-in-svg';
import { assertUnreachable } from '@/common/utils';
import ReserveByOnlineSvg from './reserve-by-online-svg';

interface IProps {
  source: ResrvSrc | null;
}

const RestaurantSourceIcon = (props: IProps) => {
  const { source } = props;

  const renderIcon = React.useMemo(() => {
    switch (source) {
      case null:
        return null;
      case 'google':
        return <LogoGoogleSvg />;
      case 'restaurant':
        return <ReserveByRestaurantSvg />;
      case 'customer':
        return (
          <ReserveByOnlineSvg />
        );
      case 'walkIn':
        return <WalkInSvg />;
      default:
        assertUnreachable(source);
        return null;
    }
  }, [source]);

  return <>{renderIcon}</>;
};

export default RestaurantSourceIcon;

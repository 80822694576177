import { z } from 'zod';
import { ZReservation } from './reservation';
import { ZTableInAreaWithoutRef } from './table-in-area.base';

export const ZTableDashboardLayout = z.array(
  ZTableInAreaWithoutRef.extend({
    disableByDate: z.string().nullable(),
    enabled: z.boolean(),
    maxPax: z.number(),
    name: z.string().nullable(),
    shape: z.string().nullable(),
    size: z.string().nullable(),
    status: z.number(),
    reservations: z.array(
      ZReservation.omit({
        tables: true,
        serviceTiming: true,
        user: true,
        pax: true,
        createdByCustomer: true,
        transaction: true,
        transactions: true,
      })
    ),
  })
);
export type TableDashboardLayout = z.infer<typeof ZTableDashboardLayout>;

import { useCustomerNameFmt } from '@/common/hooks/use-customers';
import useLocaleCfg from '@/common/hooks/use-locale-cfg';
import useCustomerNotificationSettings from '@/common/hooks/use-customer-notification-settings';
import { useTranslation } from 'next-i18next';
import React from 'react';
import {
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from '@mui/material';
import ImageTwoToneIcon from '@mui/icons-material/ImageTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import { ReservationItem } from '@/common/types/reservation';
import { TICKET_TYPE_PAYMENT } from '@/common/types/ticket.base';
import { formatCurrency } from '@/common/lib/money';
import { assertUnreachable } from '@/common/utils';
import { totalPaidReservationPayment } from '../../utils';

export type ResChargeOrRefundReason = {
  internalReason: string;
  isSendToCustomer: boolean;
  messageToCustomer: string;
};

export const resChargeOrRefundType = [
  'CHARGE',
  'NOT_CHARGE',
  'REFUND',
] as const;
export type ResChargeOrRefundType = typeof resChargeOrRefundType[number];

interface IProps {
  type: ResChargeOrRefundType;
  reservation: ReservationItem;
  onClose: () => void;
  onSubmit: (resChargeOrRefundReason?: ResChargeOrRefundReason) => void;
}

function ReservationChargeOrRefundConfirmation({
  type,
  reservation,
  onClose,
  onSubmit,
}: IProps) {
  const { t } = useTranslation();
  const customerNameFmt = useCustomerNameFmt();
  const { smsNotify, emailNotify } =
    useCustomerNotificationSettings(reservation);
  const locale = useLocaleCfg();
  const { user, ticket } = reservation;

  const [internalReason, setInternalReason] = React.useState('');
  const [errorInternalReason, setErrorInternalReason] = React.useState(false);
  const [isSendToCustomer, setIsSendToCustomer] = React.useState(false);
  const [messageToCustomer, setMessageToCustomer] = React.useState('');

  const noPayment = React.useMemo(
    () =>
      reservation?.ticket?.typePayment === TICKET_TYPE_PAYMENT['No Payment'],
    [reservation]
  );

  const labelHeader = React.useMemo(() => {
    switch (type) {
      case 'CHARGE':
        return t('Charge Reservation?');
      case 'NOT_CHARGE':
        return t('Not Charge Reservation?');
      case 'REFUND':
        return t('Refund Reservation?');
      default:
        assertUnreachable(type);
        return '';
    }
  }, [type, t]);

  const labelContent = React.useMemo(() => {
    const price = formatCurrency(
      totalPaidReservationPayment({
        pax: reservation.pax,
        price: ticket?.price,
        typePrice: ticket?.typePrice,
      }),
      locale
    );
    switch (type) {
      case 'CHARGE':
        if (smsNotify && emailNotify) {
          return t(
            'You are about to charge {{customer}}’s reservation for a payment of {{price}} for a no show or late cancellation. This action cannot be undone. Charges will be sent directly to the payment method used for bookings. Charge times vary by bank. Charge notifications will be sent to customer via SMS and email.',
            { customer: user && customerNameFmt(user), price }
          );
        }
        if (smsNotify) {
          return t(
            'You are about to charge {{customer}}’s reservation for a payment of {{price}} for a no show or late cancellation. This action cannot be undone. Charges will be sent directly to the payment method used for bookings. Charge times vary by bank. Charge notifications will be sent to customer via SMS.',
            { customer: user && customerNameFmt(user), price }
          );
        }
        if (emailNotify) {
          return t(
            'You are about to charge {{customer}}’s reservation for a payment of {{price}} for a no show or late cancellation. This action cannot be undone. Charges will be sent directly to the payment method used for bookings. Charge times vary by bank. Charge notifications will be sent to customer via email.',
            { customer: user && customerNameFmt(user), price }
          );
        }
        return t(
          'You are about to charge {{customer}}’s reservation for a payment of {{price}} for a no show or late cancellation. This action cannot be undone. No notification will be sent to customer.',
          { customer: user && customerNameFmt(user), price }
        );

      case 'NOT_CHARGE':
        if (smsNotify && emailNotify) {
          return t(
            'You are about to not charge {{customer}}’s reservation for a payment of {{price}}. This action cannot be undone. No charge notifications will be sent to customer via SMS and email.',
            { customer: user && customerNameFmt(user), price }
          );
        }
        if (smsNotify) {
          return t(
            'You are about to not charge {{customer}}’s reservation for a payment of {{price}}. This action cannot be undone. No charge notifications will be sent to customer via SMS.',
            { customer: user && customerNameFmt(user), price }
          );
        }
        if (emailNotify) {
          return t(
            'You are about to not charge {{customer}}’s reservation for a payment of {{price}}. This action cannot be undone. No charge notifications will be sent to customer via email.',
            { customer: user && customerNameFmt(user), price }
          );
        }
        return t(
          'You are about to not charge {{customer}}’s reservation for a payment of {{price}}. This action cannot be undone. No notification will be sent to customer.',
          { customer: user && customerNameFmt(user), price }
        );

      case 'REFUND':
        if (smsNotify && emailNotify) {
          return t(
            'You are about to refund {{customer}}’s reservation for a payment of {{price}}. This action cannot be undone. Refunds will be directly to the payment method used for bookings. Refund times vary by bank. Refund notification will be sent to customer via SMS and email.',
            { customer: user && customerNameFmt(user), price }
          );
        }
        if (smsNotify) {
          return t(
            'You are about to refund {{customer}}’s reservation for a payment of {{price}}. This action cannot be undone. Refunds will be directly to the payment method used for bookings. Refund times vary by bank. Refund notification will be sent to customer via SMS.',
            { customer: user && customerNameFmt(user), price }
          );
        }
        if (emailNotify) {
          return t(
            'You are about to refund {{customer}}’s reservation for a payment of {{price}}. This action cannot be undone. Refunds will be directly to the payment method used for bookings. Refund times vary by bank. Refund notification will be sent to customer via email.',
            { customer: user && customerNameFmt(user), price }
          );
        }
        return t(
          'You are about to refund {{customer}}’s reservation for a payment of {{price}}. This action cannot be undone. Refunds will be directly to the payment method used for bookings. Refund times vary by bank. No notification will be sent to customer.',
          { customer: user && customerNameFmt(user), price }
        );
      default:
        assertUnreachable(type);
        return '';
    }
  }, [
    reservation.pax,
    ticket?.price,
    ticket?.typePrice,
    locale,
    type,
    smsNotify,
    emailNotify,
    t,
    user,
    customerNameFmt,
  ]);

  const placeholderInternalReason = React.useMemo(() => {
    switch (type) {
      case 'CHARGE':
        return t('Internal Charge Reason');
      case 'NOT_CHARGE':
        return t('Internal No Charge Reason');
      case 'REFUND':
        return t('Internal Refund Reason');
      default:
        assertUnreachable(type);
        return '';
    }
  }, [type, t]);

  const labelErrorInternalReason = React.useMemo(() => {
    switch (type) {
      case 'CHARGE':
        return t('Internal Charge Reason is required');
      case 'NOT_CHARGE':
        return t('Internal No Charge Reason is required');
      case 'REFUND':
        return t('Internal Refund Reason is required');
      default:
        assertUnreachable(type);
        return '';
    }
  }, [type, t]);

  const labelConfirmBtn = React.useMemo(() => {
    switch (type) {
      case 'CHARGE':
        return t('Charge');
      case 'NOT_CHARGE':
        return t('Not Charge');
      case 'REFUND':
        return t('Refund');
      default:
        assertUnreachable(type);
        return '';
    }
  }, [type, t]);

  if (noPayment) return null;

  return (
    <Dialog
      open={true}
      onClose={onClose}
      sx={{
        '& > .MuiBackdrop-root': {
          background: 'rgba(22, 28, 36, 0.72)',
        },
        '& .MuiDialog-paper': {
          maxWidth: '900px',
        },
      }}
    >
      <DialogTitle sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box className="div-center" sx={{ p: 1 }}>
            <ImageTwoToneIcon sx={{ color: 'grey.500', mr: 1, fontSize: 24 }} />
            <Typography variant="h6">{labelHeader}</Typography>
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ fontSize: 28 }} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          px: 3,
          py: 0,
        }}
      >
        <Typography variant="body1" sx={{ color: 'grey.600', mb: 3 }}>
          {labelContent}
        </Typography>

        <TextField
          value={internalReason}
          onChange={(e) => {
            const { value } = e.target;
            setInternalReason(value);
            if (errorInternalReason) setErrorInternalReason(false);
          }}
          sx={{ mb: 1 }}
          rows={4}
          fullWidth
          multiline
          inputProps={{
            maxLength: 120,
          }}
          placeholder={placeholderInternalReason}
          helperText={errorInternalReason && labelErrorInternalReason}
          error={errorInternalReason}
        />
        <Box sx={{ textAlign: 'end', mb: 1 }}>
          <Typography variant="caption" sx={{ color: 'grey.500' }}>
            {internalReason?.length || 0}/120 {t('characters')}
          </Typography>
        </Box>
        <FormGroup sx={{ ml: 1.5, mb: isSendToCustomer ? 4 : 0 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isSendToCustomer}
                onChange={() => {
                  if (isSendToCustomer) {
                    setMessageToCustomer('');
                  }
                  setIsSendToCustomer(!isSendToCustomer);
                }}
              />
            }
            label={
              type === 'REFUND'
                ? t('Send a customised refund message to customer')
                : t('Send a customised message to customer')
            }
          />
        </FormGroup>
        {isSendToCustomer && (
          <>
            <TextField
              value={messageToCustomer}
              onChange={(e) => {
                const { value } = e.target;
                setMessageToCustomer(value);
              }}
              sx={{ mb: 1 }}
              rows={4}
              fullWidth
              multiline
              inputProps={{
                maxLength: 120,
              }}
              placeholder={
                type === 'REFUND'
                  ? t('Refund Message to Customer')
                  : t('Message to Customer')
              }
            />
            <Box sx={{ textAlign: 'end' }}>
              <Typography variant="caption" sx={{ color: 'grey.500' }}>
                {messageToCustomer?.length || 0}/120 {t('characters')}
              </Typography>
            </Box>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="text" sx={{ color: 'grey.800' }} onClick={onClose}>
          {t('Close')}
        </Button>
        <Button
          color={type === 'CHARGE' ? 'success' : 'error'}
          sx={{ color: '#fff' }}
          onClick={() => {
            if (!internalReason) {
              setErrorInternalReason(true);
              return;
            }

            onSubmit({
              internalReason,
              isSendToCustomer,
              messageToCustomer,
            });
          }}
        >
          {labelConfirmBtn}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ReservationChargeOrRefundConfirmation;

import { z } from 'zod';
import { ZReservation, enhanceReservation } from './reservation';
import {
  paymentStatusResponseKey,
  TRANSACTION_SOURCE,
  ZTransactionWithoutRef,
} from './transaction.base';

export const ZTransaction = ZTransactionWithoutRef.extend({
  reservation: ZReservation.nullish().transform(
    (x) => x && enhanceReservation(x)
  ),
});
export type Transaction = z.infer<typeof ZTransaction>;

export const ZTransactionHistory = z.object({
  id: z.string(),
  amount: z.string(),
  createdTimestamp: z.string(),
  messageForCustomer: z.string().nullish(),
  modifiedTimestamp: z.string().nullish(),
  reason: z.string().nullish(),
  referenceId: z.string(),
  reservationId: z.string(),
  source: z.enum(TRANSACTION_SOURCE).nullable().catch(null),
  status: z.enum(paymentStatusResponseKey).nullish().catch(null),
});
export type TransactionHistory = z.infer<typeof ZTransactionHistory>;

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Trans } from 'next-i18next';
import { css } from '@emotion/react';
import PublicIcon from '@mui/icons-material/Public';
import {
  ForwardedRef,
  forwardRef,
  memo,
  ReactElement,
  useEffect,
  useState,
} from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Ticket } from '@/common/types/ticket';
import { useMediaQuery, useTheme } from '@mui/material';
import {
  ticketColor,
  TICKET_TYPE_PAYMENT,
  PRTicketTypePaymentInt,
} from '@/common/types/ticket.base';
import AdvanceSelect from '@/common/components/advance-select';
import { isOnlineTicket } from '@/common/utils';
import sortBy from 'lodash/sortBy';
import Label from '@/common/components/label';
import useTicketUtils from '@/feat/settings/tickets/utils';
import { useFeatureFlags } from '@/common/hooks/use-feature-flag';
import { usePwaOrMobileView } from '@/common/hooks/use-pwa';

export type TicketItem = {
  value: string;
  label: string;
  color: string;
  isActive: boolean;
  isOnline: boolean;
  price: number;
  typePayment: number | null;
  typePrice: number | null;
};

type TicketSelectionProps = {
  dataTickets: Ticket[];
  isLoading?: boolean;
  valueTicket: TicketItem | null;
  error?: string;
  onChangeTicket: (e: TicketItem | null) => void;
  disabled?: boolean;
  actionType: string;
  typePaymentTicketEdit: number | null;
};

const TicketItemCustom = ({
  ticketItem,
  disabled = false,
}: {
  ticketItem: TicketItem;
  disabled?: boolean;
}): ReactElement => {
  const theme = useTheme();
  const { generatePriceOfTicket } = useTicketUtils();
  const { isMobileView } = usePwaOrMobileView();

  return (
    <Box>
      <Box
        css={css`
          padding: 4px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
        `}
      >
        <Box
          css={css`
            display: flex;
            justify-content: flex-start;
            align-items: center;

            width: ${ticketItem?.isActive
              ? ticketItem?.isOnline
                ? 'calc(100% - 74px)'
                : '100%'
              : 'calc(100% - 64px)'};
          `}
        >
          {ticketItem?.color && (
            <span
              css={css`
                display: block;
                margin-right: 12px;
                margin-top: 4px;
                height: 16px;
                width: 16px;
                align-self: flex-start;
                background: ${disabled
                  ? theme.palette.grey[50032]
                  : ticketColor(ticketItem?.color).swatch};
                border-radius: 50%;
              `}
            />
          )}
          <Box sx={{ flexDirection: 'row', maxWidth: `calc(100% - 50px)` }}>
            <Typography
              variant="body1"
              css={css`
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                color: ${disabled
                  ? theme.palette.text.disabled
                  : theme.palette.text.primary};
              `}
            >
              {ticketItem?.label}
            </Typography>
            {ticketItem?.price > 0 && (
              <Box
                component="span"
                sx={{
                  fontSize: isMobileView ? '12px' : '14px',
                  lineHeight: '22px',
                  color: theme.palette.text.secondary,
                  marginRight: '4px',
                }}
              >
                {ticketItem &&
                  generatePriceOfTicket(
                    ticketItem.price,
                    ticketItem.typePayment as PRTicketTypePaymentInt,
                    ticketItem.typePrice
                  )}
              </Box>
            )}
          </Box>
        </Box>
        {ticketItem?.isActive ? (
          ticketItem?.isOnline && (
            <Label
              sx={{
                backgroundColor: theme.palette.info.main_8,
                color: theme.palette.info.light,
              }}
            >
              <PublicIcon
                sx={{ color: theme.palette.info.light, marginRight: '5px' }}
              />

              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '12px',
                  lineHeight: '20px',
                }}
              >
                <Trans>Online</Trans>
              </Typography>
            </Label>
          )
        ) : (
          <Label color="default" variant="disabled">
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '12px',
                lineHeight: '20px',
              }}
            >
              <Trans>Inactive</Trans>
            </Typography>
          </Label>
        )}
      </Box>
    </Box>
  );
};

const TicketSelection = forwardRef(function TicketSelection(
  {
    dataTickets = [],
    isLoading = false,
    onChangeTicket,
    valueTicket,
    error,
    disabled = false,
    actionType,
    typePaymentTicketEdit,
  }: TicketSelectionProps,
  ref: ForwardedRef<HTMLSelectElement>
) {
  const [ticketList, setTicketList] = useState<TicketItem[] | []>([]);
  const theme = useTheme();
  const matchIpadScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { isEnablePaymentRequest } = useFeatureFlags();

  useEffect(() => {
    if (!isLoading) {
      if (!dataTickets.length) {
        setTicketList([]);
        onChangeTicket(null);
        return;
      }

      if (disabled) {
        // https://bitbucket.org/oddle/reserve-host-v3/pull-requests/321#comment-368049255
        // handle case where reservation ticket is a paid ticket and not show up in the select
        const findTicket = dataTickets.find(
          (ticket) => ticket.id === valueTicket?.value
        );

        if (!findTicket) return;

        setTicketList([
          {
            value: findTicket.id,
            label: findTicket.name,
            color: findTicket.color,
            isActive: findTicket.isActive,
            isOnline: isOnlineTicket(findTicket),
            price: findTicket.price ?? 0,
            typePayment: findTicket.typePayment,
            typePrice: findTicket.typePrice ?? 1,
          },
        ]);

        return;
      }

      const listFreeTicket = dataTickets.filter(
        (ticket) => ticket.typePayment === TICKET_TYPE_PAYMENT['No Payment']
      );

      const listFreeTicketSorted = sortBy(listFreeTicket, ['order']);

      const listAllTicketSorted = sortBy(dataTickets, ['order']);

      const listTicketToShow =
        (actionType === 'Edit' && typePaymentTicketEdit === 1) ||
        !isEnablePaymentRequest
          ? listFreeTicketSorted
          : listAllTicketSorted;

      const listInactiveTicket = listTicketToShow.filter(
        (ticket) => !ticket.isActive
      );

      const listActiveTicket = listTicketToShow.filter(
        (ticket) => ticket.isActive
      );

      const listTicketWrap = listActiveTicket
        .concat(listInactiveTicket)
        .map((ticket) => ({
          value: ticket.id,
          label: ticket.name,
          color: ticket.color,
          isActive: ticket.isActive,
          isOnline: isOnlineTicket(ticket),
          price: ticket.price ?? 0,
          typePayment: ticket.typePayment,
          typePrice: ticket.typePrice ?? 1,
        }));

      setTicketList(listTicketWrap);

      // default ticket
      if (!valueTicket) {
        onChangeTicket(listTicketWrap?.[0] || null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataTickets.length, valueTicket, isLoading]);

  //Disable select the ticket Inactive
  const handleChange = (selectedTicket: TicketItem | null) => {
    if (selectedTicket && selectedTicket.isActive) {
      onChangeTicket(selectedTicket);
    }
  };

  return (
    <Box
      sx={{ marginTop: '24px' }}
      css={css`
        .Menu-List {
          max-height: 250px;
        }
      `}
    >
      <Typography
        variant="overline"
        sx={{
          letterSpacing: '1.2px',
          color: '#919EAB',
          display: 'block',
          marginBottom: '12px',
        }}
      >
        <Trans>TICKET</Trans>
      </Typography>
      {isLoading ? (
        <Skeleton variant="rounded" width="100%" height={56} />
      ) : (
        <AdvanceSelect
          options={ticketList}
          formatOptionLabel={(ticketItem) => (
            <TicketItemCustom ticketItem={ticketItem} disabled={disabled} />
          )}
          menuMaxHeight="260px"
          onChange={handleChange}
          value={
            valueTicket
              ? ticketList?.find(
                  (ticket) => ticket?.value === valueTicket?.value
                )
              : null
          }
          error={error}
          disabled={disabled}
          noOptionsMessage={() => (
            <Box
              sx={{
                backgroundColor: theme.palette.grey['100'],
                height: '90px',
                borderRadius: '16px',
                margin: '-8px -12px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ color: theme.palette.grey['600'] }}
              >
                <Trans>You don’t have any tickets yet</Trans>
              </Typography>
              <Typography variant="caption" sx={{ marginTop: '4px' }}>
                <Trans>
                  Ensure that you have tickets set up in the settings page
                </Trans>
              </Typography>
            </Box>
          )}
          ref={ref}
          isSearchable={!matchIpadScreen}
          components={
            disabled
              ? {
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }
              : undefined
          }
        />
      )}
    </Box>
  );
});

export default memo(TicketSelection);

import { Add } from '@mui/icons-material';
import { memo, ForwardedRef, useState } from 'react';
import style from './customer-search.module.scss';
import { Trans, useTranslation } from 'next-i18next';
import { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InfiniteScroll from 'react-infinite-scroll-component';
import CircularProgress from '@mui/material/CircularProgress';
import { useCustomerNameFmt, useCustomers } from '@/common/hooks/use-customers';
import { Customer } from '@/common/types/customer';
import { getFallbackUserAvatar } from '@/common/utils';
import Avatar from '@mui/material/Avatar';
import { Box, FormHelperText, useMediaQuery, useTheme } from '@mui/material';
import SearchInput from '@/common/components/search-input';
import { SecondaryButton } from '@/common/components/buttons';
import { stepActivePWA } from '@/feat/reservation/state';
import { useSetAtom } from 'jotai';
import { usePwaOrMobileView } from '@/common/hooks/use-pwa';

interface CustomerResultSearchProps {
  listOfCustomers: Customer[];
  loadNextCustomers: () => void;
  selectCustomer: (customer: Customer) => void;
  hasMoreCustomers: boolean;
}

interface CustomerSearchProps {
  selectCustomer: (idCustomer: string) => void;
  error?: string;
  isWalkInCustomerAdded?: boolean;
  addCustomer: (searchCustomerInfo?: string) => void;
  redirectToEditModal?: () => void;
  forwardedRef?: ForwardedRef<HTMLInputElement>;
}

const AddButton = ({ children, ...props }: ButtonProps) => (
  <SecondaryButton
    startIcon={<Add />}
    sx={{
      width: '100%',
      height: 48,
      fontSize: 15,
      marginTop: '16px',
      '& .MuiButton-startIcon>*:nth-of-type(1)': {
        fontSize: '24px',
      },
    }}
    {...props}
  >
    {children}
  </SecondaryButton>
);

const MemoisedCustomerResultSearch = memo(function CustomerResultSearch(
  props: CustomerResultSearchProps
) {
  const {
    listOfCustomers,
    loadNextCustomers,
    selectCustomer,
    hasMoreCustomers,
  } = props;
  const customerNameFmt = useCustomerNameFmt();

  const isBigScreen = useMediaQuery('(min-width:1920px)');
  const ITEM_HEIGHT = 61;
  const listHeight = isBigScreen ? ITEM_HEIGHT * 5 : ITEM_HEIGHT * 3;

  return !listOfCustomers?.length ? null : (
    <InfiniteScroll
      dataLength={listOfCustomers?.length}
      next={loadNextCustomers}
      hasMore={hasMoreCustomers}
      loader={
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress />
        </div>
      }
      height={listHeight}
      className={style.customerResultContainer}
    >
      {listOfCustomers.map((customer) => (
        <div
          className={style.customerResult__item}
          key={customer?.id}
          onClick={() => {
            selectCustomer(customer);
          }}
        >
          <Avatar
            src={
              customer.photoUrl || getFallbackUserAvatar(customer.salutation)
            }
          />
          <div className={style.customerResult__infor}>
            <Typography variant="body2">
              {customer && customerNameFmt(customer)}
            </Typography>
            <Typography
              variant="body2"
              className={style.customerResult__infor__phone}
            >
              {customer?.phone || ''}
            </Typography>
          </div>
        </div>
      ))}
    </InfiniteScroll>
  );
});

function CustomerSearch(props: CustomerSearchProps) {
  const {
    selectCustomer,
    error,
    isWalkInCustomerAdded = true,
    addCustomer,
    redirectToEditModal,
    forwardedRef,
  } = props;
  const [valueSearch, setValueSearch] = useState('');
  const { t } = useTranslation();

  const {
    isLoading: isLoadingDataFetch,
    total: totalCustomers,
    flatData,
    fetchNextPage,
    hasNextPage,
  } = useCustomers({ searchText: valueSearch, pageSize: 20 });

  const theme = useTheme();

  const trimmedValueSearch = valueSearch?.trim();

  const dataSearchResult = !trimmedValueSearch ? [] : flatData;

  const { isMobileView } = usePwaOrMobileView();

  const setActiveStepPWA = useSetAtom(stepActivePWA);

  const handleSelectCustomer = (customer: Customer) => {
    selectCustomer(customer.id);
  };

  const sectionSearchResults = (() => (
    <>
      <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>
        {!dataSearchResult?.length ? (
          <Trans>No results found</Trans>
        ) : (
          <Trans>
            {{ resultTotal: totalCustomers }} results found with “
            {{ searchVal: trimmedValueSearch }}”
          </Trans>
        )}
      </Typography>
      <AddButton
        onClick={() => {
          addCustomer(trimmedValueSearch);
        }}
      >
        <Trans>Add “{{ searchVal: valueSearch }}” As Customer</Trans>
      </AddButton>
      <MemoisedCustomerResultSearch
        listOfCustomers={dataSearchResult}
        loadNextCustomers={() => {
          if (hasNextPage && !isLoadingDataFetch) fetchNextPage();
        }}
        selectCustomer={handleSelectCustomer}
        hasMoreCustomers={!!hasNextPage}
      />
    </>
  ))();

  return (
    <div className={style.customerSearch}>
      {isWalkInCustomerAdded ? (
        <>
          <SearchInput
            value={valueSearch}
            error={!!error}
            placeholder={t('Phone or name')}
            onChange={(e) => {
              setValueSearch(e.target.value);
            }}
            onClearInput={() => {
              setValueSearch('');
            }}
            sx={{
              marginTop: 2,
            }}
            ref={forwardedRef}
          />
          {!!error && <FormHelperText error>{error}</FormHelperText>}
          {trimmedValueSearch ? (
            <Box sx={{ paddingTop: 2 }}>
              {isLoadingDataFetch ? (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                sectionSearchResults
              )}
            </Box>
          ) : null}
        </>
      ) : (
        <>
          <Typography
            variant="body2"
            sx={{ marginTop: '24px', color: theme.palette.text.secondary }}
          >
            <Trans>
              Add customer to your directory or link an existing customer
              profile
            </Trans>
          </Typography>
          <AddButton
            onClick={() => {
              redirectToEditModal && redirectToEditModal();
              isMobileView && setActiveStepPWA(1);
            }}
          >
            <Trans>Add Customer Details</Trans>
          </AddButton>
        </>
      )}
    </div>
  );
}

export default memo(CustomerSearch);

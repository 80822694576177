import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import CloseIcon from '@mui/icons-material/Close';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';
import { Button } from '@mui/material';
import { useReservationActionContext } from '../../context';
import NextLink from '@/common/components/next-link';
import { usePwaOrMobileView } from '@/common/hooks/use-pwa';
import { useMemo } from 'react';
import { useCustomerNameFmt } from '@/common/hooks/use-customers';
interface ActionTagModalProps {
  open: boolean;
  onClose?: () => void;
  handleCloseModal?: () => void;
}

export default function CustomerAlertModal({
  open,
  onClose,
}: ActionTagModalProps) {
  const { t } = useTranslation();
  const { isMobileView } = usePwaOrMobileView();

  const customerNameFmt = useCustomerNameFmt();
  const { reservationForm } = useReservationActionContext();
  const customerInformation = reservationForm.watch('customerDetailData');

  const labelModal = useMemo(() => {
    let message = '';
    let titleMode = isMobileView
      ? t('Use a tablet or desktop')
      : t('Please click the button below');

    if (!customerInformation?.lastName && !customerInformation?.email) {
      message = t(
        "The customer, {{customer}}, is missing a last name and an email. {{titleMode}} to add their last name and email (if unsure, you can reuse the first name or use the company's email). Try adding the reservation again once the customer profile has been updated.",
        { customer: customerNameFmt(customerInformation), titleMode }
      );
    } else if (!customerInformation?.lastName) {
      message = t(
        'The customer, {{customer}}, is missing a last name. {{titleMode}} to add their last name (if unsure, you can reuse the first name). Try adding the reservation again once the customer profile has been updated.',
        { customer: customerNameFmt(customerInformation), titleMode }
      );
    } else if (!customerInformation?.email) {
      message = t(
        "The customer, {{customer}} {{lastName}}, is missing an email. {{titleMode}} to add their email (if unsure, you can reuse the company's email). Try adding the reservation again once the customer profile has been updated.",
        { customer: customerNameFmt(customerInformation), titleMode }
      );
    }
    return message;
  }, [customerInformation, customerNameFmt, isMobileView, t]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box className="div-center" sx={{ p: 1 }}>
            <WarningTwoToneIcon
              sx={{ color: 'warning.main', mr: 1, fontSize: 24 }}
            />
            <Typography variant="h6">
              {t('Failed to add reservation and send online payment link')}
            </Typography>
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ fontSize: 28 }} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent
        sx={{
          px: 3,
        }}
      >
        <Box>
          <Typography variant="body1" sx={{ color: 'grey.600' }}>
            {labelModal}
          </Typography>
        </Box>
      </DialogContent>

      {!isMobileView && (
        <Button
          LinkComponent={NextLink}
          variant="outlined"
          href={`/customers/edit/${customerInformation?.mcaId}`}
          target={'_blank'}
          color="inherit"
          sx={{
            width: '60%',
            alignSelf: 'center',
            padding: '6px 0px',
            marginTop: '16px',
            marginBottom: '20px',
            display: { md: 'flex' },
          }}
        >
          <Trans>Edit Customer Profile</Trans>
        </Button>
      )}
    </Dialog>
  );
}

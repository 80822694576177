import React from 'react';

export default function CrossDinersSvg(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_340_6240)">
        <path
          opacity="0.3"
          d="M9.11586 4.06982C7.76799 4.06982 6.67407 5.16374 6.67407 6.51161C6.67407 7.90343 8.10008 10.0327 9.11586 11.3366C10.1463 10.0229 11.5576 7.91808 11.5576 6.51161C11.5576 5.16374 10.4637 4.06982 9.11586 4.06982ZM9.11586 7.7325C8.44193 7.7325 7.89497 7.18554 7.89497 6.51161C7.89497 5.83768 8.44193 5.29072 9.11586 5.29072C9.78979 5.29072 10.3368 5.83768 10.3368 6.51161C10.3368 7.18554 9.78979 7.7325 9.11586 7.7325Z"
          fill="#637381"
        />
        <path
          d="M9.11601 3.09326C7.22607 3.09326 5.69751 4.62182 5.69751 6.51176C5.69751 9.07564 9.11601 12.8604 9.11601 12.8604C9.11601 12.8604 12.5345 9.07564 12.5345 6.51176C12.5345 4.62182 11.006 3.09326 9.11601 3.09326ZM6.67422 6.51176C6.67422 5.1639 7.76814 4.06998 9.11601 4.06998C10.4639 4.06998 11.5578 5.1639 11.5578 6.51176C11.5578 7.91823 10.1513 10.023 9.11601 11.3367C8.10023 10.0328 6.67422 7.90358 6.67422 6.51176Z"
          fill="#637381"
        />
        <path
          d="M9.11591 7.73256C9.79019 7.73256 10.3368 7.18594 10.3368 6.51166C10.3368 5.83738 9.79019 5.29077 9.11591 5.29077C8.44163 5.29077 7.89502 5.83738 7.89502 6.51166C7.89502 7.18594 8.44163 7.73256 9.11591 7.73256Z"
          fill="#637381"
        />
      </g>
      <g clip-path="url(#clip1_340_6240)">
        <g clip-path="url(#clip2_340_6240)">
          <path
            opacity="0.3"
            d="M4.88349 1.13989C4.20956 1.13989 3.6626 1.68685 3.6626 2.36079C3.6626 3.05669 4.3756 4.12131 4.88349 4.77327C5.39871 4.11643 6.10438 3.06402 6.10438 2.36079C6.10438 1.68685 5.55742 1.13989 4.88349 1.13989ZM4.88349 2.97123C4.54652 2.97123 4.27304 2.69775 4.27304 2.36079C4.27304 2.02382 4.54652 1.75034 4.88349 1.75034C5.22046 1.75034 5.49394 2.02382 5.49394 2.36079C5.49394 2.69775 5.22046 2.97123 4.88349 2.97123Z"
            fill="#637381"
          />
          <path
            d="M4.88357 0.651367C3.9386 0.651367 3.17432 1.41565 3.17432 2.36062C3.17432 3.64255 4.88357 5.53494 4.88357 5.53494C4.88357 5.53494 6.59282 3.64255 6.59282 2.36062C6.59282 1.41565 5.82854 0.651367 4.88357 0.651367ZM3.66267 2.36062C3.66267 1.68668 4.20963 1.13972 4.88357 1.13972C5.5575 1.13972 6.10446 1.68668 6.10446 2.36062C6.10446 3.06385 5.40122 4.11626 4.88357 4.7731C4.37567 4.12114 3.66267 3.05653 3.66267 2.36062Z"
            fill="#637381"
          />
          <path
            d="M4.8834 2.97114C5.22054 2.97114 5.49384 2.69783 5.49384 2.36069C5.49384 2.02355 5.22054 1.75024 4.8834 1.75024C4.54626 1.75024 4.27295 2.02355 4.27295 2.36069C4.27295 2.69783 4.54626 2.97114 4.8834 2.97114Z"
            fill="#637381"
          />
        </g>
      </g>
      <g clip-path="url(#clip3_340_6240)">
        <g clip-path="url(#clip4_340_6240)">
          <path
            opacity="0.3"
            d="M3.25572 6.45728C2.5069 6.45728 1.89917 7.06501 1.89917 7.81382C1.89917 8.58705 2.69139 9.76996 3.25572 10.4944C3.82818 9.76454 4.61226 8.59519 4.61226 7.81382C4.61226 7.06501 4.00453 6.45728 3.25572 6.45728ZM3.25572 8.4921C2.88131 8.4921 2.57744 8.18823 2.57744 7.81382C2.57744 7.43942 2.88131 7.13555 3.25572 7.13555C3.63012 7.13555 3.93399 7.43942 3.93399 7.81382C3.93399 8.18823 3.63012 8.4921 3.25572 8.4921Z"
            fill="#637381"
          />
          <path
            d="M3.25561 5.91479C2.20564 5.91479 1.35645 6.76399 1.35645 7.81396C1.35645 9.23834 3.25561 11.341 3.25561 11.341C3.25561 11.341 5.15478 9.23834 5.15478 7.81396C5.15478 6.76399 4.30558 5.91479 3.25561 5.91479ZM1.89906 7.81396C1.89906 7.06515 2.5068 6.45741 3.25561 6.45741C4.00443 6.45741 4.61216 7.06515 4.61216 7.81396C4.61216 8.59533 3.83079 9.76468 3.25561 10.4945C2.69129 9.7701 1.89906 8.58719 1.89906 7.81396Z"
            fill="#637381"
          />
          <path
            d="M3.25567 8.49205C3.63027 8.49205 3.93394 8.18837 3.93394 7.81377C3.93394 7.43917 3.63027 7.1355 3.25567 7.1355C2.88107 7.1355 2.57739 7.43917 2.57739 7.81377C2.57739 8.18837 2.88107 8.49205 3.25567 8.49205Z"
            fill="#637381"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_340_6240">
          <rect
            width="11.7206"
            height="11.7206"
            fill="white"
            transform="translate(3.25562 2.11646)"
          />
        </clipPath>
        <clipPath id="clip1_340_6240">
          <rect
            width="5.86028"
            height="5.86028"
            fill="white"
            transform="translate(1.95337 0.163086)"
          />
        </clipPath>
        <clipPath id="clip2_340_6240">
          <rect
            width="5.86028"
            height="5.86028"
            fill="white"
            transform="translate(1.95337 0.163086)"
          />
        </clipPath>
        <clipPath id="clip3_340_6240">
          <rect
            width="6.51143"
            height="6.51143"
            fill="white"
            transform="translate(0 5.37207)"
          />
        </clipPath>
        <clipPath id="clip4_340_6240">
          <rect
            width="6.51143"
            height="6.51143"
            fill="white"
            transform="translate(0 5.37207)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

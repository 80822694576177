import { ComplexButton } from '@/common/components/buttons';
import ServiceDatePicker from '@/common/components/service-date-picker';
import { useTopNavDate } from '@/common/hooks/storage';
import { usePwaOrMobileView } from '@/common/hooks/use-pwa';
import { useMerchantReservation } from '@/common/hooks/use-reservations';
import { useGetAvailableTimeSlotInfiniteByDate } from '@/common/hooks/use-schedules';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { Ticket } from '@/common/types/ticket';
import { isOnlineTicket } from '@/common/utils';
import { AvailableTimeSlotItem } from '@/feat/schedule/types';
import CloseIcon from '@mui/icons-material/Close';
import EventIcon from '@mui/icons-material/Event';
import PublicIcon from '@mui/icons-material/Public';
import TableBarTwoToneIcon from '@mui/icons-material/TableBarTwoTone';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Skeleton,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { format, isPast, isSameDay, isToday, parse, parseISO } from 'date-fns';
import findLast from 'lodash/findLast';
import first from 'lodash/first';
import { Trans, useTranslation } from 'next-i18next';
import * as React from 'react';
import BlockDisturb from './block-disturb';
import { getRoundingCurrentTime } from './get-rounding-current-time';
import { ShowTimeslotsTypeSelection } from './show-timeslots-type-selection';
import styles from './style-date-time.module.scss';

const getDateString = (date: Date | null) => {
  if (!date) {
    return '';
  }
  return format(date, 'yyyy-MM-dd');
};

const parseDate = (dateStr: string, format = 'yyyy-MM-dd') =>
  parse(dateStr, format, new Date());

const LoadingSkeleton = () => {
  return (
    <Grid container spacing={1}>
      {Array.from(Array(15).keys()).map((e) => {
        return (
          <Grid item key={e} xs={3}>
            <Skeleton variant="rounded" width={'100%'} height={64} />
          </Grid>
        );
      })}
    </Grid>
  );
};

interface Props {
  dateReservation?: Date | null;
  timeReservation?: string;
  selectDateTimeReservation?: (
    date: string,
    time: AvailableTimeSlotItem
  ) => void;
  error?: string;
  disabled?: boolean;
  selectedTicket: Ticket | null;
  dateRersvEdited?: Date | null;
  timeRersvEdited?: string;
}
interface TimeSlotItemProps {
  isInThePast?: boolean;
  isBlocked?: boolean;
  isFull?: boolean;
  isSelected?: boolean;
  isDefault?: boolean;
  isOnlineServiceAssociated?: boolean;
  primaryValue: string;
  numAvailable: number;
  selectTimeSlot: (date: Date, value: string) => void;
  value: string;
  date: Date;
  timeSlotRef?: React.MutableRefObject<HTMLButtonElement | null>;
}

const TimeSlotItem = (props: TimeSlotItemProps) => {
  const {
    isInThePast = false,
    isBlocked = false,
    isFull = false,
    isSelected = false,
    isDefault = false,
    isOnlineServiceAssociated = false,
    primaryValue,
    numAvailable,
    selectTimeSlot,
    value,
    date,
    timeSlotRef,
  } = props;
  const { isMobileView } = usePwaOrMobileView();

  return (
    <ComplexButton
      ref={timeSlotRef}
      primary={isInThePast ? null : primaryValue}
      secondary={
        isInThePast ? null : (
          <>
            <TableBarTwoToneIcon />
            &nbsp;
            <Trans>
              {{
                numAvailable: numAvailable,
              }}{' '}
              left
            </Trans>
          </>
        )
      }
      secondaryWarn={isFull}
      selected={isSelected}
      disabled={isInThePast}
      onClick={() => {
        selectTimeSlot(date, value);
      }}
      topRightIcon={
        isInThePast || isDefault ? null : isBlocked ? (
          <BlockDisturb />
        ) : isOnlineServiceAssociated ? (
          <PublicIcon
            sx={{ color: '#64ADEA' }}
            style={{ fontSize: isMobileView ? 14 : 16 }}
          />
        ) : null
      }
      sx={{
        width: '100%',
        '&.MuiButtonBase-root': {
          padding: 0,
        },
        h6: {
          fontSize: isMobileView ? '14px' : '16px',
          whiteSpace: 'nowrap',
          width: '100%',
          py: 'unset',
        },
      }}
      value={value}
    />
  );
};
const TimeSlotItemMemoized = React.memo(TimeSlotItem);

const TimeSlotSection = ({
  date,
  timeSlots,
  selectedTime,
  selectTimeSlot,
  ticketServices,
  timeSlotRef,
  focusingTime,
}: {
  date: string;
  timeSlots: AvailableTimeSlotItem[];
  selectedTime: string | null;
  selectTimeSlot: (date: Date, time: string) => void;
  ticketServices?: Set<string> | null;
  timeSlotRef: React.MutableRefObject<HTMLButtonElement | null>;
  focusingTime: string;
}) => {
  const firstDataTimeSlot = first(timeSlots);
  const dateValue = React.useMemo(() => {
    return parse(date, 'yyyy-MM-dd', new Date());
  }, [date]);
  const time = firstDataTimeSlot
    ? parse(firstDataTimeSlot.time, 'HH:mm:ss', dateValue)
    : null;

  const { isMobileView } = usePwaOrMobileView();
  const offset = time ? 3 * Math.floor(time.getMinutes() / 15) : 0;

  if (!timeSlots.length) {
    return null;
  }

  return timeSlots.length ? (
    <>
      {offset && !isMobileView ? <Grid item xs={offset} /> : null}
      {timeSlots.map((timeSlot, index) => {
        const time = format(
          parse(timeSlot?.time, 'HH:mm:ss', dateValue),
          'h:mm a'
        );
        const value = `${format(dateValue, 'yyyy-MM-dd')} ${timeSlot.time}`;
        return (
          <Grid item xs={4} sm={3} key={index}>
            <div>
              <TimeSlotItemMemoized
                timeSlotRef={value === focusingTime ? timeSlotRef : undefined}
                date={dateValue}
                value={value}
                primaryValue={time}
                numAvailable={
                  timeSlot?.totalOfTable - timeSlot?.totalOfTableBooked
                }
                isFull={timeSlot?.totalOfTableBooked >= timeSlot?.totalOfTable}
                isBlocked={
                  timeSlot?.statusTimeSlot === 'BLOCKED_SERVICE_TIMING'
                }
                isDefault={timeSlot?.statusTimeSlot === 'DEFAULT'}
                selectTimeSlot={selectTimeSlot}
                isSelected={selectedTime === value}
                isOnlineServiceAssociated={timeSlot.listServiceTimingIds.some(
                  (id) => ticketServices?.has(id)
                )}
              />
            </div>
          </Grid>
        );
      })}
    </>
  ) : null;
};
const TimeSlotSectionMemoized = React.memo(TimeSlotSection);

const TimeSlotSectionList = ({
  sections,
  selectedTime,
  ticketServices,
  selectTimeSlot,
  timeSlotRef,
  sectionsRef,
  focusingTime,
}: {
  sections: {
    timeSlots: AvailableTimeSlotItem[];
    date: string;
  }[];
  selectedTime: string | null;
  selectTimeSlot: (date: Date, time: string) => void;
  ticketServices?: Set<string> | null;
  timeSlotRef: React.MutableRefObject<HTMLButtonElement | null>;
  sectionsRef: React.MutableRefObject<Record<string, HTMLDivElement>>;
  focusingTime: string;
}) => {
  return (
    <>
      {sections.map(({ date, timeSlots }) => (
        <Stack
          gap={2}
          key={date}
          ref={(elm) => {
            if (elm) {
              sectionsRef.current[date] = elm as HTMLDivElement;
            }
          }}
        >
          <Typography
            variant="overline"
            sx={{
              fontSize: '16px',
              fontWeight: '600',
              textTransform: 'capitalize',
            }}
          >
            {format(parseISO(String(date)), 'dd MMM yyyy')}
          </Typography>

          <Grid container spacing={1}>
            <TimeSlotSectionMemoized
              date={date}
              selectTimeSlot={selectTimeSlot}
              selectedTime={selectedTime}
              ticketServices={ticketServices}
              timeSlots={timeSlots}
              timeSlotRef={timeSlotRef}
              focusingTime={focusingTime}
            />
          </Grid>
        </Stack>
      ))}
    </>
  );
};
const TimeSlotSectionListMemoized = React.memo(TimeSlotSectionList);

const DateTimeReservation = React.forwardRef(function DateTimeReservation(
  props: Props,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const {
    dateReservation = null,
    timeReservation = '',
    selectDateTimeReservation,
    error,
    disabled = false,
    selectedTicket,
    dateRersvEdited,
    timeRersvEdited,
  } = props;
  const { t } = useTranslation();
  const { formatDate } = useI18nTimeUtils();
  const [topNavDate] = useTopNavDate();
  const initSelectedDate = React.useMemo(() => {
    return dateReservation
      ? dateReservation
      : dateRersvEdited
      ? dateRersvEdited
      : isPast(topNavDate)
      ? new Date()
      : topNavDate;
  }, [dateRersvEdited, dateReservation, topNavDate]);

  const initSelectedTime = React.useMemo(() => {
    const time = timeReservation || timeRersvEdited;
    return time
      ? `${format(initSelectedDate || new Date(), 'yyyy-MM-dd')} ${time}`
      : null;
  }, [initSelectedDate, timeRersvEdited, timeReservation]);

  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    initSelectedDate
  );
  const [selectedTime, setSelectedTime] = React.useState<string | null>(
    initSelectedTime
  );
  const [selectedDateQueryKey, setSelectedDateQueryKey] = React.useState(
    getDateString(selectedDate)
  );

  const theme = useTheme();
  const [openModal, setOpenModal] = React.useState(false);
  const { isMobileView } = usePwaOrMobileView();

  const focusingTime = React.useMemo(() => {
    return `${format(initSelectedDate || new Date(), 'yyyy-MM-dd')} ${
      selectedTime || getRoundingCurrentTime()
    }`;
  }, [initSelectedDate, selectedTime]);

  const timeSlotRef = React.useRef<HTMLButtonElement | null>(null);
  const scrollRef = React.useRef<HTMLDivElement | null>(null);
  const sectionsRef = React.useRef<Record<string, HTMLDivElement>>({});

  const { data: merchantReservation } = useMerchantReservation();
  const [typeShowTimeslots, setTypeShowTimeslots] = React.useState<number>(
    merchantReservation?.outlet.typeShowTimeslots || 2
  );

  const handleTypeShowTimeslotsChange = (newType: number) => {
    setTypeShowTimeslots(newType);
    resetQuery();
  };

  const {
    getTimeSlotsAvailableQuery: {
      data: timeSlotPages,
      isLoading: isLoadingTimeSlot,
      fetchNextPage,
      fetchPreviousPage,
      isFetchingNextPage: isLoadingNextTimeSlot,
      isFetchingPreviousPage: isLoadingPreviousTimeSlot,
      isFetched: isFetchedTimeSlot,
      hasPreviousPage,
    },
    resetQuery,
  } = useGetAvailableTimeSlotInfiniteByDate({
    date: selectedDateQueryKey,
    enabled: !!selectedDate,
    isShowTimeslotST: typeShowTimeslots === 1,
  });

  const warningContent = React.useMemo(() => {
    if (!timeSlotPages?.pages || !selectedTime) {
      return [];
    }

    const timeSlots = timeSlotPages.pages.find((p) =>
      isSameDay(parseDate(p.date), parseDate(selectedTime))
    );
    if (!timeSlots) {
      return [];
    }

    const warningArray: string[] = [];
    const timeStr = format(
      parseDate(selectedTime, 'yyyy-MM-dd HH:mm:ss'),
      'HH:mm:ss'
    );

    const newValueConverted = timeSlots.timeSlots.find(
      (timeSlot) => timeSlot.time === timeStr
    );
    if (!newValueConverted) return warningArray;

    if (newValueConverted.statusTimeSlot === 'DEFAULT')
      warningArray.push(t('Selected timeslot is not bookable online.'));
    else if (newValueConverted.statusTimeSlot === 'BLOCKED_SERVICE_TIMING')
      warningArray.push(
        t('Selected timeslot is blocked out from online booking.')
      );

    if (newValueConverted.totalOfTableBooked >= newValueConverted.totalOfTable)
      warningArray.push(
        t(
          'Selected timeslot has {{numberTable}}/{{numberTable}} tables occupied.',
          {
            numberTable: newValueConverted.totalOfTable,
          }
        )
      );

    return warningArray;
  }, [selectedTime, t, timeSlotPages?.pages]);

  const ticketServices = React.useMemo(() => {
    return selectedTicket && isOnlineTicket(selectedTicket)
      ? selectedTicket.serviceTimings?.reduce((acc, val) => {
          const isRecurring = val.isRecurring;
          const isFuture =
            (val.specificDate &&
              !isPast(parse(val.specificDate, 'yyyy-MM-dd', new Date()))) ||
            val.specificDateArray?.some(
              (d) => !isPast(parse(d, 'yyyy-MM-dd', new Date()))
            );
          if (isRecurring || isFuture) {
            acc.add(val.id);
          }
          return acc;
        }, new Set<string>())
      : null;
  }, [selectedTicket]);

  const selectTimeSlot = React.useCallback((date: Date, newValue: string) => {
    setSelectedDate(date);
    setSelectedTime(newValue);
  }, []);

  const handleCloseModal = () => {
    setSelectedDate(initSelectedDate);
    setSelectedTime(initSelectedTime);
    setOpenModal(false);
  };

  const handleConfirmModal = () => {
    if (selectedTime && selectDateTimeReservation) {
      const dateTime = parse(selectedTime, 'yyyy-MM-dd HH:mm:ss', new Date());
      const date = format(dateTime, 'yyyy-MM-dd');
      const time = format(dateTime, 'HH:mm:ss');
      const page = timeSlotPages?.pages.find((p) => p.date === date);
      if (page) {
        const timeSlot = page.timeSlots.find((slot) => slot.time === time);
        if (timeSlot) {
          selectDateTimeReservation(date, timeSlot);
        }
      }
    }
    if (openModal) setOpenModal(false);
  };

  const inputValue = React.useMemo(() => {
    if (dateReservation && timeReservation) {
      const dateValue = format(dateReservation, 'yyyy-MM-dd');
      const dateTimeConverted = formatDate(
        parse(
          `${dateValue} ${timeReservation}`,
          'yyyy-MM-dd HH:mm:ss',
          new Date()
        ),
        {
          en: 'd LLL yyyy, h:mm a',
          zh: 'yyyy LLL do, h:mm a',
        }
      );
      return (
        (isToday(dateReservation) ? `${t('Today')}, ` : '') + dateTimeConverted
      );
    }
    return '';
  }, [dateReservation, timeReservation, formatDate, t]);

  const handleDatePickerSelect = React.useCallback(
    (date: Date) => {
      const dateStr = getDateString(date);
      if (selectedDateQueryKey === dateStr) {
        resetQuery();
      } else {
        setSelectedDateQueryKey(dateStr);
      }
      setSelectedDate(date);
    },
    [resetQuery, selectedDateQueryKey]
  );

  const handleScroll = (scrollTop: number) => {
    const scrollElm = scrollRef.current;
    if (!scrollElm || isLoadingTimeSlot || !isFetchedTimeSlot) {
      return;
    }
    const containerTop =
      (scrollRef.current?.offsetTop || 0) +
      (Number(scrollRef.current?.style.paddingLeft) || 0);
    const currentScrollingDate = findLast<string>(
      Object.keys(sectionsRef.current).sort(),
      (date) => {
        const elm = sectionsRef.current[date];
        if (!elm || !document.body.contains(elm)) {
          return false;
        }
        const distance = elm.offsetTop - containerTop;
        return distance <= scrollTop + 300;
      }
    );
    setSelectedDate((prev) =>
      currentScrollingDate && getDateString(prev) !== currentScrollingDate
        ? parse(currentScrollingDate, 'yyyy-MM-dd', new Date())
        : prev
    );
    // scroll to bottom
    if (
      scrollElm.scrollHeight - scrollElm.scrollTop ===
      scrollElm.clientHeight
    ) {
      if (!isLoadingNextTimeSlot) {
        fetchNextPage();
      }
    }

    // scroll to top
    if (scrollElm.scrollTop === 0) {
      if (!isLoadingPreviousTimeSlot && hasPreviousPage && openModal) {
        fetchPreviousPage();
      }
      scrollElm.scrollTop = 1;
    }
  };

  React.useEffect(() => {
    if (initSelectedDate && initSelectedTime) {
      setSelectedDate(initSelectedDate);
      setSelectedTime(initSelectedTime);
    }
  }, [initSelectedDate, initSelectedTime]);

  React.useEffect(() => {
    if (isFetchedTimeSlot && openModal) {
      setTimeout(() => {
        if (timeSlotRef.current) {
          timeSlotRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        } else {
          const scrollElm = scrollRef.current;
          if (scrollElm) {
            if (
              scrollElm.scrollHeight - scrollElm.scrollTop ===
              scrollElm.clientHeight
            ) {
              fetchNextPage();
            } else {
              scrollElm.scrollTop = 1;
            }
          }
        }
      }, 0);
    }
  }, [fetchNextPage, isFetchedTimeSlot, openModal]);

  return (
    <>
      <TextField
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <EventIcon color="action" sx={{ fontSize: 24 }} />
            </InputAdornment>
          ),
          readOnly: true,
        }}
        value={inputValue}
        error={!!error}
        disabled={disabled}
        placeholder={t('Select date and time')}
        sx={{
          '.MuiInputBase-input::placeholder': {
            color: `${
              error ? theme.palette.error.main : theme.palette.grey['500']
            }`,
          },
        }}
        onClick={() => {
          if (disabled) return;
          setOpenModal(true);
          // refetch();
        }}
        inputRef={ref}
      />

      {!!error && <FormHelperText error>{error}</FormHelperText>}
      {dateReservation &&
        timeReservation &&
        warningContent?.map((text, index) => (
          <FormHelperText
            sx={{
              color: theme.palette.warning.dark,
              display: 'flex',
              alignItems: 'center',
            }}
            key={index}
          >
            <WarningAmberIcon
              sx={{
                fontSize: '16px',
                marginRight: '4px',
                color: theme.palette.warning.main,
              }}
            />{' '}
            {text}
          </FormHelperText>
        ))}

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        sx={{
          zIndex: 1501,
        }}
      >
        <Box
          sx={{
            width: '100%',
            height: '100%',
            position: 'relative',
            overflow: 'auto',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              borderRadius: '16px',
              boxShadow: 24,
              width: '90%',
              height: 'calc(100% - 48px)',
              maxWidth: '1200px',
              maxHeight: '720px',
            }}
          >
            <Typography
              id="server-modal-title"
              variant="h6"
              component="h2"
              sx={{
                display: 'flex',
                alignItems: 'center',
                height: '72px',
                justifyContent: 'space-between',
                padding: '22px 12px 22px 24px',
              }}
            >
              <Trans>Select Date and Time</Trans>
              <Box>
                <IconButton onClick={handleCloseModal}>
                  <CloseIcon sx={{ fontSize: 24 }} />
                </IconButton>
              </Box>
            </Typography>
            <Box
              id="server-modal-description"
              sx={{ height: 'calc(100% - 144px)' }}
            >
              <Box
                className={styles.modalDateTime}
                sx={{
                  display: 'flex',
                  flexDirection: isMobileView ? 'column' : 'row',
                  overflowY: isMobileView ? 'auto' : 'none',
                }}
              >
                <ServiceDatePicker
                  key={selectedDate ? format(selectedDate, 'yyyy-MM') : ''}
                  value={selectedDate}
                  onChange={handleDatePickerSelect}
                  customFullyBlockedDayLabel={t('Fully blocked schedules')}
                  sx={{
                    minWidth: '260px',
                    maxWidth: isMobileView ? '100%' : '600px',
                    borderRadius: 'unset',
                    height: 'fit-content',
                    overflowY: isMobileView ? 'none' : 'auto',
                  }}
                />
                <Divider orientation="vertical" flexItem />
                <Stack
                  gap={3}
                  sx={{
                    minWidth: `calc(${theme.breakpoints.values.md}px - 48px - 406px)`,
                    width: '100%',
                    padding: '16px',
                    pt: '0',
                    overflowY: isMobileView ? 'none' : 'auto',
                  }}
                  ref={scrollRef}
                  onScroll={(evt) => {
                    handleScroll((evt.target as HTMLDivElement).scrollTop);
                  }}
                >
                  {isLoadingPreviousTimeSlot && <LoadingSkeleton />}
                  {isLoadingTimeSlot ? (
                    <LoadingSkeleton />
                  ) : !timeSlotPages?.pages ? null : (
                    <>
                      {/* Set this selection float to right */}
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          backgroundColor: 'white',
                          position: 'sticky',
                          top: 0,
                          pb: 2,
                          zIndex: 100,
                        }}
                      >
                        <Box></Box> {/* Keep this empty box for alignment */}
                        <ShowTimeslotsTypeSelection
                          typeShowTimeslots={typeShowTimeslots}
                          onTypeChange={handleTypeShowTimeslotsChange}
                        />
                      </Box>
                      <TimeSlotSectionListMemoized
                        sections={timeSlotPages.pages}
                        selectTimeSlot={selectTimeSlot}
                        selectedTime={selectedTime}
                        focusingTime={focusingTime}
                        ticketServices={ticketServices}
                        timeSlotRef={timeSlotRef}
                        sectionsRef={sectionsRef}
                      />
                    </>
                  )}
                  {isLoadingNextTimeSlot && <LoadingSkeleton />}
                </Stack>
              </Box>
            </Box>
            <Typography
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                height: '72px',
                padding: '0 16px',
                boxShadow: '0px 0px 16px rgb(145 158 171 / 24%)',
              }}
            >
              <Typography
                sx={{
                  color: theme.palette.warning.dark,
                }}
                variant="caption"
              >
                {warningContent?.map((text, index) => (
                  <div key={index}>
                    {text}
                    <br></br>
                  </div>
                ))}
              </Typography>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  size="large"
                  variant="text"
                  color="inherit"
                  onClick={handleCloseModal}
                >
                  <Trans>Cancel</Trans>
                </Button>

                <Button
                  size="large"
                  sx={{
                    marginLeft: '12px',
                    maxWidth: '80%',
                    fontSize: 'clamp(12px, 3.5vw, 15px)',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  onClick={handleConfirmModal}
                  disabled={!selectedTime || isLoadingTimeSlot}
                >
                  {selectedTime ? (
                    <Trans sx={{ fontSize: 'inherit' }}>
                      Select{' '}
                      {{
                        selectedDate: formatDate(
                          parseDate(selectedTime, 'yyyy-MM-dd HH:mm:ss'),
                          {
                            en: 'dd MMM yyyy',
                            zh: 'yyyy MMM do',
                          }
                        ),
                      }}
                      {', '}
                      {{
                        selectedTime: formatDate(
                          parseDate(selectedTime, 'yyyy-MM-dd HH:mm:ss'),
                          {
                            en: 'hh:mm a',
                          }
                        ),
                      }}
                    </Trans>
                  ) : (
                    <Trans>Select Date and Time</Trans>
                  )}
                </Button>
              </Box>
            </Typography>
          </Box>
        </Box>
      </Modal>
    </>
  );
});

export default React.memo(DateTimeReservation);

import { useEffect, useMemo, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  useTheme,
  InputAdornment,
  Stack,
  IconButton,
  Button,
  Chip,
  FormControl,
  styled,
} from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import AddIcon from '@mui/icons-material/Add';
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import {
  useAddCustomerCDPTag,
  useDeleteCustomerTag,
  useGetAllCustomerTags,
  useDeleteCustomerCDPTag,
} from '@/common/hooks/use-customers';
import { useSnackbar } from '@/common/components/snack-bar';
import {
  useAddReservationTag,
  useDeleteReservationTag,
  useGetReservationSegmentTag,
  useReservationTags,
} from '@/common/hooks/use-reservations';
import { useAtom, useSetAtom } from 'jotai';
import {
  TagItem,
  createTagsModalAtom,
  reservationTagsAtom,
} from '@/feat/reservation/state';

const Form = styled('form')(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  gap: 24,
}));

const validationSchema = z.object({
  tag: z.string(),
});

type FormInputs = z.infer<typeof validationSchema>;

const isContains = (str1: string, str2: string) => {
  return String(str1)
    .toLowerCase()
    .trim()
    .includes(String(str2).toLowerCase().trim());
};

const isEqual = (str1: string, str2: string) => {
  return (
    String(str1).toLowerCase().trim() === String(str2).toLowerCase().trim()
  );
};

const dialogTitle: Record<string, React.ReactNode> = {
  reservation: <Trans>Reservation Tags</Trans>,
  customer: <Trans>Customer Tags</Trans>,
};

interface ActionTagModalProps {
  open: boolean;
  type: string;
  customerMcaId: string;
  customerId: string;
  refetchReservation?: () => void;
}

export default function ActionTagModal({
  open,
  customerMcaId,
  customerId,
  type,
}: ActionTagModalProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [selectedRersvTags, setSelectedRersvTags] =
    useAtom(reservationTagsAtom);
  const [tagsPendingDelete, setTagsPendingDelete] = useState<TagItem[]>([]);
  const setCreateTagsModal = useSetAtom(createTagsModalAtom);
  const [cachingSelectedTags, setCachingSelectedTags] = useState<TagItem[]>([]);

  const { mutateAsync: addCustomerCDPTag } = useAddCustomerCDPTag();
  const { mutateAsync: deleteCustomerCDPTag } = useDeleteCustomerCDPTag();
  const { mutateAsync: addReservationTag } = useAddReservationTag();
  const { mutateAsync: deleteCustomerTag } = useDeleteCustomerTag();
  const { mutateAsync: deleteReservationTag } = useDeleteReservationTag();

  const snackbar = useSnackbar();

  const { flatData: dataCustomerCDP, refetch } = useGetReservationSegmentTag({
    customerIds: [customerId],
  });

  const { data: reservationTags, refetch: refetchReservationTags } =
    useReservationTags({
      params: { searchText: '' },
    });
  const { data: dataListCustomerTags, refetch: refetchCustomerTags } =
    useGetAllCustomerTags();

  const { handleSubmit, register, watch, setValue, reset } =
    useForm<FormInputs>({
      defaultValues: { tag: '' },
    });
  const tagValue = watch('tag');

  const listTags = useMemo(() => {
    if (type === 'customer') {
      return (dataListCustomerTags || []).map((tag) => ({
        id: tag.id ?? '',
        title: tag.title ?? '',
      }));
    } else {
      const listData = reservationTags?.map((it) => ({
        id: it.id,
        title: it.title,
      }));
      return listData
        ?.filter((it) => isContains(it.title, tagValue))
        .filter(Boolean);
    }
  }, [dataListCustomerTags, reservationTags, tagValue, type]);

  const canAddNewTag = useMemo(() => {
    if (!tagValue) return false;
    return !listTags?.some((it) => isEqual(it.title, tagValue));
  }, [tagValue, listTags]);

  const customerTags = useMemo(
    () => dataCustomerCDP?.[0]?.tags ?? [],
    [dataCustomerCDP]
  );
  useEffect(() => {
    if (type === 'customer') {
      setCachingSelectedTags(customerTags.map((title) => ({ id: '', title })));
    } else {
      setCachingSelectedTags(selectedRersvTags);
    }
  }, [customerTags, selectedRersvTags, type]);

  const handleSelectTags = async (tag: TagItem) => {
    if (type === 'customer') {
      const isSelected = cachingSelectedTags.some((t) => t.title === tag.title);
      setCachingSelectedTags((prev) =>
        isSelected ? prev.filter((t) => t.title !== tag.title) : [...prev, tag]
      );

      setTagsPendingDelete((prev) => prev.filter((t) => t.id !== tag.id));

      try {
        if (isSelected) {
          await deleteCustomerCDPTag({
            mcaId: customerMcaId || '',
            tags: [tag.title],
          });
        } else {
          await addCustomerCDPTag({
            mcaId: customerMcaId,
            tags: [tag.title],
          });
        }
        refetch();
      } catch (error) {
        setCachingSelectedTags((prev) =>
          isSelected
            ? [...prev, tag]
            : prev.filter((t) => t.title !== tag.title)
        );
        snackbar.showMessage(t('Failed to update tag. Please try again.'));
      }
    } else {
      if (selectedRersvTags.some((selectedTag) => selectedTag.id === tag.id)) {
        setSelectedRersvTags(
          selectedRersvTags.filter((selectedTag) => selectedTag.id !== tag.id)
        );
      } else {
        setSelectedRersvTags([...selectedRersvTags, tag]);
      }
      if (tagsPendingDelete.some((pendingTag) => pendingTag.id === tag.id)) {
        setTagsPendingDelete(
          tagsPendingDelete.filter((pendingTag) => pendingTag.id !== tag.id)
        );
      }
    }
  };

  const handleDeleteTag = (tag: TagItem) => {
    const isPendingDelete = tagsPendingDelete.some(
      (pendingTag) => pendingTag.id === tag.id
    );

    if (isPendingDelete) {
      if (type === 'customer') {
        deleteCustomerTag({
          tagId: tag.id,
        }).then(() => {
          refetchCustomerTags();
        });
      } else {
        deleteReservationTag({
          tagId: tag.id,
        }).then(() => {
          refetchReservationTags();
        });
      }

      setCachingSelectedTags((prevTags) =>
        prevTags.filter((selectedTag) => selectedTag.id !== tag.id)
      );
      if (type !== 'customer') {
        setSelectedRersvTags((prevTags) =>
          prevTags.filter((selectedTag) => selectedTag.id !== tag.id)
        );
      }
      setTagsPendingDelete([]);
      snackbar.showMessage(t('Tag deleted successfully'));
    } else {
      setTagsPendingDelete([tag]);
      setCachingSelectedTags((prevTags) =>
        prevTags.filter((t) => t.id !== tag.id)
      );

      if (type !== 'customer') {
        setSelectedRersvTags((prevTags) =>
          prevTags.filter((t) => t.id !== tag.id)
        );
      }
    }
  };

  const handleCloseDialog = () => {
    reset();
    setTagsPendingDelete([]);
    setCreateTagsModal({
      isOpen: false,
      type: 'reservation',
      customerMcaId: '',
      customerId: '',
    });
  };

  const onAddTag = async (data: FormInputs) => {
    if (type === 'customer') {
      if (!customerMcaId || !data.tag) return;
      await addCustomerCDPTag({
        mcaId: customerMcaId,
        tags: [data.tag],
      }).then(async () => {
        await deleteCustomerCDPTag({
          mcaId: customerMcaId || '',
          tags: [data.tag],
        });
      });

      snackbar.showMessage(t('Customer tag added successfully'));
      refetchCustomerTags();
    } else if (type === 'reservation') {
      await addReservationTag({
        tag: data.tag,
      });

      snackbar.showMessage(t('Reservation tag added successfully'));
      setValue('tag', '');
      refetchReservationTags();
    }
  };

  return (
    <Form onSubmit={handleSubmit(onAddTag)} id="tags">
      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle
          sx={{
            pl: '28px !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography variant="h6">{dialogTitle?.[type] || ''}</Typography>
          <IconButton onClick={handleCloseDialog}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ mt: 0 }}>
          <FormControl
            sx={[
              {
                width: '100%',
              },
              canAddNewTag
                ? {
                    boxShadow: theme.customShadows.dropdown,
                    p: 1,
                    borderRadius: 1.5,
                  }
                : null,
            ]}
          >
            <TextField
              {...register('tag')}
              size="small"
              autoComplete="off"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: !!tagValue ? (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => {
                        setValue('tag', '');
                      }}
                    >
                      <ClearTwoToneIcon />
                    </IconButton>
                  </InputAdornment>
                ) : null,
              }}
              placeholder={t('Search')}
              fullWidth
              sx={{
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: `${theme.palette.grey[500_24]} !important`,
                  borderWidth: '1px !important',
                },
              }}
            />

            {canAddNewTag ? (
              <Button
                size="small"
                variant="text"
                form="tags"
                type="submit"
                startIcon={<AddIcon />}
                sx={{ mt: 2 }}
              >
                <Trans>Create a new tag “{{ searchVal: tagValue }}”</Trans>
              </Button>
            ) : null}
          </FormControl>

          <Stack direction="row" flexWrap="wrap" mt={3} gap={1}>
            {!!listTags?.length
              ? listTags?.map((tag, idx) => {
                  const isSelectedTag =
                    type === 'customer'
                      ? cachingSelectedTags.some((t) => t.title === tag.title)
                      : cachingSelectedTags.some((t) => t.id === tag.id);
                  const isPendingDelete = tagsPendingDelete.some(
                    (pendingTag) => pendingTag.id === tag.id
                  );

                  return (
                    <Chip
                      key={`${tag.id}+${idx}`}
                      label={tag.title}
                      size="small"
                      onDelete={(e) => {
                        e.stopPropagation();
                        handleDeleteTag(tag);
                      }}
                      deleteIcon={<DeleteIcon />}
                      onClick={() => handleSelectTags(tag)}
                      sx={[
                        {
                          borderRadius: theme.spacing(0.75),
                          background: isPendingDelete
                            ? theme.palette.error.main
                            : isSelectedTag
                            ? theme.palette.primary.main_16
                            : theme.palette.grey[500_16],
                          padding: theme.spacing(0.5),
                          width: 'fit-content',
                          '&:hover': {
                            background: isPendingDelete
                              ? theme.palette.error.main
                              : isSelectedTag
                              ? theme.palette.primary.main_16
                              : theme.palette.grey[500_16],
                          },
                          '& .MuiChip-label': {
                            fontWeight: 400,
                            color: isPendingDelete
                              ? theme.palette.common.white
                              : isSelectedTag
                              ? theme.palette.primary.main
                              : theme.palette.grey[700],
                          },
                          '& .MuiSvgIcon-root': {
                            fontSize: 18,
                            color: isPendingDelete
                              ? '#FFFFFF90'
                              : isSelectedTag
                              ? theme.palette.primary.main_56
                              : undefined,
                          },
                        },
                      ]}
                    />
                  );
                })
              : null}
          </Stack>
        </DialogContent>
      </Dialog>
    </Form>
  );
}

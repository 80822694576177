export default function FirstTimeVisitorSvg(
  props: React.SVGProps<SVGSVGElement>
) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.3334 7.33333C13.1445 7.33333 12.9862 7.26944 12.8584 7.14167C12.7306 7.01389 12.6667 6.85556 12.6667 6.66667C12.6667 6.47778 12.7306 6.31944 12.8584 6.19167C12.9862 6.06389 13.1445 6 13.3334 6C13.5223 6 13.6806 6.06389 13.8084 6.19167C13.9362 6.31944 14.0001 6.47778 14.0001 6.66667C14.0001 6.85556 13.9362 7.01389 13.8084 7.14167C13.6806 7.26944 13.5223 7.33333 13.3334 7.33333ZM12.6667 5.33333V2H14.0001V5.33333H12.6667ZM6.00008 8C5.26675 8 4.63897 7.73889 4.11675 7.21667C3.59453 6.69444 3.33341 6.06667 3.33341 5.33333C3.33341 4.6 3.59453 3.97222 4.11675 3.45C4.63897 2.92778 5.26675 2.66667 6.00008 2.66667C6.73341 2.66667 7.36119 2.92778 7.88341 3.45C8.40564 3.97222 8.66675 4.6 8.66675 5.33333C8.66675 6.06667 8.40564 6.69444 7.88341 7.21667C7.36119 7.73889 6.73341 8 6.00008 8ZM0.666748 13.3333V11.4667C0.666748 11.0889 0.76397 10.7417 0.958415 10.425C1.15286 10.1083 1.41119 9.86667 1.73341 9.7C2.4223 9.35556 3.1223 9.09722 3.83341 8.925C4.54453 8.75278 5.26675 8.66667 6.00008 8.66667C6.73341 8.66667 7.45564 8.75278 8.16675 8.925C8.87786 9.09722 9.57786 9.35556 10.2667 9.7C10.589 9.86667 10.8473 10.1083 11.0417 10.425C11.2362 10.7417 11.3334 11.0889 11.3334 11.4667V13.3333H0.666748ZM2.00008 12H10.0001V11.4667C10.0001 11.3444 9.96953 11.2333 9.90841 11.1333C9.8473 11.0333 9.76675 10.9556 9.66675 10.9C9.06675 10.6 8.46119 10.375 7.85008 10.225C7.23897 10.075 6.6223 10 6.00008 10C5.37786 10 4.76119 10.075 4.15008 10.225C3.53897 10.375 2.93341 10.6 2.33341 10.9C2.23341 10.9556 2.15286 11.0333 2.09175 11.1333C2.03064 11.2333 2.00008 11.3444 2.00008 11.4667V12ZM6.00008 6.66667C6.36675 6.66667 6.68064 6.53611 6.94175 6.275C7.20286 6.01389 7.33341 5.7 7.33341 5.33333C7.33341 4.96667 7.20286 4.65278 6.94175 4.39167C6.68064 4.13056 6.36675 4 6.00008 4C5.63341 4 5.31953 4.13056 5.05841 4.39167C4.7973 4.65278 4.66675 4.96667 4.66675 5.33333C4.66675 5.7 4.7973 6.01389 5.05841 6.275C5.31953 6.53611 5.63341 6.66667 6.00008 6.66667Z"
        fill="#637381"
      />
    </svg>
  );
}

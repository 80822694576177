import useCallbackRef from './use-callback-ref';
import useLocaleCfg from './use-locale-cfg';

export const useMoneyFmt = () => {
  const locale = useLocaleCfg();

  /**
   * Format currency to {currency}{amount} (S$1,000) or N/A
   * @param amount
   * @param locale
   */
  const moneyFmt = useCallbackRef(
    (amount: number | string | null | undefined) => {
      const fmt = new Intl.NumberFormat(locale['BCP 47'], {
        style: 'decimal',
        maximumFractionDigits: 2,
      });
      const format = (num: number) => locale.cPrefix + fmt.format(num);

      if (amount === null || amount === undefined) return 'N/A';
      if (typeof amount === 'string') {
        if (!amount.length) return 'N/A';

        return format(Number(amount));
      }

      return format(amount);
    }
  );

  return { moneyFmt };
};

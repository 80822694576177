import { Box, CardProps, Typography, Divider, Checkbox } from '@mui/material';
import React from 'react';

import { ActionReservationType } from '../../context';
import { Trans, useTranslation } from 'next-i18next';
import { TextField } from '@mui/material';
import { PaymentRequestEditProps } from './type';
import { InputAdornment } from '@mui/material';
import { FormControlLabel } from '@mui/material';
import Button from '@mui/material/Button';
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyTwoTone';
import PaymentAlert from '@/common/components/payment-aleft';
import CustomSelectWithTooltip from './custom-select-time-expire';
import { useAtom } from 'jotai';
import { isErrorTimeExpireAtom } from '../../state';
import { calculateTimeDifference } from '../../utils';

export type PaymentRequestPaxProps = {
  actionType: ActionReservationType;
  editedReservationPaymentRequest: PaymentRequestEditProps;
  defaultSMSNotify: boolean;
  defaultEmailNotify: boolean;
};

export default function PaymentRequestOnlineDetail({
  actionType,
  editedReservationPaymentRequest,
  defaultSMSNotify,
  defaultEmailNotify,
}: PaymentRequestPaxProps & CardProps) {
  const { t } = useTranslation();

  const expireDateTime = editedReservationPaymentRequest?.expireDateTime || '';
  const [isErrorTimeExpire] = useAtom(isErrorTimeExpireAtom);

  const { hoursDifference, minutesDifference } =
    calculateTimeDifference(expireDateTime);

  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(
        editedReservationPaymentRequest?.link || ''
      );
      alert('URL has been copied to clipboard');
    } catch (error) {
      console.error('Failed to copy URL: ', error);
    }
  };

  return (
    <Box>
      <Divider sx={{ marginBottom: '24px' }} />
      <Typography
        sx={{
          color: '#919EAB',
          fontSize: '12px',
          fontWeight: '700',
        }}
      >
        <Trans>PAYMENT LINK SETTINGS</Trans>
      </Typography>

      <Box sx={{ padding: '12px 0' }}>
        <Typography
          sx={{
            color: '#212B36',
            fontSize: '14px',
            fontWeight: '600',
          }}
        >
          <Trans>Payment Link</Trans>
        </Typography>
      </Box>
      {actionType === 'Edit' ? (
        <Box sx={{ width: '100%', paddingTop: '12px' }}>
          <TextField
            id="outlined-basic"
            label={editedReservationPaymentRequest?.link}
            disabled={true}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button variant="contained" onClick={handleCopyUrl}>
                    <FileCopyTwoToneIcon
                      sx={{
                        color: '#FFF !important',
                      }}
                    />
                    <Box sx={{ paddingLeft: '8px' }}>
                      <Trans>Copy URL</Trans>
                    </Box>
                  </Button>
                </InputAdornment>
              ),
            }}
            InputLabelProps={{
              style: {
                color: '#212B36',
              },
            }}
            sx={{
              width: '100%',
              '& .MuiFormLabel-root': {
                width: '50%',
              },
            }}
          />
        </Box>
      ) : (
        <PaymentAlert
          title={''}
          mode={isErrorTimeExpire ? 'error' : 'secondary'}
          description={
            isErrorTimeExpire
              ? t(
                  'Reservation timing is too close to the minimum validity period (30 minutes before). Please adjust the reservation time or choose manual payment.'
                )
              : t('Link appears after saving reservation')
          }
        />
      )}

      <Box sx={{ padding: '24px 0 12px 0' }}>
        <Typography
          sx={{
            color: '#212B36',
            fontSize: '14px',
            fontWeight: '600',
          }}
        >
          <Trans>Link Validity Period</Trans>
        </Typography>
      </Box>
      {actionType === 'Edit' ? (
        <>
          <Box sx={{}}>
            <Typography
              sx={{
                color: '#637381',
                fontSize: '14px',
                fontWeight: '500',
              }}
            >
              {t(
                ` Expire in {{hoursDifference}} hours {{minutesDifference}} minutes`,
                {
                  hoursDifference,
                  minutesDifference,
                }
              )}
            </Typography>
          </Box>

          <Box sx={{ paddingTop: '24px' }}>
            <Typography
              sx={{
                color: '#212B36',
                fontSize: '14px',
                fontWeight: '600',
              }}
            >
              <Trans>Send Payment Request Link via</Trans>
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '10px',
                paddingLeft: '10px',
              }}
            >
              <FormControlLabel
                control={<Checkbox />}
                label={<Trans>SMS (Local Charges Apply)</Trans>}
                disabled={true}
                checked={defaultSMSNotify}
              />

              <FormControlLabel
                control={<Checkbox />}
                label={<Trans>Email</Trans>}
                disabled={true}
                checked={defaultEmailNotify}
              />
            </Box>
          </Box>
        </>
      ) : (
        <CustomSelectWithTooltip />
      )}
    </Box>
  );
}

import {
  SxProps,
  Theme,
  useTheme,
  alpha,
  Button,
  Chip,
  Stack,
} from '@mui/material';
import { Trans } from 'next-i18next';
import AddIcon from '@mui/icons-material/Add';
import { useDeleteCustomerCDPTag } from '@/common/hooks/use-customers';

interface Props {
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
  tags: string[];
  customerMcaId: string;
  onOpenModalAddTag?: () => void;
  onDeleted?: () => void;
}

export default function CustomerTags({
  tags,
  onOpenModalAddTag,
  customerMcaId,
  onDeleted,
  sx,
}: Props) {
  const theme = useTheme();
  const { mutateAsync: deleteCustomerCDPTag } = useDeleteCustomerCDPTag();

  const handleDeleteTag = (tag: string) => {
    deleteCustomerCDPTag({
      mcaId: customerMcaId || '',
      tags: [tag],
    }).then(() => {
      onDeleted?.();
    });
  };

  return (
    <Stack direction="row" gap={0.5} mb={0.5} sx={{ ...sx }}>
      {!!tags?.length
        ? tags.map((tag, idx) => (
            <Chip
              key={tag + idx}
              label={tag}
              size="small"
              onDelete={() => handleDeleteTag(tag)}
              sx={[
                {
                  borderRadius: theme.spacing(0.75),
                  background: theme.palette.grey[500_16],
                  padding: theme.spacing(0.5),
                  width: 'fit-content',
                  '&:hover': {
                    background: theme.palette.grey[500_16],
                  },
                  '& .MuiChip-label': {
                    fontWeight: 400,
                    color: theme.palette.grey[700],
                  },
                  '& .MuiSvgIcon-root': {
                    fontSize: 18,
                  },
                },
              ]}
            />
          ))
        : null}

      <Button
        size="small"
        variant="text"
        startIcon={<AddIcon />}
        onClick={onOpenModalAddTag}
        sx={{
          padding: theme.spacing(0, 1),
          color: theme.palette.grey[600],
          '&:hover': {
            backgroundColor: alpha(theme.palette.grey[600], 0.08),
          },
        }}
      >
        <Trans>Add Tag</Trans>
      </Button>
    </Stack>
  );
}

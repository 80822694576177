import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
} from '@tanstack/react-query';
import { format } from 'date-fns';
import { z } from 'zod';
import { qClient } from '../lib/react-query';
import {
  API_RSRV_STATUS_MAP,
  ReservationStatus,
  RESERVATION_STATUS_MAP,
} from '../lib/reservation-status-flow';
import { ZTransaction, ZTransactionHistory } from '../types/transaction';
import useCallbackRef from './use-callback-ref';
import useDebounce from './use-debounce';
import useV2Api from './use-v2-api';

// export type GetTransactionsParams = {
//   searchText?: string | null;
//   status?: ApiRsrvStatusInt | null;
//   startDate?: string | null;
//   endDate?: string | null;
//   ticketId?: string | null;
//   page?: number | null;
//   limit?: number | null;
// };

interface IDownloadFileParam {
  fileName: string;
  fileExtension: string;
  data: Blob;
}
const ZTransactionMeta = z.object({
  totalItems: z.number(),
  itemCount: z.number(),
  itemsPerPage: z.number(),
  totalPages: z.number(),
  currentPage: z.number(),
});

function handleDownloadFile(params: IDownloadFileParam): void {
  const newBlob = new Blob([params.data]);
  const blobUrl = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');

  link.href = blobUrl;
  link.setAttribute('download', `${params.fileName}.${params.fileExtension}`);
  document.body.appendChild(link);
  link.click();
  link?.parentNode?.removeChild(link);
  window.URL.revokeObjectURL('/');
}

export const ZTransactionResponse = z.object({
  items: z.array(ZTransaction),
  meta: ZTransactionMeta,
});
export type TransactionResponse = z.infer<typeof ZTransactionResponse>;

export function useExportData() {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args()
      .implement(async (listIds) => {
        const data = await v2Api.post(
          'reservations/export',
          {
            listIds: listIds,
          },
          { responseType: 'blob' }
        );
        const downloadInfo = {
          fileName: 'export',
          fileExtension: 'csv',
          data: data.data,
        };

        handleDownloadFile(downloadInfo);
      })
  );
}
export function useTransactions({
  page,
  perPage,
  startDate,
  endDate,
  searchText,
  rStatus,
  ticketId,
  keepPreviousData,
}: {
  page?: number | null;
  perPage?: number | null;
  startDate?: Date | null;
  endDate?: Date | null;
  searchText?: string | null;
  rStatus?: ReservationStatus | null;
  ticketId?: string | null;
  keepPreviousData?: boolean;
}) {
  const v2Api = useV2Api();
  const debouncedSearchText = useDebounce(searchText);

  const queryKeys = [
    page,
    perPage,
    startDate,
    endDate,
    debouncedSearchText,
    rStatus,
    ticketId,
  ];

  const optimisticUpdate = useCallbackRef(
    (updater: <T extends TransactionResponse | undefined>(x: T) => T) => {
      qClient.setQueryData(queryKeys, updater);
    }
  );

  const query = useQuery(
    queryKeys,
    async () => {
      const { data } = await v2Api.get('/reservations/transactions', {
        params: {
          page: (page || 0) + 1,
          limit: perPage,
          startDate: startDate && format(startDate, 'yyyy-MM-dd'),
          endDate: endDate && format(endDate, 'yyyy-MM-dd'),
          searchText: debouncedSearchText,
          status:
            rStatus &&
            API_RSRV_STATUS_MAP[RESERVATION_STATUS_MAP[rStatus].apiRsrvStatus!],
          ticketId,
        },
      });
      return ZTransactionResponse.parse(data);
    },
    { keepPreviousData }
  );

  return {
    ...query,
    transactions: query.data?.items,
    meta: query.data?.meta,
    optimisticUpdate,
  };
}

export const ZTransactionHistoryResponse = z.array(ZTransactionHistory);
export type TransactionHistoryResponse = z.infer<
  typeof ZTransactionHistoryResponse
>;

export function useTransactionHistory({
  reservationId,
  config,
}: {
  reservationId?: string;
  config?: UseQueryOptions<
    TransactionHistoryResponse,
    Error,
    TransactionHistoryResponse,
    Array<any>
  >;
}) {
  const v2Api = useV2Api();
  return useQuery(
    ['get-transaction-history', reservationId],
    async () => {
      if (!reservationId) throw Error('invalid reservationId');
      const { data } = await v2Api.get(
        `/reservations/${reservationId}/transactions`
      );
      return ZTransactionHistoryResponse.parse(data);
    },
    { enabled: !!reservationId, ...config }
  );
}

export const chargeTransactionTypeKey = [
  'refunded',
  'charge',
  'no_charge',
] as const;
export const ZChargeTransactionPayload = z.object({
  action: z.enum(chargeTransactionTypeKey),
  reason: z.string(),
  refundMessage: z.string().nullish(),
  messageForCustomer: z.string().nullish(),
});
export type ChargeTransactionPayload = z.infer<
  typeof ZChargeTransactionPayload
>;

export function useChargeTransaction(args?: {
  reservationId?: string;
  config?: UseMutationOptions<
    boolean,
    Error,
    ChargeTransactionPayload,
    unknown
  >;
}) {
  const v2Api = useV2Api();
  return useMutation(
    z
      .function()
      .args(ZChargeTransactionPayload)
      .implement(async (payload: ChargeTransactionPayload) => {
        if (!args?.reservationId) throw Error('invalid reservationId');
        await v2Api.post(
          `/reservations/${args?.reservationId}/transaction-action`,
          payload
        );
        return true;
      }),
    args?.config
  );
}

import React from 'react';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers/DatePicker';
import { FormHelperText, useTheme } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useTranslation } from 'next-i18next';

interface DatePickerProps<T, U>
  extends Omit<MuiDatePickerProps<T, U>, 'renderInput'> {
  placeholder?: string;

  error?: string;
}

const DatePicker: React.FC<DatePickerProps<Date, Date>> = (props) => {
  const { placeholder, error, ...restProps } = props;
  const theme = useTheme();
  const { i18n } = useTranslation();

  return (
    <MuiDatePicker
      PaperProps={{
        sx: {
          boxShadow: theme.shadows[24],
          borderRadius: '16px',
          overflow: 'hidden',

          '.MuiCalendarPicker-root': {
            '>div:first-of-type': {
              backgroundColor: theme.palette.primary.main,
              margin: 0,
              padding: '15px 12px',
              minHeight: 55,
              maxHeight: 55,
              color: '#fff',

              button: {
                color: '#fff',
              },
            },
          },

          '.MuiPickersDay-dayWithMargin': {
            '&.MuiPickersDay-today': {
              background: theme.palette.grey[500_16],
              borderColor: theme.palette.grey[500_24],
              color: '#000',
            },

            '&.MuiPickersDay-dayOutsideMonth': {
              color: theme.palette.grey[500_24],
            },

            '&.Mui-selected': {
              backgroundColor: theme.palette.primary.main,
              color: '#fff',
            },
          },
        },
      }}
      showDaysOutsideCurrentMonth={true}
      inputFormat="dd/MM/yyyy"
      renderInput={(params) => {
        const props = {
          ...params,
          inputProps: { ...params.inputProps, placeholder },
        };
        return (
          <>
            <TextField {...props} error={!!error} />
            {!!error && <FormHelperText error>{error}</FormHelperText>}
          </>
        );
      }}
      dayOfWeekFormatter={(day) => {
        if (i18n?.language?.startsWith('zh')) return day;

        return day.charAt(0).toUpperCase();
      }}
      {...restProps}
      // MUI date picker is weird. It shows "today" when value is undefined
      // use fallback null to hide this.
      // use "today" if you want to show today date
      value={restProps.value || null}
    />
  );
};

export default DatePicker;

import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { useMemo } from 'react';

export type Variant = 'filled' | 'outlined' | 'ghost' | 'disabled';
export type Color =
  | 'default'
  | 'primary'
  | 'success'
  | 'error'
  | 'warning'
  | 'info';

interface Props {
  variant?: Variant;
  color?: Color;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
}

export default function Label({
  variant = 'filled',
  color = 'default',
  children,
  sx,
}: Props) {
  const theme = useTheme();
  const backgroundColor = useMemo(() => {
    if (variant === 'outlined') {
      return '';
    }
    if (variant === 'disabled' || color === 'default') {
      return theme.palette.grey[300];
    }

    return variant !== 'ghost'
      ? theme.palette[color].main
      : theme.palette[color].main_16;
  }, [color, theme.palette, variant]);

  const textColor = useMemo(() => {
    if (variant === 'filled') {
      if (['default', 'warning', 'success'].includes(color)) {
        return theme.palette.grey[800];
      }
      return theme.palette.common.white;
    }
    if (variant === 'outlined') {
      if (color === 'default') {
        return theme.palette.text.primary;
      }
      return theme.palette[color].main;
    }
    if (variant === 'ghost') {
      if (color === 'default') {
        return theme.palette.text.secondary;
      }
      return theme.palette[color].dark;
    }
    return theme.palette.text.disabled;
  }, [color, theme.palette, variant]);

  return (
    <Box
      sx={{
        backgroundColor,
        color: textColor,
        padding: '0px 8px 2px',
        borderRadius: '6px',
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
        fontSize: 'small',
        border: variant === 'outlined' ? '1px solid' : '',
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}

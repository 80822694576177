import { ButtonGroup, Box, styled, useTheme } from '@mui/material';
import { SecondaryButton } from '@/common/components/buttons';
import { useMemo } from 'react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import findLast from 'lodash/findLast';
import first from 'lodash/first';
import last from 'lodash/last';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import useLocaleCfg from '@/common/hooks/use-locale-cfg';
import { usePwaOrMobileView } from '@/common/hooks/use-pwa';

interface DinningIntervalProps {
  value: number;
  onChange: (newVal: number) => void;
  disabled: boolean;
}
const AVAILABLE_VALUES = [
  900, 1800, 2700, 3600, 4500, 5400, 6300, 7200, 8100, 9000, 9900, 10800, 11700,
  12600, 13500, 14400, 15300, 16200, 17100, 18000, 18900, 19800, 20700, 21600,
];

const Button = styled(SecondaryButton)(({ theme }) => [
  {
    backgroundColor: theme.palette.grey[100],
    width: theme.spacing(7),
    height: theme.spacing(7),
    color: theme.palette.text.primary,
  },
  {
    '&:hover': {
      borderRightColor: `${theme.palette.grey[500_32]} !important`,
    },
  },
]);

const DinningInterval = ({
  value,
  onChange,
  disabled,
}: DinningIntervalProps) => {
  const theme = useTheme();
  const { secondsToHrsMins } = useI18nTimeUtils();
  const locale = useLocaleCfg();
  const isEnglish = useMemo(
    () => locale.dateLocale.code?.includes('en'),
    [locale]
  );
  const { isMobileView } = usePwaOrMobileView();

  const increase = () => {
    const next = AVAILABLE_VALUES.find((it) => it > value);
    if (next) {
      onChange(next);
    }
  };

  const decrease = () => {
    const next = findLast(AVAILABLE_VALUES, (it) => it < value);
    if (next) {
      onChange(next);
    }
  };

  const decreaseDisabled = useMemo(() => {
    if (disabled) return true;
    return first(AVAILABLE_VALUES)! >= value;
  }, [disabled, value]);

  const increaseDisabled = useMemo(() => {
    if (disabled) return true;
    return last(AVAILABLE_VALUES)! <= value;
  }, [disabled, value]);

  return (
    <ButtonGroup sx={{ width: '100%' }}>
      <Button onClick={decrease} disabled={decreaseDisabled}>
        <RemoveIcon />
      </Button>
      <Box
        sx={{
          width: isMobileView
            ? '100%'
            : isEnglish
            ? theme.spacing(8)
            : theme.spacing(9),
          height: theme.spacing(7),
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid',
          borderColor: theme.palette.grey[500_32],
          color: disabled
            ? theme.palette.text.disabled
            : theme.palette.text.primary,
          backgroundColor: disabled
            ? theme.palette.grey[100]
            : theme.palette.common.white,
        }}
      >
        {secondsToHrsMins(value)}
      </Box>
      <Button onClick={increase} disabled={increaseDisabled}>
        <AddIcon />
      </Button>
    </ButtonGroup>
  );
};

export default DinningInterval;

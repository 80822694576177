import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { TicketItem } from './components/action-reservation-modal/ticket-selection';
import { SelectedTable } from './components/add-reservation/assign-table-menu/type';
import { Customer } from '@/common/types/customer';
import { UseFormReturn, useForm } from 'react-hook-form';
import {
  CreateCustomerPayload,
  useAddCustomer,
} from '@/common/hooks/use-customers';
import { format } from 'date-fns';
import {
  useAddReservation,
  useAddWalkIn,
  useUpdateReservation,
  useUpdateWalkIn,
} from '@/common/hooks/use-reservations';
import { ReservationItem } from '@/common/types/reservation';
import { useAtom, atom, useAtomValue } from 'jotai';
import {
  automatedCallNotifyAtom,
  customerInfoActionAtom,
  emailNotifyAtom,
  reservationTagsAtom,
  reservationTypeAtom,
  smsNotifyAtom,
  TagItem,
  useResetState,
} from './state';
import { PaymentStatusResponse } from '@/common/types/transaction.base';

type TimeBlockItem = {
  start: string;
  end: string;
};

type TimeBlockCtx = {
  timeBlock: TimeBlockItem;
  setTimeBlock: Dispatch<SetStateAction<TimeBlockItem>>;
};

const timeBlockAtom = atom<TimeBlockItem>({ start: '', end: '' });

export const useTimeBlock = (): TimeBlockCtx => {
  const [timeBlock, setTimeBlock] = useAtom(timeBlockAtom);
  return { timeBlock, setTimeBlock };
};

export const DEFAULT_DINING_INTERVAL = 5400;
export const DEFAULT_EXPIRE_TIME = 1440;

type ReservationFormValue = {
  ticket: TicketItem | null;
  dateTime: Date | null;
  tables: SelectedTable[] | null;
  staffNote: string;
  specialRequest: string;
  otherOccasion: string;
  diningInterval: number;
  adultsPax: number;
  childrenPax: number;
  occasionsSelected: Array<string>;
  customerDetailData: Customer | null;
  paymentType: PaymentType;
  paymentStatus: PaymentStatusResponse;
  notePaymentRequest: string;
  expireTime: number;
  tags: TagItem[] | null;
};

type CustomerFormValue = {
  salutation: string | null;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  birthday: Date | null;
  email: string;
  receivePromotions: boolean;
  customerNotes: string;
};

type ReservationActionContextType = {
  reservationForm: UseFormReturn<ReservationFormValue>;
  customerForm: UseFormReturn<CustomerFormValue>;
  handleAddReservation: () => Promise<ReservationItem | undefined>;
  valueDate: string;
  valueTimeSlot: string;
  isAddingReservation: boolean;
  isAddingWalkIn: boolean;
  isUpdatingReservation: boolean;
  isUpdatingWalkIn: boolean;
  isAddingCustomer: boolean;
};

const ReservationActionContext = createContext<ReservationActionContextType>({
  reservationForm: null as any,
  customerForm: null as any,
  handleAddReservation: (() => {}) as any,
  valueDate: '',
  valueTimeSlot: '',
  isAddingReservation: false,
  isAddingWalkIn: false,
  isUpdatingReservation: false,
  isUpdatingWalkIn: false,
  isAddingCustomer: false,
});

export type ActionReservationType = 'Add' | 'Edit';
export type ReservationType = 'Reservation' | 'WalkIn';
export type ActionWithCustomerInfo = 'SEE_DETAIL' | 'SEARCH' | 'ADD';
export type PaymentType = 'ONLINE_PAYMENT_LINK' | 'OTHER_METHOD';

interface ReservationActionContextProviderProps {
  children: React.ReactNode;
  actionType: ActionReservationType;
  editedReservationId?: string;
  openModal: boolean;
}

const reservationFormDefaultValue: ReservationFormValue = {
  ticket: null,
  dateTime: null,
  tables: null,
  specialRequest: '',
  staffNote: '',
  otherOccasion: '',
  diningInterval: DEFAULT_DINING_INTERVAL,
  adultsPax: 2,
  childrenPax: 0,
  occasionsSelected: [],
  customerDetailData: null,
  paymentType: 'ONLINE_PAYMENT_LINK',
  expireTime: DEFAULT_EXPIRE_TIME,
  paymentStatus: 'pending',
  notePaymentRequest: '',
  tags: [],
};

const customerFormDefaultValue: CustomerFormValue = {
  salutation: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  birthday: null,
  email: '',
  receivePromotions: false,
  customerNotes: '',
};

export const ReservationActionContextProvider = ({
  children,
  actionType,
  editedReservationId,
  openModal,
}: ReservationActionContextProviderProps) => {
  const reservationType = useAtomValue(reservationTypeAtom);
  const [customerInfoActionState] = useAtom(customerInfoActionAtom);
  const [smsNotify] = useAtom(smsNotifyAtom);
  const [emailNotify] = useAtom(emailNotifyAtom);
  const [automatedCallNotify] = useAtom(automatedCallNotifyAtom);
  const tags = useAtomValue(reservationTagsAtom);

  const reservationForm = useForm<ReservationFormValue>({
    defaultValues: reservationFormDefaultValue,
    mode: 'onChange',
  });

  const customerForm = useForm<CustomerFormValue>({
    defaultValues: customerFormDefaultValue,
    mode: 'onChange',
  });

  const watchDateTime = reservationForm.watch('dateTime');

  const { valueDate, valueTimeSlot } = useMemo(() => {
    if (!watchDateTime)
      return {
        valueDate: '',
        valueTimeSlot: '',
      };
    const [valueDate = '', valueTimeSlot = ''] = format(
      watchDateTime,
      'yyyy-MM-dd,HH:mm:ss'
    ).split(',');

    return {
      valueDate,
      valueTimeSlot,
    };
  }, [watchDateTime]);

  const addCustomerMutation = useAddCustomer();
  const addReservationMutation = useAddReservation();
  const addWalkinMutation = useAddWalkIn();
  const updateReservation = useUpdateReservation();
  const updateWalkIn = useUpdateWalkIn();

  const createCustomer = () => {
    const data = customerForm.getValues();
    const customer: CreateCustomerPayload = {
      firstName: data.firstName,
      lastName: data.lastName,
      ...(!!data.salutation && {
        salutation: data.salutation,
      }),
      phone: '+' + data.phoneNumber,
      additionalPhone: undefined,
      organisationName: undefined,
      canSendEmailMarketing: data.receivePromotions,
      email: data.email || null,
      notesDiner: data.customerNotes,
      ...(!!data.birthday && {
        birthday: format(data.birthday, 'yyyy-MM-dd'),
      }),
    };

    return addCustomerMutation.mutateAsync(customer);
  };

  const saveReservation = async (customer?: Customer | null) => {
    const {
      tables,
      occasionsSelected,
      otherOccasion,
      adultsPax,
      childrenPax,
      specialRequest,
      staffNote,
      diningInterval,
      ticket,
      paymentType,
      paymentStatus,
      notePaymentRequest,
      expireTime,
    } = reservationForm.getValues();
    const occasions = occasionsSelected
      ?.filter((occasion) => occasion !== 'Others')
      ?.join(', ');

    const otherOccasions =
      occasionsSelected?.includes('Others') && !!otherOccasion?.trim()
        ? otherOccasion?.trim()
        : '';

    const tableIds =
      !Array.isArray(tables) || !tables?.length
        ? []
        : (tables as SelectedTable[]).map((item) => item?.id);

    const tagIds =
      !Array.isArray(tags) || !tags?.length
        ? []
        : (tags as TagItem[]).map((item) => item?.id);

    const payloadWalkIn = {
      // customer
      customerId: customer?.mcaId || '',
      email: customer?.email || '',
      language: customer?.language || '',
      salutation: customer?.salutation || 'N/A',
      notesDiner: customer?.notesDiner || '',
      canSendEmailMarketing: customer?.canSendEmailMarketing || false,
      // pax
      numberOfAdults: adultsPax,
      numberOfChildren: childrenPax,
      // customer notes
      notes: specialRequest,
      staffNotes: staffNote,
      occasions,
      otherOccasions,
      // dining
      diningInterval: diningInterval,
      // table
      tableIds,
      // reservation tags
      tagIds,
    };

    const callApi = async () => {
      if (actionType === 'Add') {
        if (reservationType === 'Reservation') {
          return await addReservationMutation.mutateAsync({
            ...payloadWalkIn,
            allowEmailNotify: emailNotify,
            allowSmsNotify: smsNotify,
            allowAutomatedCallNotify: automatedCallNotify,
            ticketId: ticket?.value || '',
            reservationDate: valueDate,
            reservationTime: valueTimeSlot,
            firstName: customer?.firstName || '',
            lastName: customer?.lastName || '',
            phone: customer?.phone || '',
            paymentType: paymentType,
            expireTime: expireTime,
            paymentStatus: paymentStatus,
            notePaymentRequest: notePaymentRequest,
          });
        } else {
          return await addWalkinMutation.mutateAsync({
            ...payloadWalkIn,
            customerFirstName: customer?.firstName || '',
            customerLastName: customer?.lastName || '',
            customerPhone: customer?.phone || '',
          });
        }
      } else {
        if (reservationType === 'Reservation') {
          return await updateReservation.mutateAsync({
            ...payloadWalkIn,
            allowEmailNotify: emailNotify,
            allowSmsNotify: smsNotify,
            allowAutomatedCallNotify: automatedCallNotify,
            ticketId: ticket?.value || '',
            reservationDate: valueDate,
            reservationTime: valueTimeSlot,
            firstName: customer?.firstName || '',
            lastName: customer?.lastName || '',
            phone: customer?.phone || '',
            id: editedReservationId || '',
            paymentStatus,
            paymentType,
          });
        } else {
          return await updateWalkIn.mutateAsync({
            ...payloadWalkIn,
            customerFirstName: customer?.firstName || '',
            customerLastName: customer?.lastName || '',
            customerPhone: customer?.phone || '',
            id: editedReservationId || '',
          });
        }
      }
    };
    return callApi();
  };

  const handleAddReservation = async () => {
    if (reservationType === 'Reservation') {
      // Reservation requires customer information
      const reservationFormValid = await reservationForm.trigger();
      const customerFormValid = await customerForm.trigger();
      if (customerInfoActionState === 'SEARCH') {
        return;
      }
      const reservationMissingCustomer =
        reservationForm.formState.errors.customerDetailData &&
        Object.keys(reservationForm.formState.errors).length === 1;
      if (
        (reservationFormValid || reservationMissingCustomer) &&
        customerFormValid
      ) {
        let customerDetail = reservationForm.getValues().customerDetailData;
        if (!customerDetail) {
          customerDetail = await createCustomer();
        }
        if (customerDetail) {
          return saveReservation(customerDetail);
        }
      }
    } else {
      // Walkin: customer information are optional
      const reservationFormValid = await reservationForm.trigger();
      let customerFormValid = true;
      if (customerInfoActionState === 'ADD') {
        customerFormValid = await customerForm.trigger();
      }
      if (reservationFormValid && customerFormValid) {
        let customerDetail = reservationForm.getValues().customerDetailData;
        if (customerInfoActionState === 'ADD') {
          customerDetail = await createCustomer();
        }
        return saveReservation(customerDetail);
      }
    }
  };

  const resetState = useResetState();

  useEffect(() => {
    // reset states when component unmount or the modal is closed
    if (!openModal) {
      resetState();
      reservationForm.reset(reservationFormDefaultValue);
      customerForm.reset(customerFormDefaultValue);
    }
  }, [customerForm, openModal, reservationForm, resetState]);

  return (
    <ReservationActionContext.Provider
      value={{
        reservationForm,
        customerForm,
        handleAddReservation,
        valueDate,
        valueTimeSlot,
        isAddingReservation: addReservationMutation.isLoading,
        isAddingWalkIn: addWalkinMutation.isLoading,
        isUpdatingReservation: updateReservation.isLoading,
        isUpdatingWalkIn: updateWalkIn.isLoading,
        isAddingCustomer: addCustomerMutation.isLoading,
      }}
    >
      {children}
    </ReservationActionContext.Provider>
  );
};

export const useReservationActionContext = () => {
  return useContext(ReservationActionContext);
};

import { ReservationItem } from '@/common/types/reservation';
import { DialogTitle, Typography } from '@mui/material';
import { css } from '@emotion/react';
import { titleSource } from './common';
import RestaurantSourceIcon from '@/common/components/restaurant-src-icon';
import { MobileBackBtn } from '@/common/components/buttons';
import { AutoRsrvStatusBtn } from '@/common/components/reservation-status-btn';

export const MobileDrawerTitle = ({
  reservationData,
  onClose,
}: {
  reservationData: ReservationItem;
  onClose: () => void;
}) => {
  const source = reservationData.source;

  return (
    <DialogTitle
      component="div"
      sx={{
        display: { xs: 'flex', md: 'none' },
        padding: '16px',
        lineHeight: 0,
      }}
    >
      <MobileBackBtn onClick={onClose} />
      <div
        css={css`
          min-width: 0;
          svg {
            flex-shrink: 0;
          }
          .MuiTypography-root {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
          }
        `}
      >
        {source && (
          <div
            css={css`
              display: flex;
              align-items: center;
            `}
          >
            <RestaurantSourceIcon source={source} />
            <Typography variant="body2" color="grey.800" ml={1}>
              {titleSource[source]}
            </Typography>
          </div>
        )}
        {reservationData?.code && (
          <Typography variant="caption" color="grey.600" ml={3}>
            #{reservationData.code}
          </Typography>
        )}
      </div>
      <div style={{ flex: 1 }} />
      <AutoRsrvStatusBtn reservationItem={reservationData} />
    </DialogTitle>
  );
};

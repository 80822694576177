import { ReservationItem } from '@/common/types/reservation';
import { assertUnreachable } from '@/common/utils';
import { useShowResChargeOrRefundConfirmModal } from '@/feat/reservation/components/confirmation/global-charge-or-refund-reservation-confirmation';
import { useChargeTransaction } from '@/common/hooks/use-transactions';
import { ResChargeOrRefundType } from '@/feat/reservation/components/confirmation/charge-or-refund-reservation-confirmation';
import { useShowSnackbarMessage } from '@/common/components/snack-bar';
import useCallbackRef from '@/common/hooks/use-callback-ref';
import { useMemo } from 'react';
import { useCustomerNameFmt } from '@/common/hooks/use-customers';
import { useTranslation } from 'next-i18next';

export default function useChangeReservationAction(
  reservation: ReservationItem | undefined | null,
  callback: () => void
) {
  const { t } = useTranslation();
  const customerNameFmt = useCustomerNameFmt();
  const showSnackbar = useShowSnackbarMessage();
  const showResChargeOrRefundConfirmModal =
    useShowResChargeOrRefundConfirmModal();
  const { mutateAsync: chargeTransaction, isLoading } = useChargeTransaction({
    reservationId: reservation?.id,
  });

  const changeReservationAction = useCallbackRef(
    async (type: ResChargeOrRefundType) => {
      if (!reservation) return;
      const [action, reason] = await showResChargeOrRefundConfirmModal({
        type,
        reservation,
      });
      const customerName = reservation.user
        ? customerNameFmt(reservation.user)
        : '';

      if (action === 'ABORTED') return reservation;
      if (action === 'CONFIRMED') {
        switch (type) {
          case 'CHARGE':
            await chargeTransaction({
              action: 'charge',
              reason: reason?.internalReason || '',
              messageForCustomer: reason?.messageToCustomer,
            });
            showSnackbar(
              t(`{{ customerName }}’s reservation has been charged.`, {
                customerName,
              })
            );
            break;
          case 'NOT_CHARGE':
            await chargeTransaction({
              action: 'no_charge',
              reason: reason?.internalReason || '',
              messageForCustomer: reason?.messageToCustomer,
            });
            showSnackbar(
              t(`{{ customerName }}’s reservation has not been charged.`, {
                customerName,
              })
            );
            break;
          case 'REFUND':
            await chargeTransaction({
              action: 'refunded',
              reason: reason?.internalReason || '',
              messageForCustomer: reason?.messageToCustomer,
            });
            showSnackbar(
              t(`{{ customerName }}’s reservation has been refunded.`, {
                customerName,
              })
            );
            break;
          default:
            assertUnreachable(type);
        }
      }
      callback();
    }
  );

  return useMemo(
    () => ({
      changeReservationAction,
      isLoadingChangeReservation: isLoading,
    }),
    [changeReservationAction, isLoading]
  );
}

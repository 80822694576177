import { ReservationItem } from '@/common/types/reservation';
import { atom, useAtom } from 'jotai';
import { atomWithLocation } from 'jotai-location';
import { useEffect, useMemo } from 'react';
import ActionReservationModal from './action-reservation-modal';
import ViewReservationModal from './view-reservation-modal';
import useCallbackRef from '@/common/hooks/use-callback-ref';
import { ReservationType, reservationTypeAtom } from '../state';
import { useIsPreventNavAnimation } from '@/common/hooks/use-prevent-nav-animation';
import { usePageEvent } from '@/common/lib/segment';

const locationAtom = atomWithLocation();

type URLDerivedState = {
  mode: 'Add' | 'View' | 'Edit';
  reservationId?: string;
};

const urlDerivedAtom = atom<
  URLDerivedState | null,
  [URLDerivedState | null],
  void
>(
  (get) => {
    const searchParams = get(locationAtom).searchParams;
    if (searchParams?.get('addReservation') === 'true') {
      return { mode: 'Add' };
    }
    const viewingReservationId = searchParams?.get('viewReservation');
    if (viewingReservationId) {
      return {
        mode: 'View',
        reservationId: viewingReservationId,
      };
    }
    const editingReservationId = searchParams?.get('editReservation');
    if (editingReservationId) {
      return {
        mode: 'Edit',
        reservationId: editingReservationId,
      };
    }
    return null;
  },
  (_get, set, drawerState) =>
    set(locationAtom, (prev) => {
      let searchParams = new URLSearchParams(prev.searchParams);
      searchParams.delete('addReservation');
      searchParams.delete('viewReservation');
      searchParams.delete('editReservation');
      if (drawerState) {
        const { mode, reservationId } = drawerState;
        if (mode === 'Add') searchParams.set('addReservation', 'true');
        if (mode === 'View' && reservationId)
          searchParams.set('viewReservation', reservationId);
        if (mode === 'Edit' && reservationId)
          searchParams.set('editReservation', reservationId);
      }
      return { ...prev, searchParams };
    })
);

type State = {
  reservationType?: ReservationType;
  isAffectedReservation?: boolean;
  mcaIdPrefillCustomer?: string;
  disableConfirmModal?: boolean;
  className?: string;
  initialValue?: {
    tables?: { id: string; name: string; maxPax: number }[];
    reservationDate?: string;
    reservationTime?: string;
    diningInterval?: number;
  };
  // Add / Edit
  onClose?: () => void;
  onSuccess?: (updated: ReservationItem) => void;
  onView?: (reservationId: string) => void;
  // View
  onStatusChangeSuccess?: (newReservation: ReservationItem) => void;
  //Resend PaymentRequest
  onResendReservation?: ReservationItem;
};
const stateAtom = atom<State | null>(null);

type ReservationDrawerAtom = URLDerivedState & State;
export const reservationDrawerAtom = atom<
  null | ReservationDrawerAtom,
  [null | ReservationDrawerAtom],
  void
>(
  (get) => {
    const isOpen = get(urlDerivedAtom);
    if (!isOpen) return null;
    const state = get(stateAtom);
    return {
      ...isOpen,
      ...state,
    };
  },
  (_get, set, arg0) => {
    if (!arg0) {
      set(urlDerivedAtom, null);
      set(stateAtom, null);
      return;
    }
    const {
      mode,
      reservationId,
      onClose,
      onSuccess,
      onView,
      onStatusChangeSuccess,
      className,
      disableConfirmModal,
      isAffectedReservation,
      mcaIdPrefillCustomer,
      reservationType,
      initialValue,
      onResendReservation,
    } = arg0;
    set(urlDerivedAtom, { mode, reservationId });
    set(stateAtom, {
      className,
      disableConfirmModal,
      isAffectedReservation,
      mcaIdPrefillCustomer,
      onClose,
      onSuccess,
      onView,
      onStatusChangeSuccess,
      initialValue,
      onResendReservation,
    });
    if (reservationType) {
      set(reservationTypeAtom, reservationType);
    }
  }
);

const GlobalReservationDrawer = () => {
  const [drawerState, setDrawerState] = useAtom(reservationDrawerAtom);
  const pageEvent = usePageEvent();
  const handleClose = useCallbackRef(() => {
    setDrawerState(null);
  });

  const handleEdit = useCallbackRef((id: string) => {
    setDrawerState({ mode: 'Edit', reservationId: id });
  });

  const handleResendLinkPayment = useCallbackRef(
    (reservationResend: ReservationItem) => {
      setDrawerState({
        mode: 'Add',
        onResendReservation: reservationResend,
      });
    }
  );

  const onView = useCallbackRef((id: string) => {
    if (drawerState?.onView) return drawerState.onView(id);
    setDrawerState({ mode: 'View', reservationId: id });
  });

  const actionType: 'Add' | 'Edit' = useMemo(() => {
    if (drawerState?.mode === 'Edit') {
      return 'Edit';
    }
    return 'Add';
  }, [drawerState?.mode]);
  const isPreventNavAnimation = useIsPreventNavAnimation();

  // This effect should be called once when the drawerState.mode changed
  // Should not be triggered if session data changed
  // TODO: check session data before call segment api
  useEffect(() => {
    if (drawerState) {
      pageEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerState?.mode]);

  return (
    <>
      <ViewReservationModal
        reservationId={
          drawerState?.mode === 'View'
            ? drawerState?.reservationId || null
            : null
        }
        close={handleClose}
        onEdit={handleEdit}
        onResend={handleResendLinkPayment}
        /** disable animation when swiping to go back on iOS */
        disableTransition={isPreventNavAnimation}
      />
      <ActionReservationModal
        actionType={actionType}
        editedReservationId={
          drawerState?.mode === 'Edit' ? drawerState?.reservationId : undefined
        }
        openModal={drawerState?.mode === 'Add' || drawerState?.mode === 'Edit'}
        closeModal={handleClose}
        isAffectedReservation={drawerState?.isAffectedReservation}
        onView={onView}
        onSuccess={drawerState?.onSuccess}
        mcaIdPrefillCustomer={drawerState?.mcaIdPrefillCustomer}
        disableConfirmModal={drawerState?.disableConfirmModal}
        className={drawerState?.className}
        initialValue={drawerState?.initialValue}
        // resend new Reservation with data exited
        dataResendPayment={drawerState?.onResendReservation}
        /** disable animation when swiping to go back on iOS */
        disableTransition={isPreventNavAnimation}
      />
    </>
  );
};

export default GlobalReservationDrawer;

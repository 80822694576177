import { MenuItem, Select } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { Trans } from 'next-i18next';

interface SelectOption {
  value: number;
  label: React.ReactElement;
}

export function ShowTimeslotsTypeSelection({
  typeShowTimeslots,
  onTypeChange,
}: {
  typeShowTimeslots: number;
  onTypeChange: (newType: number) => void;
}) {
  const handleChange = (event: SelectChangeEvent<number>) => {
    const selectedType = event.target.value as number;
    onTypeChange(selectedType);
  };

  const timeslotsOptions: SelectOption[] = [
    {
      value: 1,
      label: <Trans>Operating Hours</Trans>,
    },
    {
      value: 2,
      label: <Trans>All Time Slots</Trans>,
    },
  ];
  return (
    <Select
      sx={{ width: '250px' }}
      MenuProps={{
        style: {
          zIndex: 1999,
        },
      }}
      value={typeShowTimeslots}
      onChange={handleChange}
    >
      {timeslotsOptions.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

import useCallbackRef from '@/common/hooks/use-callback-ref';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { ReservationItem } from '@/common/types/reservation';
import { isPRTicket } from '@/common/types/ticket';
import { TICKET_TYPE_PAYMENT } from '@/common/types/ticket.base';
import { ValueOf, assertUnreachable } from '@/common/utils';
import PaymentStatusBox from '@/feat/transaction/components/payment-status';
import { Stack } from '@mui/material';
import { Card, CardContent } from '@mui/material';
import { Box, Typography, Button } from '@mui/material';
import { parseISO } from 'date-fns';
import { Trans, useTranslation } from 'next-i18next';
import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Divider } from '@mui/material';
import { Transaction } from '@/common/types/transaction';
import { useTheme } from '@mui/material';
import { useMoneyFmt } from '@/common/hooks/use-money-fmt';
import { Grid } from '@mui/material';

const TransactionDateTime = ({
  transaction,
}: {
  transaction: Transaction | null | undefined;
}) => {
  const { formatDate } = useI18nTimeUtils();

  const date = useMemo(
    () =>
      transaction?.createdTimestamp
        ? parseISO(transaction.createdTimestamp)
        : null,
    [transaction?.createdTimestamp]
  );

  return (
    <span>
      {date
        ? formatDate(date, {
            en: 'dd MMM yyyy',
            zh: 'yyyy MMM do',
          })
        : 'N/A'}
    </span>
  );
};

export const PaymentDetailsPanel = ({
  reservationData,
  onViewFullTransaction,
}: {
  reservationData: ReservationItem;
  onViewFullTransaction: () => void;
}) => {
  const { formatLongDateTime } = useI18nTimeUtils();
  const { t } = useTranslation();
  const theme = useTheme();
  const { moneyFmt } = useMoneyFmt();

  const generateTypePaymentLabel = useCallbackRef(
    (
      typePayment: ValueOf<typeof TICKET_TYPE_PAYMENT> | null | undefined
    ): string => {
      switch (typePayment) {
        case null:
        case undefined:
          return '';
        case TICKET_TYPE_PAYMENT['No Payment']:
          return t('No Payment');
        case TICKET_TYPE_PAYMENT.Deposit:
          return t('Deposit');
        case TICKET_TYPE_PAYMENT.Prepaid:
          return t('Prepaid');
        case TICKET_TYPE_PAYMENT.CardGuarantee:
          return t('Card Guarantee');
        default:
          assertUnreachable(typePayment);
          return '';
      }
    }
  );

  if (!reservationData?.ticket || !isPRTicket(reservationData.ticket))
    return null;

  const title = <Trans>Payment Details</Trans>;

  return (
    <Box
      css={css`
        padding: 16px;
        box-shadow: ${theme.customShadows.card};
        border-radius: 16px;
        margin-top: 24px;

        .MuiTypography-overline {
          letter-spacing: 1.2px;
          text-transform: uppercase;
          color: #919eab;
        }
      `}
    >
      <Box sx={{ display: { xs: 'none', md: 'block' } }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '24px',
          }}
        >
          <Typography variant="h6">{title}</Typography>
        </Box>
        <Box sx={{ mt: 1 }}>
          <Grid
            container
            spacing={5}
            sx={{
              marginTop: 'unset',
              '>.MuiGrid-item': {
                paddingTop: 'unset',
              },
              marginBottom: '16px',
            }}
          >
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={3} sx={{ marginTop: 1 }}>
                  <Stack
                    style={{ justifyContent: 'space-between', height: '100%' }}
                  >
                    <Typography variant="overline">
                      <Trans>Date and Time</Trans>
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '8px' }}>
                      <TransactionDateTime
                        transaction={reservationData?.transaction}
                      />
                    </Typography>
                  </Stack>
                </Grid>

                <Grid item xs={3} sx={{ marginTop: 1 }}>
                  <Stack
                    style={{ justifyContent: 'space-between', height: '100%' }}
                  >
                    <Typography variant="overline">
                      <Trans>PAYMENT STATUS</Trans>
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '8px' }}>
                      <PaymentStatusBox
                        original={reservationData?.transaction!}
                        hideTime
                      />
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={3} sx={{ marginTop: 1 }}>
                  <Stack
                    style={{ justifyContent: 'space-between', height: '100%' }}
                  >
                    <Typography variant="overline">
                      <Trans>TICKET TYPE</Trans>
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '8px' }}>
                      {generateTypePaymentLabel(
                        reservationData?.ticket?.typePayment
                      )}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid item xs={3} sx={{ marginTop: 1 }}>
                  <Stack
                    style={{ justifyContent: 'space-between', height: '100%' }}
                  >
                    <Typography variant="overline">
                      <Trans>TOTAL AMOUNT</Trans>
                    </Typography>
                    <Typography variant="body2" sx={{ marginTop: '8px' }}>
                      {moneyFmt(reservationData?.transaction?.amount)}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Button
        variant="outlined"
        size="medium"
        onClick={onViewFullTransaction}
        style={{
          width: '100%',
          margin: '16px 0 16px 0',
        }}
      >
        <Trans>View Full Transaction</Trans>
      </Button>
      <Card
        sx={{ display: { md: 'none' } }}
        css={css`
          .MuiTypography-overline {
            letter-spacing: 1.2px;
            text-transform: uppercase;
            color: ${theme.palette.grey[500]};
          }
        `}
      >
        <CardContent>
          <Stack gap={2}>
            <Typography variant="subtitle1">{title}</Typography>
            <Stack gap={1}>
              <Typography variant="overline">
                <Trans>Date and Time</Trans>
              </Typography>
              <Typography variant="body2">
                {reservationData.transaction?.createdTimestamp
                  ? formatLongDateTime(
                      parseISO(reservationData.transaction.createdTimestamp)
                    )
                  : 'N/A'}
              </Typography>
            </Stack>
            <Divider />
            <Stack gap={1}>
              <Typography variant="overline">
                <Trans>Payment Status</Trans>
              </Typography>
              <PaymentStatusBox
                original={reservationData.transaction}
                hideTime
              />
            </Stack>
            <Divider />
            <Stack gap={1}>
              <Typography variant="overline">
                <Trans>Ticket Type</Trans>
              </Typography>
              <Typography variant="body2">
                {generateTypePaymentLabel(reservationData.ticket?.typePayment)}
              </Typography>
            </Stack>
            <Divider />
            <Stack gap={1}>
              <Typography variant="overline">
                <Trans>Total Amount</Trans>
              </Typography>
              <Typography variant="body2">
                {moneyFmt(reservationData.transaction?.amount)}
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

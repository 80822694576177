import React from 'react';
import { Box, Typography } from '@mui/material';
import { Trans } from 'next-i18next';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';

interface IProps {
  title?: String;
  description: String;
  mode: 'secondary' | 'error';
}

const PaymentAlert = (props: IProps) => {
  const { title, description, mode } = props;

  return (
    <Box
      sx={{
        background: mode === 'secondary' ? '#CBEAFB' : '#FDE1D3',
        borderRadius: 1,
      }}
    >
      <Box sx={{ padding: '15px 18px', display: 'flex' }}>
        <InfoRoundedIcon
          color={mode === 'secondary' ? 'secondary' : 'error'}
          fontSize="small"
        />
        <Box sx={{ paddingLeft: '8px' }}>
          {title && (
            <Box style={{ paddingBottom: 4 }}>
              <Typography
                sx={{
                  color: mode === 'secondary' ? '#003768' : '#DB2727',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                <Trans>{title}</Trans>
              </Typography>
            </Box>
          )}

          <Typography
            sx={{
              color: mode === 'secondary' ? '#01195A' : '#9D132B',
              fontSize: '14px',
              fontWeight: '400',
            }}
          >
            <Trans>{description}</Trans>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentAlert;

import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  InputAdornment,
  TextField,
  Typography,
  styled,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  useTheme,
} from '@mui/material';
import { Trans, useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import FileCopyTwoToneIcon from '@mui/icons-material/FileCopyTwoTone';
import { formatCurrency } from '@/common/lib/money';
import PersonTwoToneIcon from '@mui/icons-material/PersonTwoTone';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useLocaleCfg from '@/common/hooks/use-locale-cfg';
import { TICKET_TYPE_PAYMENT } from '@/common/types/ticket.base';
import { assertUnreachable } from '@/common/utils';
import PaymentStatusBox from '@/feat/transaction/components/payment-status';
import { TextareaAutosize } from '@mui/material';
import { ReservationItem } from '@/common/types/reservation';
import PaymentAlert from '@/common/components/payment-aleft';
import { PrimaryButton } from '@/common/components/buttons';
import { calculateTimeDifference } from '../../utils';

interface IProps {
  dataReservation: ReservationItem;
  onResendAction: () => void;
}

const titleTextStyle = {
  color: '#212B36',
  fontSize: '16px',
  fontWeight: '400',
};

const PaymentRequestPanel = ({
  dataReservation,
  onResendAction,
}: IProps): JSX.Element => {
  const { t } = useTranslation();

  const locale = useLocaleCfg();
  const theme = useTheme();

  const paymentType = dataReservation?.reservationPaymentRequest?.type;

  const expireDateTime =
    dataReservation?.reservationPaymentRequest?.expireDateTime || '';
  const typeRefundWindow = dataReservation?.transaction;

  const { timeExpire, hoursDifference, minutesDifference } =
    calculateTimeDifference(expireDateTime);

  const typePayment = dataReservation?.ticket?.typePayment;
  const typePrice = dataReservation?.ticket?.typePrice;
  const link = dataReservation?.reservationPaymentRequest?.link || '';
  const price = dataReservation?.ticket?.price || 0;
  const priceTotal =
    typePrice === 1
      ? price *
        (dataReservation?.numberOfChildren + dataReservation?.numberOfAdults)
      : price;
  const isAllowSMS = dataReservation?.allowSmsNotify;
  const isAllowEmail = dataReservation?.allowEmailNotify;
  const paymentNote = dataReservation?.reservationPaymentRequest?.notes || '';

  const paymentTypeLabel = useMemo(() => {
    switch (typePayment) {
      case null:
      case undefined:
        return '';
      case TICKET_TYPE_PAYMENT['No Payment']:
        return t('No Payment');
      case TICKET_TYPE_PAYMENT.Deposit:
        return t('Deposit');
      case TICKET_TYPE_PAYMENT.Prepaid:
        return t('Prepaid');
      case TICKET_TYPE_PAYMENT.CardGuarantee:
        return t('Card Guarantee');
      default:
        assertUnreachable(typePayment);
        return '';
    }
  }, [t, typePayment]);

  const handleCopyUrl = async () => {
    try {
      await navigator.clipboard.writeText(link);
      alert('URL has been copied to clipboard');
    } catch (error) {
      console.error('Failed to copy URL: ', error);
    }
  };

  const AccordionCustom = styled(Accordion)(() => ({
    '&.MuiPaper-root': {
      height: 'fit-content ',
      background: 'unset',
      boxShadow: 'unset',
      margin: '16px 0px',
    },
    '&:before': {
      display: 'none',
    },
    '& .MuiAccordionDetails-root': {
      padding: '0',
    },
    '&.Mui-expanded': {
      '&:last-of-type, &:first-of-type': {
        margin: '16px 0px',
      },
    },
    '&.MuiButtonBase-root-MuiAccordionSummary-root': {
      padding: '0',
    },
  }));

  const AccordionSummaryCustom = styled(AccordionSummary)(() => ({
    flexDirection: 'row',
    minHeight: '40px',
    padding: '0 8px',
    '& .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: '0',
      '&.Mui-expanded': {
        margin: '0',
      },
    },
    '&.Mui-expanded': {
      minHeight: '40px',
    },
  }));

  const renderItems = (count: number, type: string) =>
    Array.from({ length: count }, (_, i) => (
      <Box
        key={i + 1}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: i > 0 ? '4px' : '0',
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Typography variant="body1" sx={titleTextStyle}>
            1&nbsp;
          </Typography>
          <Typography variant="body1" sx={titleTextStyle}>
            {type}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex' }}>
          <Typography
            variant="body1"
            sx={{
              color: 'grey.600',
              fontSize: '16px',
              fontWeight: '400',
              mr: '8px',
            }}
          >
            {paymentTypeLabel}
          </Typography>
          <Typography>{formatCurrency(price, locale)}</Typography>
        </Box>
      </Box>
    ));

  return (
    <Card>
      <CardContent>
        <Box>
          <Typography variant="subtitle1" sx={{ color: '#000000' }}>
            <Trans>Payment Request Configuration</Trans>
          </Typography>
        </Box>
        <Box>
          <AccordionCustom>
            <AccordionSummaryCustom
              expandIcon={<ExpandMoreIcon sx={{ fontSize: '24px' }} />}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <PersonTwoToneIcon fontSize="medium" sx={{ pb: 0.25 }} />
                <Typography
                  variant="body1"
                  sx={{
                    color: '#212B36',
                    fontSize: '16px',
                    fontWeight: '400',
                    mx: '4px',
                  }}
                >
                  {dataReservation?.numberOfAdults}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: '#212B36', fontSize: '16px', fontWeight: '400' }}
                >
                  <Trans>Adult</Trans>
                </Typography>
                {dataReservation?.numberOfChildren > 0 && (
                  <>
                    <Typography>, </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: '#212B36',
                        fontSize: '16px',
                        fontWeight: '400',
                        mx: '4px',
                      }}
                    >
                      {dataReservation?.numberOfChildren}
                    </Typography>
                    <Typography
                      sx={{
                        color: '#212B36',
                        fontSize: '16px',
                        fontWeight: '400',
                      }}
                    >
                      <Trans>Child</Trans>
                    </Typography>
                  </>
                )}
              </Box>
              <Typography
                sx={{ color: '#212B36', fontSize: '16px', fontWeight: '400' }}
              >
                {formatCurrency(priceTotal, locale)}
              </Typography>
            </AccordionSummaryCustom>
            <AccordionDetails>
              <Stack direction="column" spacing={1} sx={{ px: 1 }}>
                {typePrice === 1 ? (
                  <>
                    {renderItems(dataReservation?.numberOfAdults, 'Adult')}
                    {dataReservation?.numberOfChildren > 0 &&
                      renderItems(dataReservation?.numberOfChildren, 'Child')}
                  </>
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      margin: 0,
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body1" sx={titleTextStyle}>
                        {dataReservation?.numberOfAdults}&nbsp;
                      </Typography>
                      <Typography variant="body1" sx={titleTextStyle}>
                        <Trans>Adult</Trans>
                      </Typography>
                      {dataReservation?.numberOfChildren !== 0 && (
                        <>
                          <Typography>,&nbsp;</Typography>
                          <Typography variant="body1" sx={titleTextStyle}>
                            {dataReservation?.numberOfChildren}&nbsp;
                          </Typography>
                          <Typography variant="body1" sx={titleTextStyle}>
                            <Trans>Child</Trans>
                          </Typography>
                        </>
                      )}
                    </Box>

                    <Box sx={{ display: 'flex' }}>
                      <Typography
                        variant="body1"
                        sx={{
                          color: 'grey.600',
                          fontSize: '16px',
                          fontWeight: '400',
                          mr: '8px',
                        }}
                      >
                        {paymentTypeLabel}
                      </Typography>
                      <Typography>
                        {formatCurrency(priceTotal, locale)}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Stack>
            </AccordionDetails>
          </AccordionCustom>
        </Box>
        {paymentType && (
          <Box sx={{}}>
            <Divider sx={{ marginBottom: '24px' }} />
            <Box sx={{ paddingBottom: '4px' }}>
              <Typography
                sx={{ color: '#919EAB', fontSize: '12px', fontWeight: '700' }}
              >
                <Trans>PAYMENT TYPE</Trans>
              </Typography>
              <Box sx={{ paddingTop: '12px' }}>
                <Typography
                  sx={{ color: '#212B36', fontSize: '14px', fontWeight: '500' }}
                >
                  {paymentType === 'OTHER_METHOD' ? (
                    <Trans>Manual Payment</Trans>
                  ) : (
                    <Trans>Online Payment Link</Trans>
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        {paymentType === 'OTHER_METHOD' && (
          <Box>
            <Divider sx={{ margin: '24px 0px' }} />
            <Box sx={{ paddingBottom: '4px' }}>
              <Typography
                sx={{ color: '#919EAB', fontSize: '12px', fontWeight: '700' }}
              >
                <Trans>PAYMENT STATUS</Trans>
              </Typography>

              <Typography variant="body2" sx={{ paddingTop: '12px' }}>
                <PaymentStatusBox original={typeRefundWindow} hideTime />
              </Typography>
            </Box>
          </Box>
        )}

        {paymentType === 'ONLINE_PAYMENT_LINK' && (
          <Box>
            <Divider sx={{ margin: '16px 0px' }} />
            <Box sx={{ paddingBottom: '4px' }}>
              <Typography
                sx={{ color: '#919EAB', fontSize: '12px', fontWeight: '700' }}
              >
                <Trans>PAYMENT LINK SETTINGS</Trans>
              </Typography>
              <Box sx={{ paddingTop: '12px' }}>
                <Typography
                  sx={{
                    color: '#212B36',
                    fontSize: '14px',
                    fontWeight: '500',
                    mb: '12px',
                  }}
                >
                  <Trans>Payment Link</Trans>
                </Typography>
                {typeRefundWindow?.status === 'expired' ? (
                  <PaymentAlert
                    title=""
                    mode="error"
                    description={t('Link has Expired')}
                  />
                ) : (
                  <Box sx={{ width: '100%' }}>
                    <TextField
                      id="outlined-basic"
                      label={link}
                      disabled={true}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button variant="contained" onClick={handleCopyUrl}>
                              <FileCopyTwoToneIcon
                                sx={{ color: '#FFFFFF !important' }}
                              />
                              <Box sx={{ paddingLeft: '8px' }}>
                                <Trans>Copy URL</Trans>
                              </Box>
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                      InputLabelProps={{
                        style: {
                          color: '#212B36',
                        },
                      }}
                      sx={{
                        width: '100%',
                        '& .MuiFormLabel-root': {
                          width: '50%',
                        },
                      }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )}

        {timeExpire > 0 && typeRefundWindow?.status === 'pending' && (
          <Box sx={{ paddingTop: '24px' }}>
            <Typography
              sx={{ color: '#212B36', fontSize: '14px', fontWeight: '600' }}
            >
              {typeRefundWindow?.status === 'pending' ? (
                <Trans>Pending Reservation</Trans>
              ) : (
                <Trans>Link Validity Period</Trans>
              )}
            </Typography>

            <Box sx={{ paddingTop: '6px' }}>
              <Typography
                sx={{
                  color: '#637381',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
              >
                {t(
                  ` Expire in {{hoursDifference}} hours {{minutesDifference}} minutes`,
                  {
                    hoursDifference,
                    minutesDifference,
                  }
                )}
              </Typography>
            </Box>
          </Box>
        )}

        {paymentType === 'ONLINE_PAYMENT_LINK' &&
          typeRefundWindow?.status === 'pending' && (
            <Box sx={{ paddingTop: '24px' }}>
              <Typography
                sx={{ color: '#212B36', fontSize: '14px', fontWeight: '600' }}
              >
                <Trans>Send Payment Request Link</Trans>
              </Typography>
              <Box sx={{ paddingTop: '6px' }}>
                <Typography
                  sx={{
                    color: '#637381',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                >
                  {isAllowSMS && isAllowEmail ? (
                    <Trans>
                      Payment Request Link sent will be sent by you via SMS and
                      email.
                    </Trans>
                  ) : isAllowSMS ? (
                    <Trans>
                      Payment Request Link sent will be sent by you via SMS
                    </Trans>
                  ) : isAllowEmail ? (
                    <Trans>
                      Payment Request Link sent will be sent by you via Email
                    </Trans>
                  ) : (
                    <Trans>No Payment Request Link will be sent by you.</Trans>
                  )}
                </Typography>
              </Box>
            </Box>
          )}

        {paymentNote.length > 0 && (
          <Box>
            <Divider sx={{ margin: '24px 0' }} />
            <Typography
              sx={{
                color: '#919EAB',
                fontSize: '12px',
                fontWeight: '700',
                marginBottom: '12px',
              }}
            >
              <Trans>NOTE FOR PAYMENT REQUEST</Trans>
            </Typography>

            <TextareaAutosize
              disabled
              value={paymentNote}
              style={{
                width: '100%',
                height: 104,
                borderRadius: 8,
                border: `1px solid ${theme.palette.grey[500_32]}`,
                padding: '16px 14px',
                color: '#212B36',
                fontWeight: '500',
                fontSize: 16,
                fontFamily: 'Public Sans,system',
              }}
            />
          </Box>
        )}
        {/* handle logic to resend reservation */}
        {paymentType === 'ONLINE_PAYMENT_LINK' &&
          typeRefundWindow?.status === 'expired' && (
            <PrimaryButton
              onClick={onResendAction}
              sx={{ mt: 2, width: '100%' }}
            >
              {t('Resend Payment Link')}
            </PrimaryButton>
          )}
      </CardContent>
    </Card>
  );
};
export default PaymentRequestPanel;

import { add, format, isBefore, isAfter, isEqual } from 'date-fns';
import {
  ALLOWED_BOOKING_RESERVATION_STATUS,
  TableError,
  TableInAssignMenu,
} from '@/feat/reservation/components/add-reservation/assign-table-menu/type';
import BlockIcon from '@mui/icons-material/Block';
import React, { ReactNode } from 'react';
import { orderBy } from 'lodash-es';
import { Trans } from 'next-i18next';
import { Table } from '@/common/types/table';
import { ReservationItem } from '@/common/types/reservation';
import { PeopleOutlineTwoTone } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Area } from '@/common/types/area';
import { getBlockStartEndTime } from '@/common/components/table-block';

const getUpcomingReservations = (
  reservations: ReservationItem[],
  selectedTimeslot: Date
) => {
  let upcomingReservations: ReservationItem[] = [];

  if (reservations?.length > 0) {
    upcomingReservations = reservations?.filter((reservation) => {
      const selectedDate = format(selectedTimeslot, 'yyyy-MM-dd');

      const { reservationDate, rStatus, rLimitTime } = reservation;

      if (
        selectedDate !== reservationDate ||
        ALLOWED_BOOKING_RESERVATION_STATUS.includes(rStatus)
      )
        return false;

      return isBefore(selectedTimeslot, rLimitTime);
    });
  }

  if (upcomingReservations.length > 0) {
    return orderBy(
      upcomingReservations,
      [(reservation) => reservation?.reservationTime],
      'asc'
    );
  }

  return [];
};

const getLatestSeatedReservation = (
  upcomingReservations: ReservationItem[],
  selectedTimeslot: Date
) => {
  let latestSeatedReservation;
  const sortedByLimitTime = upcomingReservations
    .slice()
    .sort((a, b) => b.rLimitTime.valueOf() - a.rLimitTime.valueOf());

  latestSeatedReservation = sortedByLimitTime.find(
    (reservation) =>
      reservation.statusStr === 'SEATED' &&
      isBefore(selectedTimeslot, reservation.rLimitTime)
  );

  return latestSeatedReservation;
};

const getTableState = (
  nextReservation: ReservationItem,
  selectedTimeslot: Date,
  diningInterval: number
) => {
  const { rLimitTime, rDateTime, statusStr } = nextReservation;
  const futureLimitTime = add(selectedTimeslot, {
    seconds: diningInterval,
  });

  let priorityByState = 1;

  let isClashed =
    isBefore(selectedTimeslot, rDateTime) &&
    isAfter(futureLimitTime, rDateTime);

  let isOccupied =
    isBefore(selectedTimeslot, rLimitTime) &&
    (isAfter(selectedTimeslot, rDateTime) ||
      isEqual(selectedTimeslot, rDateTime));

  const hasUpcoming =
    isBefore(selectedTimeslot, rLimitTime) ||
    isEqual(selectedTimeslot, rLimitTime);

  if (statusStr === 'SEATED') {
    isOccupied = true;
    isClashed = false;
  } else if (isOccupied) {
    isOccupied = false;
    isClashed = true;
  }

  if (isOccupied) priorityByState = 5;
  else if (isClashed) priorityByState = 4;
  else if (hasUpcoming) priorityByState = 2;

  return {
    hasUpcoming,
    isClashed,
    isOccupied,
    priorityByState,
  };
};

export const getProcessedTable = (
  formatDateInView: (
    date: Date | number,
    formats: { en: string; zh?: string }
  ) => string,
  _tables: Table[] | undefined | null,
  paxCount: number,
  diningInterval: number,
  area: Area,
  selectedTimeslot?: undefined | Date,
  isAreaOfSchedule = false
): TableInAssignMenu[] => {
  if (_tables)
    return _tables.map((table: Table) => {
      const isBlocked =
        !table.enabled ||
        table.tableBlocks?.some((b) => {
          if (!selectedTimeslot) {
            return false;
          }
          const endTime = add(selectedTimeslot, { seconds: diningInterval });
          const { start: bStart, end: bEnd } = getBlockStartEndTime(b);
          return bStart < endTime && bEnd > selectedTimeslot;
        }) ||
        area.areaBlocks?.some((b) => {
          if (!selectedTimeslot) {
            return false;
          }
          const endTime = add(selectedTimeslot, { seconds: diningInterval });
          const { start: bStart, end: bEnd } = getBlockStartEndTime(b);
          return bStart < endTime && bEnd > selectedTimeslot;
        }) ||
        false;
      const isBelowMinPax = table.minPax > paxCount;
      const isAboveMaxPax = table.maxPax < paxCount;
      let { reservations } = table;
      let isClashed = false;
      let isOccupied = false;
      let hasUpcoming = false;
      let priorityByState = 1;
      let nextReservation;
      let tableStateComponent: ReactNode = <></>;

      if (selectedTimeslot && reservations) {
        const upcomingReservations = getUpcomingReservations(
          reservations,
          selectedTimeslot
        );

        if (upcomingReservations.length) {
          const latestSeatedReservation = getLatestSeatedReservation(
            upcomingReservations,
            selectedTimeslot
          );

          nextReservation = latestSeatedReservation || upcomingReservations[0];

          if (nextReservation) {
            const { rDateTime, rLimitTime } = nextReservation;
            const tableState = getTableState(
              nextReservation,
              selectedTimeslot,
              diningInterval
            );

            isClashed = tableState.isClashed;
            isOccupied = tableState.isOccupied;
            hasUpcoming = tableState.hasUpcoming;
            priorityByState = tableState.priorityByState;

            const displayReservationTime = isOccupied ? rLimitTime : rDateTime;

            const displayedTime = formatDateInView(displayReservationTime, {
              en: 'h:mm a',
            });

            tableStateComponent = isOccupied ? (
              <Box
                sx={{ color: 'error.dark', mr: '-4px', ml: '-4px' }}
                display="flex"
                alignItems="center"
              >
                <Trans>
                  <BlockIcon sx={{ fontSize: 16, mr: '4px' }} />
                  till {{ displayedTime }}
                </Trans>
              </Box>
            ) : (
              isClashed && (
                <Box
                  display="flex"
                  alignItems="center"
                  sx={{ mr: '-4px', ml: '-4px' }}
                >
                  <Trans>
                    <PeopleOutlineTwoTone sx={{ fontSize: 16, mr: '4px' }} />
                    at {{ displayedTime }}
                  </Trans>
                </Box>
              )
            );
          }
        }
      }

      if (isBlocked) tableStateComponent = <Trans>Blocked</Trans>;

      if ((isBelowMinPax || isAboveMaxPax) && priorityByState < 3)
        priorityByState = 3;

      return {
        ...table,
        isBelowMinPax,
        isAboveMaxPax,
        isBlocked,
        isClashed,
        isOccupied,
        hasUpcoming,
        priorityByState,
        nextReservation,
        tableStateComponent,
        isAreaOfSchedule,
      };
    });

  return [];
};

export const filterPriorityTablesWithSchedule = (
  tables: TableInAssignMenu[]
) => {
  const tablesService: TableInAssignMenu[] = [];
  const tablesNoService: TableInAssignMenu[] = [];
  tables.forEach((table) => {
    table?.isAreaOfSchedule
      ? tablesService.push(table)
      : tablesNoService.push(table);
  });
  return tablesService.concat(tablesNoService);
};

const handleFilterDuplicateError = (
  errors: TableError[],
  type?: string
): TableError[] | TableError | [] | undefined => {
  if (!errors?.length) return [];
  if (errors?.length === 1) return errors[0];

  return errors.reduce((acc, cur) => {
    const isExistedError = acc?.some((item: TableError) => {
      if (type === 'outsideAreasSchedule') return item?.value === cur?.value;

      return isEqual(item?.value as Date, cur?.value as Date);
    });
    if (isExistedError) return acc;

    return ([] as TableError[]).concat(acc).concat(cur);
  }, [] as TableError[]);
};

export const handleFilterTableErrors = (tableErrors: TableError[]) => {
  const listMaxPax = tableErrors?.filter(
    (item) => item?.source === 'maxPax'
  ) as TableError[];
  const listMinPax = tableErrors?.filter(
    (item) => item?.source === 'minPax'
  ) as TableError[];
  const listClashed = tableErrors?.filter((item) => item?.source === 'clashed');
  const listOccupied = tableErrors?.filter(
    (item) => item?.source === 'occupied'
  );
  const listOutsideAreasSchedule = tableErrors?.filter(
    (item) => item?.source === 'outsideAreasSchedule'
  );
  const listBlocked = tableErrors?.filter((item) => item?.source === 'blocked');

  return ([] as TableError[])
    .concat(handleFilterDuplicateError(listMaxPax, 'maxPax') as TableError[])
    .concat(handleFilterDuplicateError(listMinPax, 'minPax') as TableError[])
    .concat(listBlocked?.[0] as TableError)
    .concat(handleFilterDuplicateError(listClashed, 'clashed') as TableError[])
    .concat(
      handleFilterDuplicateError(listOccupied, 'occupied') as TableError[]
    )
    .concat(
      handleFilterDuplicateError(
        listOutsideAreasSchedule,
        'outsideAreasSchedule'
      ) as TableError[]
    )
    .filter(Boolean);
};

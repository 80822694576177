import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import { Trans, useTranslation } from 'next-i18next';
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone';
import CustomerReservationInfor from '../reservation/components/customer-reservation-infor';
import PaymentStatusBox from './components/payment-status';
import { formatCurrency } from '@/common/lib/money';
import { TransactionHistory } from '@/common/types/transaction';
import {
  ticketColor,
  TICKET_TYPE_PAYMENT,
  PRTicketTypePaymentInt,
} from '@/common/types/ticket.base';
import { useReservation } from '@/common/hooks/use-reservations';
import ReservationStatusBtn from '@/common/components/reservation-status-btn';
import { TouchTooltip } from '@/common/components/tooltip';
import { toNumber } from 'lodash-es';
import useLocaleCfg from '@/common/hooks/use-locale-cfg';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { isPRTicket } from '@/common/types/ticket';
import { Column, useTable } from 'react-table';
import StickyTable from '@/common/components/sticky-table';
import { useTransactionHistory } from '@/common/hooks/use-transactions';
import LoadingScreen from '@/common/components/loading-screen';
import useTransactionUtils, {
  TYPE_CHARGE_WINDOW,
} from '@/feat/transaction/util';
import useChangeReservationAction from '@/common/hooks/use-change-reservation-action';
import { useEffect } from 'react';
import { parseISO } from 'date-fns';
import RestaurantSourceIcon from '@/common/components/restaurant-src-icon';
import { css } from '@emotion/react';
import { titleSource } from '@/feat/reservation/components/view-reservation-modal/common';
import { usePwaOrMobileView } from '@/common/hooks/use-pwa';

interface Props {
  onClose: () => void;
  isOpen: boolean;
  reservationId: string;
  reloadTransaction?: () => void;
}

export default function TransactionDetail(props: Props) {
  const { onClose, reservationId, isOpen, reloadTransaction } = props;
  const { t } = useTranslation();
  const locale = useLocaleCfg();
  const { formatDate } = useI18nTimeUtils();
  const theme = useTheme();
  const {
    isRefundableTransaction,
    isRefundableReservation,
    getTransactionPaymentStatus,
    generateStatusLabel,
    getChargeType,
  } = useTransactionUtils();
  const {
    data: reservation,
    isLoading: isLoadingReservation,
    refetch: refetchReservation,
  } = useReservation({
    reservationId,
  });
  const {
    data: transactionHistory,
    isLoading: isLoadingTransactionHistory,
    refetch: refetchTransactionHistory,
  } = useTransactionHistory({
    reservationId,
  });
  const { changeReservationAction, isLoadingChangeReservation } =
    useChangeReservationAction(reservation, () => {
      refetchReservation();
      refetchTransactionHistory();
      reloadTransaction && reloadTransaction();
    });

  const typeCharge = getChargeType(reservation);
  const status = reservation?.transaction?.status;
  const statusRefund = reservation?.transaction?.statusRefund;
  const type = reservation?.transaction?.type;
  const paymentType = reservation?.reservationPaymentRequest?.type;
  const transactionPaymentStatus = getTransactionPaymentStatus(
    status,
    type || null,
    statusRefund,
    reservation?.transaction?.source
  );
  const { isMobileView } = usePwaOrMobileView();

  useEffect(() => {
    refetchReservation();
    refetchTransactionHistory();
  }, [isOpen, refetchReservation, refetchTransactionHistory]);

  const labelTicketPayment = React.useMemo(() => {
    const ticket = reservation?.ticket;
    if (!isPRTicket(ticket)) return '';

    const labels: Record<PRTicketTypePaymentInt, string> = {
      [TICKET_TYPE_PAYMENT['Deposit']]: t('Deposit'),
      [TICKET_TYPE_PAYMENT['Prepaid']]: t('Prepaid'),
      [TICKET_TYPE_PAYMENT['CardGuarantee']]: t('Card Guarantee'),
    };
    return labels[ticket.typePayment] || '';
  }, [reservation?.ticket, t]);

  const columns = React.useMemo<Column<TransactionHistory>[]>(
    () => [
      {
        id: 'col:date',
        accessor: 'createdTimestamp',
        width: '48px',
        Header: () => (
          <Typography variant={'captionSemibold'}>{t('Date')}</Typography>
        ),
        Cell: ({ row }) => (
          <span>
            {formatDate(parseISO(row.original.createdTimestamp), {
              en: 'dd MMM yyyy',
              zh: 'yyyy MMM do',
            })}
          </span>
        ),
      },
      {
        id: 'col:time',
        accessor: 'createdTimestamp',
        width: '48px',
        Header: () => (
          <Typography variant={'captionSemibold'}>{t('Time')}</Typography>
        ),
        Cell: ({ row }) => (
          <span>
            {formatDate(parseISO(row.original.createdTimestamp), {
              en: 'hh:mm a',
              zh: 'hh:mm a',
            })}
          </span>
        ),
      },
      {
        id: 'col:status',
        accessor: 'status',
        width: '64px',
        Header: () => (
          <Typography variant={'captionSemibold'}>
            {t('Payment Status')}
          </Typography>
        ),
        Cell: ({ row }) => {
          const status = getTransactionPaymentStatus(
            row.original.status,
            'create',
            null,
            row.original.source
          );
          return <span>{generateStatusLabel(status)}</span>;
        },
      },
      {
        id: 'col:amount',
        accessor: 'amount',
        width: '64px',
        Header: () => (
          <Typography variant={'captionSemibold'}>
            {t('Total Amount')}
          </Typography>
        ),
        Cell: ({ row }) => <span>{row.original.amount}</span>,
      },
      {
        id: 'col:source',
        accessor: 'source',
        width: '64px',
        Header: () => (
          <Typography variant={'captionSemibold'}>{t('Done by')}</Typography>
        ),
        Cell: ({ row }) => {
          const DEFAULT_DONE_BY = 'Customer';
          return (
            <span>
              {row.original.source ? row.original.source : DEFAULT_DONE_BY}
            </span>
          );
        },
      },
      {
        id: 'col:reason',
        accessor: 'reason',
        width: '64px',
        Header: () => (
          <Typography variant={'captionSemibold'}>{t('Reason')}</Typography>
        ),
        Cell: ({ row }) => <span>{row.original.reason}</span>,
      },
    ],
    [formatDate, generateStatusLabel, getTransactionPaymentStatus, t]
  );

  const transactionHistoryTable = useTable({
    data: transactionHistory || [],
    columns,
  });

  const refundDescription = () => {
    if (!reservation || !reservation.transaction) return;
    const refundWindowMinutes = Number(reservation.ticket?.refundWindow) || 0;
    if (
      reservation.ticket?.typePayment === TICKET_TYPE_PAYMENT['No Payment'] ||
      refundWindowMinutes === 0
    )
      return <Trans>Non-refundable</Trans>;
    const refundWindowHours = refundWindowMinutes / 60;
    const refundWindowDay = refundWindowHours / 24;
    const refundWindowWeek = refundWindowDay / 7;
    const isRefundReservation = isRefundableReservation(reservation);

    if (reservation.ticket?.typeRefundWindow === 1) {
      if (refundWindowMinutes < 60) {
        return isRefundReservation ? (
          <Trans>Within the {{ refundWindowMinutes }} min refund window</Trans>
        ) : (
          <Trans>
            Not within the {{ refundWindowMinutes }} min refund window
          </Trans>
        );
      }
      if (refundWindowHours === 1) {
        return isRefundReservation ? (
          <Trans>Within the 1 hour refund window</Trans>
        ) : (
          <Trans>Not within the 1 hour refund window</Trans>
        );
      }
      if (refundWindowHours > 1) {
        return isRefundReservation ? (
          <Trans>Within the {{ refundWindowHours }} hours refund window</Trans>
        ) : (
          <Trans>
            Not within the {{ refundWindowHours }} hours refund window
          </Trans>
        );
      }
    }

    if (reservation.ticket?.typeRefundWindow === 2) {
      if (refundWindowDay === 1) {
        return isRefundReservation ? (
          <Trans>Within the 1 day refund window.</Trans>
        ) : (
          <Trans>Not within the 1 day refund window.</Trans>
        );
      }
      if (refundWindowDay > 1 && refundWindowDay < 7) {
        return isRefundReservation ? (
          <Trans>Within the {{ refundWindowDay }} days refund window.</Trans>
        ) : (
          <Trans>
            Not within the {{ refundWindowDay }} days refund window.
          </Trans>
        );
      }
      if (refundWindowWeek === 1) {
        return isRefundReservation ? (
          <Trans>Within the 1 week refund window.</Trans>
        ) : (
          <Trans>Not within the 1 week refund window.</Trans>
        );
      }
      if (refundWindowWeek > 1) {
        return isRefundReservation ? (
          <Trans>Within the {{ refundWindowWeek }} weeks refund window.</Trans>
        ) : (
          <Trans>
            Not within the {{ refundWindowWeek }} weeks refund window.
          </Trans>
        );
      }
    }
  };

  const generateAmountLabel = () => {
    switch (transactionPaymentStatus) {
      case 'Guaranteed':
        return <Trans>AMOUNT GUARANTEED</Trans>;
      case 'Manual Charge':
      case 'System Charge':
        return <Trans>AMOUNT CHARGED</Trans>;
      case 'Manual No Charge':
      case 'System No Charge':
        return <Trans>AMOUNT NOT CHARGED</Trans>;
      default:
        return <Trans>AMOUNT PAID</Trans>;
    }
  };

  const generateDateAndTimeLabel = () => {
    switch (transactionPaymentStatus) {
      case 'Guaranteed':
        return <Trans>DATE AND TIME GUARANTEED</Trans>;
      case 'Manual Charge':
      case 'System Charge':
        return <Trans>DATE AND TIME CHARGED</Trans>;
      case 'Manual No Charge':
      case 'System No Charge':
        return <Trans>DATE AND TIME NOT CHARGED</Trans>;
      default:
        return <Trans>DATE AND TIME PAID</Trans>;
    }
  };

  const generatePaymentLabel = () => {
    switch (transactionPaymentStatus) {
      case 'Guaranteed':
        return <Trans>GUARANTEED VIA</Trans>;
      default:
        return <Trans>PAYMENT VIA</Trans>;
    }
  };

  const generatePaymentStatus = () => {
    switch (paymentType) {
      case 'OTHER_METHOD':
        return <Trans>Manual Payment</Trans>;
      default:
        return <Trans>Online Payment</Trans>;
    }
  };

  const generateActionOrRefundSection = () => {
    if (
      transactionPaymentStatus === 'Guaranteed' &&
      typeCharge === TYPE_CHARGE_WINDOW.DURING
    ) {
      return (
        <>
          <Box sx={{ paddingBottom: '14px' }}>
            <Typography
              variant="overline"
              sx={{ color: theme.palette.grey[500] }}
            >
              <Trans>Action</Trans>
            </Typography>
          </Box>
          <Button
            variant="outlined"
            color="success"
            size="medium"
            sx={{ marginRight: '16px' }}
            disabled={isLoadingChangeReservation}
            onClick={() => changeReservationAction('CHARGE')}
          >
            {t('Charge')}
          </Button>
          <Button
            variant="outlined"
            color="error"
            size="medium"
            disabled={isLoadingChangeReservation}
            onClick={() => changeReservationAction('NOT_CHARGE')}
          >
            {t('No Charge')}
          </Button>
        </>
      );
    }
    if (
      reservation?.transaction &&
      isRefundableTransaction(transactionPaymentStatus) &&
      paymentType !== 'OTHER_METHOD'
    ) {
      return (
        <>
          <Box sx={{ paddingBottom: '14px' }}>
            <Typography
              variant="overline"
              sx={{ color: theme.palette.grey[500] }}
            >
              <Trans>Action</Trans>
            </Typography>
          </Box>
          <Button
            variant="outlined"
            color="error"
            size="medium"
            disabled={isLoadingChangeReservation}
            onClick={() => changeReservationAction('REFUND')}
          >
            {t('Refund')}
          </Button>
          {transactionPaymentStatus === 'System Charge' && (
            <Typography variant="body2" sx={{ paddingTop: '12px' }}>
              {refundDescription()}
              <TouchTooltip
                title={
                  <Trans>Refund window is based on your ticket settings</Trans>
                }
                placement="right"
                arrow
              >
                <HelpTwoToneIcon
                  sx={{
                    fontSize: 16,
                    color: theme.palette.grey[600],
                    margin: '0 0 -3px 4px',
                  }}
                />
              </TouchTooltip>
            </Typography>
          )}
        </>
      );
    }
  };

  const generateReasonSection = () => {
    switch (transactionPaymentStatus) {
      case 'Refunded':
        return (
          <>
            {reservation?.transaction?.refundReason && (
              <Box sx={{ paddingBottom: '24px' }}>
                <Typography
                  variant="overline"
                  sx={{ color: theme.palette.grey[500] }}
                >
                  <Trans>INTERNAL REFUND REASON</Trans>
                </Typography>
                <Typography variant="body2" sx={{ paddingTop: '12px' }}>
                  <>{reservation?.transaction?.refundReason}</>
                </Typography>
              </Box>
            )}
            {reservation?.transaction?.refundMessage && (
              <Box sx={{ paddingBottom: '14px' }}>
                <Typography
                  variant="overline"
                  sx={{ color: theme.palette.grey[500] }}
                >
                  <Trans>REFUND MESSAGE SENT VIA EMAIL</Trans>
                </Typography>
                <Typography variant="body2" sx={{ paddingTop: '12px' }}>
                  {reservation?.transaction?.refundMessage}
                </Typography>
              </Box>
            )}
          </>
        );
      case 'Manual Charge':
      case 'Manual No Charge':
      case 'System No Charge':
        return (
          <>
            {!!reservation!.transaction?.reason && (
              <Box sx={{ paddingBottom: '24px' }}>
                <Typography
                  variant="overline"
                  sx={{ color: theme.palette.grey[500] }}
                >
                  {transactionPaymentStatus === 'Manual Charge' ? (
                    <Trans>INTERNAL CHARGE REASON</Trans>
                  ) : (
                    <Trans>INTERNAL NO CHARGE REASON</Trans>
                  )}
                </Typography>
                <Typography variant="body2" sx={{ paddingTop: '12px' }}>
                  {reservation!.transaction?.reason}
                </Typography>
              </Box>
            )}
            {!!reservation!.transaction?.messageForCustomer && (
              <Box sx={{ paddingBottom: '24px' }}>
                <Typography
                  variant="overline"
                  sx={{ color: theme.palette.grey[500] }}
                >
                  {transactionPaymentStatus === 'Manual Charge' ? (
                    <Trans>CHARGE MESSAGE SENT VIA EMAIL</Trans>
                  ) : (
                    <Trans>NO CHARGE MESSAGE SENT VIA EMAIL</Trans>
                  )}
                </Typography>
                <Typography variant="body2" sx={{ paddingTop: '12px' }}>
                  {reservation!.transaction?.messageForCustomer}
                </Typography>
              </Box>
            )}
          </>
        );
      default:
        return null;
    }
  };

  const list = reservation && (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '18px 16px',
          boxShadow: theme.customShadows.card,
          maxHeight: 64,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: '600' }}>
          <Trans>Transaction Details</Trans>
        </Typography>
        <Box onClick={onClose}>
          <CloseIcon sx={{ fontSize: 24, cursor: 'pointer' }} />
        </Box>
      </Box>
      <Box
        sx={{
          padding: '16px 24px',
          height: 'calc(100vh - 64px)',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            borderRadius: '16px',
            padding: '24px',
            boxShadow: theme.customShadows.card,
          }}
        >
          <Typography variant="h6">
            <Trans>Transaction Details</Trans>
          </Typography>

          <Grid container spacing={1} sx={{ paddingTop: '24px' }}>
            <Grid item xs={6}>
              <Typography
                variant="overline"
                sx={{ color: theme.palette.grey[500] }}
              >
                <Trans>PAYMENT STATUS</Trans>
              </Typography>
              <Typography variant="body2" sx={{ paddingTop: '12px' }}>
                {!!reservation.transaction && (
                  <PaymentStatusBox
                    original={reservation.transaction}
                    hideTime
                  />
                )}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="overline"
                sx={{ color: theme.palette.grey[500] }}
              >
                <Trans>Transaction id</Trans>
              </Typography>
              <Typography variant="body2" sx={{ paddingTop: '12px' }}>
                <>{reservation.transaction?.transactionGenerateId}</>
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="overline"
                sx={{ color: theme.palette.grey[500] }}
              >
                {generateAmountLabel()}
              </Typography>
              {reservation.transaction?.amount && (
                <Typography variant="body2" sx={{ paddingTop: '12px' }}>
                  {formatCurrency(
                    toNumber(reservation.transaction?.amount || 0),
                    locale
                  )}
                </Typography>
              )}
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="overline"
                sx={{ color: theme.palette.grey[500] }}
              >
                {generateDateAndTimeLabel()}
              </Typography>
              <Typography variant="body2" sx={{ paddingTop: '12px' }}>
                {reservation.transaction?.createdTimestamp &&
                  formatDate(
                    parseISO(reservation.transaction.createdTimestamp),
                    {
                      en: 'dd MMM yyyy, hh:mm a',
                      zh: 'yyyy MMM do, hh:mm a',
                    }
                  )}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <Typography
                variant="overline"
                sx={{
                  color: theme.palette.grey[500],
                }}
              >
                {generatePaymentLabel()}
              </Typography>
              <Typography variant="body2" sx={{ paddingTop: '12px' }}>
                {generatePaymentStatus()}
              </Typography>
            </Grid>

            <Grid item xs={6}>
              {generateActionOrRefundSection()}
            </Grid>
          </Grid>

          {generateReasonSection()}
        </Box>
        <Box
          sx={{
            paddingTop: '24px',
            display: 'flex',
            flexDirection: isMobileView ? 'column' : 'row',
          }}
        >
          <Box
            sx={{
              width: isMobileView ? '100%' : '55%',
              marginRight: '24px',
              mb: '24px',
              padding: '24px',
              borderRadius: '16px',
              boxShadow: theme.customShadows.card,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h6">
                <Trans>Reservation Details</Trans>
              </Typography>
              <Box sx={{ display: 'flex' }}>
                {reservation.source && (
                  <RestaurantSourceIcon source={reservation.source} />
                )}
                <Box
                  css={css`
                    display: flex;
                    flex-direction: column;
                    margin-left: 9px;
                  `}
                >
                  {reservation.source && (
                    <Typography variant="body2">
                      {titleSource[reservation.source]}
                    </Typography>
                  )}
                  <Typography variant="caption" sx={{ color: '#637381' }}>
                    #{reservation.code}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ padding: '24px 0px', display: 'flex' }}>
              <Box
                sx={{
                  width: '11px',
                  height: '11px',
                  borderRadius: '50%',
                  marginTop: '7px',
                  background: ticketColor(reservation.ticket?.color).swatch,
                }}
              />
              <Box sx={{ marginLeft: '8px', width: '80%' }}>
                <Typography variant="subtitle1">
                  {reservation.ticket?.name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: theme.palette.grey[600], paddingTop: '8px' }}
                >
                  {reservation.ticket?.price &&
                    formatCurrency(reservation.ticket?.price || 0, locale)}{' '}
                  {labelTicketPayment}
                </Typography>
              </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                padding: '24px 0px',
                justifyContent: 'space-between',
                flexDirection: isMobileView ? 'column' : 'row',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  '& .MuiButton-root': {
                    maxWidth: 120,
                    minWidth: 120,
                  },
                  mb: 2,
                  width: isMobileView ? '100%' : '25%',
                }}
              >
                <ReservationStatusBtn reservationItem={reservation} />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: isMobileView ? '100%' : '70%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    maxWidth: '150px',
                  }}
                >
                  <Typography
                    variant="overline"
                    sx={{
                      color: theme.palette.grey[500],
                    }}
                  >
                    <Trans>Date and Time</Trans>
                  </Typography>
                  <Typography variant="body2">
                    {reservation.rTimeState === 'TODAY' && (
                      <Trans>Today, </Trans>
                    )}
                    {formatDate(reservation.rDateTime, {
                      en: 'dd MMM yyyy, hh:mm a',
                      zh: 'yyyy MMM do, hh:mm a',
                    })}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'stretch',
                  }}
                >
                  <Typography
                    variant="overline"
                    sx={{
                      color: theme.palette.grey[500],
                    }}
                  >
                    <Trans>PAX</Trans>
                  </Typography>
                  <Typography variant="body2">{reservation.pax}</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignSelf: 'stretch',
                  }}
                >
                  <Typography
                    variant="overline"
                    sx={{
                      color: theme.palette.grey[500],
                    }}
                  >
                    <Trans>Table NO.</Trans>
                  </Typography>
                  <Typography variant="body2">
                    {reservation.tables?.[0]?.name &&
                      reservation.tables?.[0]?.name}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: isMobileView ? '100%' : '43%' }}>
            {reservation.user?.mcaId && (
              <CustomerReservationInfor
                customerAtTimeOfReservation={reservation.user}
                action="SEE_DETAIL"
                idCustomerToSee={reservation.user?.mcaId}
                changeCustomerProvided={false}
              />
            )}
          </Box>
        </Box>
        {isLoadingTransactionHistory ? (
          <LoadingScreen sx={{ height: '200px' }} />
        ) : (
          <Box
            sx={{
              borderRadius: '16px',
              padding: '24px',
              marginTop: '24px',
              boxShadow: theme.customShadows.card,
            }}
          >
            <Typography variant="h6">
              <Trans>Transaction History</Trans>
            </Typography>
            <Box
              sx={{
                marginTop: '24px',
              }}
            >
              <StickyTable table={transactionHistoryTable} overflow />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );

  return (
    <Drawer
      anchor={'right'}
      open={isOpen}
      onClose={() => onClose()}
      sx={{
        '& .MuiPaper-root': {
          width: isMobileView ? '100%' : '80%',
        },
      }}
    >
      {isLoadingReservation || isLoadingTransactionHistory ? (
        <LoadingScreen />
      ) : (
        list
      )}
    </Drawer>
  );
}

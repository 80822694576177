import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useMemo } from 'react';
import { z } from 'zod';
import {
  TableDashboardLayout,
  ZTableDashboardLayout,
} from '../types/table-in-dashboard-layout';
import useV2Api from './use-v2-api';

const DASHBOARD_LAYOUT_ENDPOINT = '/layouts/dashboard-layout';

export const useTablesLayout = (args: {
  date: string;
  config?: UseQueryOptions<
    TableDashboardLayout,
    Error,
    TableDashboardLayout,
    Array<any>
  >;
}) => {
  const v2Api = useV2Api();
  const { date } = args;
  const fetch = useMemo(
    () =>
      z
        .function()
        .args(z.string())
        .implement(async (params) => {
          if (!params) throw Error('invalid date');
          const { data } = await v2Api.get(DASHBOARD_LAYOUT_ENDPOINT, {
            params: { date: params },
          });

          return ZTableDashboardLayout.parse(
            (data as TableDashboardLayout)?.filter((i) => !!i.tableId)
          );
        }),
    [v2Api]
  );

  return useQuery(['get-tables-available', date], () => fetch(date), {
    enabled: !!date,
    ...args?.config,
  });
};

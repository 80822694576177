import React from 'react';

const WalkInSvg = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="8" cy="8" r="8" fill="#F4F6F8" />
      <circle cx="8" cy="8" r="7.5" stroke="#919EAB" strokeOpacity="0.32" />
      <path
        d="M8.75 4.75C9.3 4.75 9.75 4.3 9.75 3.75C9.75 3.2 9.3 2.75 8.75 2.75C8.2 2.75 7.75 3.2 7.75 3.75C7.75 4.3 8.2 4.75 8.75 4.75ZM6.9 6.45L5.5 13.5H6.55L7.45 9.5L8.5 10.5V13.5H9.5V9.75L8.45 8.75L8.75 7.25C9.4 8 10.4 8.5 11.5 8.5V7.5C10.55 7.5 9.75 7 9.35 6.3L8.85 5.5C8.57 5.055 8.01 4.875 7.525 5.08L5 6.15V8.5H6V6.8L6.9 6.45Z"
        fill="#3D0099"
      />
    </svg>
  );
};

export default WalkInSvg;

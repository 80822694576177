import Box from '@mui/material/Box';
import { useTranslation } from 'next-i18next';
import { memo } from 'react';
import {
  StepLabel,
  Step,
  Stepper,
  StepIconProps,
  useTheme,
} from '@mui/material';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material';
import { ReservationType } from '.';

type HorizontalStepperProps = {
  reservationType: ReservationType;
  isTicketPrice: boolean;
  activeStepPWA: number;
};

const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: theme.palette.primary.main,
    }),
    '& .QontoStepIcon-completedIcon': {
      color: theme.palette.primary.main,
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  })
);

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));
function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;
  const theme = useTheme();

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <CheckTwoToneIcon
          className="QontoStepIcon-completedIcon"
          sx={{ fontWeight: 'bold' }}
        />
      ) : (
        <div
          className="QontoStepIcon-circle"
          style={{
            backgroundColor: active
              ? theme.palette.primary.main
              : theme.palette.grey[500],
          }}
        />
      )}
    </QontoStepIconRoot>
  );
}
function HorizontalLabelStepper({
  reservationType,
  isTicketPrice,
  activeStepPWA,
}: HorizontalStepperProps) {
  const { t } = useTranslation();

  const isWalkIn = reservationType === 'WalkIn';
  const steps = [t('Reservation'), t('Customer'), t('Payment')];

  const getStepLabel = (index: number) => {
    if (index === 0 && isWalkIn) {
      return t('Walk-In');
    }
    if ((index === 2 && !isTicketPrice) || (index === 2 && isWalkIn)) {
      return null;
    }
    return steps[index];
  };

  return (
    <>
      <Box sx={{ width: '100%', mt: 4 }}>
        <Stepper
          alternativeLabel
          activeStep={activeStepPWA}
          connector={<QontoConnector />}
        >
          {steps.map((_, index) => {
            const label = getStepLabel(index);
            if (!label) {
              return null;
            }
            return (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    </>
  );
}

export default memo(HorizontalLabelStepper);

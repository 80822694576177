import React, { useEffect, useMemo, useState } from 'react';
import { FieldValues, SubmitHandler, Controller } from 'react-hook-form';
import {
  Box,
  Checkbox,
  FormHelperText,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import Select from '@/common/components/select';
import PhoneInput from '@/common/components/phone-input';
import { isValidPhoneNumber } from '@/common/lib/phone-number';
import DatePicker from '@/common/components/date-picker';
import WarningIcon from '@mui/icons-material/Warning';
import { format } from 'date-fns';
import { useTranslation, Trans } from 'next-i18next';
import { z } from 'zod';
import {
  CreateCustomerPayload,
  useAddCustomer,
  useCustomers,
  useCustomerNameFmt,
} from '@/common/hooks/use-customers';
import { Customer } from '@/common/types/customer';
import { useReservationActionContext } from '../../context';
import { usePwaOrMobileView } from '@/common/hooks/use-pwa';
import { TICKET_TYPE_PAYMENT } from '@/common/types/ticket.base';

interface AddCustomerProps {
  brandName: string;
  suggestedName?: string;
  suggestedPhone?: string;
  suggestedEmail?: string;
  selectCustomer: (idCustomer: string) => void;
  isRequireEmailForOnlinePayment?: boolean;
}

const StyleForm = styled('form')({
  marginTop: '24px',
});

const ControllerBox = styled(Box)({
  marginBottom: '24px',
});

const StyleDatePicker = styled(DatePicker)({
  width: '100%',
});

const AddCustomer: React.FC<AddCustomerProps> = ({
  brandName,
  suggestedName = '',
  suggestedPhone = '',
  suggestedEmail = '',
  selectCustomer,
  isRequireEmailForOnlinePayment,
}) => {
  const { customerForm, reservationForm } = useReservationActionContext();
  const {
    handleSubmit,
    formState: { errors, isSubmitting },
    control,
    reset,
    watch,
    setValue,
  } = customerForm;
  const { t } = useTranslation();
  const watchEmail = watch('email', '');
  const findCustomerQuery = useCustomers({
    searchText: watchEmail,
    options: {
      refetchInterval: 3600000,
      enabled: !watchEmail,
    },
  });
  const { mutateAsync: addCustomer } = useAddCustomer();
  const customerNameFmt = useCustomerNameFmt();
  const [duplicatedUser, setDuplicatedUser] = useState<Customer | null>(null);

  const paymentType = reservationForm.watch('paymentType');
  const isTicketPaid =
    reservationForm.watch('ticket')?.typePayment !==
    TICKET_TYPE_PAYMENT['No Payment'];
  const isOnlineMethodPayment = paymentType === 'ONLINE_PAYMENT_LINK';

  const isCustomerInforRequired = useMemo(() => {
    return isTicketPaid && isOnlineMethodPayment;
  }, [isOnlineMethodPayment, isTicketPaid]);

  const salutations = [
    { value: 'Mr', label: t('Mr') },
    { value: 'Ms', label: t('Ms') },
    { value: 'Mrs', label: t('Mrs') },
    { value: 'Dr', label: t('Dr') },
    { value: 'N/A', label: t('N/A') },
  ];

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const customer: CreateCustomerPayload = {
      firstName: data.firstName,
      lastName: data.lastName,
      ...(!!data.salutation && {
        salutation: data.salutation,
      }),
      phone: '+' + data.phoneNumber,
      additionalPhone: undefined,
      organisationName: undefined,
      canSendEmailMarketing: data.receivePromotions,
      email: data.email || null,
      notesDiner: data.customerNotes,
      ...(!!data.birthday && {
        birthday: format(data.birthday, 'yyyy-MM-dd'),
      }),
    };

    const resCustomerData = await addCustomer(customer);
    reset();
    selectCustomer(resCustomerData?.mcaId || '');
  };

  const handleUsingDuplicatedCustomer = () => {
    selectCustomer(duplicatedUser?.id || '');
  };

  useEffect(() => {
    setValue('email', suggestedEmail);
    setValue('firstName', suggestedName);
    setValue('phoneNumber', suggestedPhone);
    setValue('salutation', 'Mr');
  }, [setValue, suggestedEmail, suggestedName, suggestedPhone]);

  const { isMobileView } = usePwaOrMobileView();

  return (
    <StyleForm onSubmit={handleSubmit(onSubmit)}>
      <ControllerBox sx={{ width: isMobileView ? '100%' : '200px' }}>
        <Controller
          control={control}
          name="salutation"
          render={({ field }) => (
            <Select
              {...field}
              label={t('Salutation')}
              options={salutations}
              disabled={isSubmitting}
            />
          )}
        />
      </ControllerBox>
      <ControllerBox>
        <Controller
          control={control}
          name="firstName"
          render={({ field }) => (
            <>
              <TextField
                {...field}
                label={t('First Name (Required)')}
                sx={{ width: '100%' }}
                error={!!errors.firstName}
                disabled={isSubmitting}
              />
              {!!errors.firstName && (
                <FormHelperText error>
                  {t('First name is required')}
                </FormHelperText>
              )}
            </>
          )}
          rules={{ required: true }}
        />
      </ControllerBox>
      <ControllerBox>
        <Controller
          control={control}
          name="lastName"
          render={({ field }) => (
            <>
              <TextField
                {...field}
                label={
                  isCustomerInforRequired
                    ? t('Last Name (Required)')
                    : t('Last Name')
                }
                sx={{ width: '100%' }}
                error={!!errors.lastName}
                disabled={isSubmitting}
              />
              {!!errors.lastName && (
                <FormHelperText error>
                  {t('Last Name is required to send an online payment link')}
                </FormHelperText>
              )}
            </>
          )}
          rules={{ required: isCustomerInforRequired }}
        />
      </ControllerBox>
      <ControllerBox>
        <Controller
          control={control}
          name="phoneNumber"
          render={({ field: { ref, ...restField } }) => (
            <PhoneInput
              {...restField}
              inputProps={{ ref }}
              label={t('Phone Number (Required)')}
              error={
                !!errors.phoneNumber
                  ? t('Phone number seems incorrect')
                  : undefined
              }
              disabled={isSubmitting}
            />
          )}
          rules={{
            validate: (value: string | undefined) =>
              isValidPhoneNumber(value || ''),
          }}
        />
      </ControllerBox>
      <ControllerBox>
        <Controller
          control={control}
          name="birthday"
          render={({ field: { ref, ...restField } }) => (
            <StyleDatePicker
              {...restField}
              inputRef={ref}
              label={t('Birthday')}
              placeholder="dd/mm/yyyy"
              inputFormat="dd/MM/yyyy"
              disabled={isSubmitting}
            />
          )}
        />
      </ControllerBox>
      <ControllerBox>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <>
              <TextField
                {...field}
                label={
                  isCustomerInforRequired
                    ? t('Email Address (Required)')
                    : t('Email Address')
                }
                sx={{ width: '100%' }}
                error={!!errors.email}
                disabled={isSubmitting}
              />
              {errors.email?.type === 'isEmail' && (
                <FormHelperText error>
                  {t('Email seems incorrect')}
                </FormHelperText>
              )}
              {errors.email?.type === 'isNotDuplicated' && (
                <FormHelperText error>
                  {t('{{customer}} is an existing customer', {
                    customer: field.value,
                  })}
                </FormHelperText>
              )}
              {errors.email?.type === 'required' && (
                <FormHelperText error>
                  {t('Email is required to send an online payment link')}
                </FormHelperText>
              )}
            </>
          )}
          rules={{
            required: isCustomerInforRequired,
            validate: {
              isEmail: (value: string | undefined) => {
                if (!value) {
                  return true;
                }
                return z.string().email().safeParse(value).success;
              },
              isRequireEmailForOnlinePayment: (value: string | undefined) => {
                if (isRequireEmailForOnlinePayment && !value) {
                  return false;
                }
                return true;
              },
              isNotDuplicated: async (value) => {
                if (!value) {
                  return true;
                }
                try {
                  const query = await findCustomerQuery.refetch();
                  const foundUsers = (query.data?.pages || []).flatMap(
                    ({ data }) => data
                  );
                  if (foundUsers?.[0]) {
                    setDuplicatedUser(foundUsers[0]);
                    return false;
                  } else {
                    setDuplicatedUser(null);
                    return true;
                  }
                } catch (e) {
                  // In case calling duplicate check failed
                  // Bypass the error and it will be re-checked when submit
                  console.warn(e);
                  setDuplicatedUser(null);
                  return true;
                }
              },
            },
          }}
        />
      </ControllerBox>
      <ControllerBox sx={{ display: 'flex', alignItems: 'center' }}>
        <Controller
          control={control}
          name="receivePromotions"
          render={({ field }) => (
            <>
              <Checkbox
                {...field}
                sx={{ width: '18px', height: '18px', marginRight: '11px' }}
                disabled={isSubmitting}
              />
              <span>
                <Trans>Receive marketing promotions from {{ brandName }}</Trans>
              </span>
            </>
          )}
        />
      </ControllerBox>
      {errors.email?.type === 'isNotDuplicated' && (
        <Box
          sx={{
            background: '#FFF2CC',
            borderRadius: '8px',
            padding: '16.5px 14px',
            marginBottom: '24px',
            display: 'flex',
          }}
        >
          <WarningIcon
            fontSize="medium"
            sx={{ color: '#FF9502', marginRight: '15px' }}
          />
          <Box>
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '24px',
                color: '#B75B01',
                marginBottom: '4px',
              }}
            >
              {t('Existing Customer')}
            </Typography>
            <Box
              sx={{
                fontSize: '14px',
                lineHeight: '22px',
                color: '#7A3200',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={handleUsingDuplicatedCustomer}
            >
              {t('Use {{duplicatedUserName}} profile instead', {
                duplicatedUserName: duplicatedUser
                  ? customerNameFmt(duplicatedUser)
                  : '',
              })}
            </Box>
          </Box>
        </Box>
      )}
      <ControllerBox>
        <Controller
          control={control}
          name="customerNotes"
          render={({ field }) => (
            <TextField
              {...field}
              label={t('Customer Notes')}
              multiline
              rows={4}
              sx={{ width: '100%' }}
              disabled={isSubmitting}
            />
          )}
        />
      </ControllerBox>
    </StyleForm>
  );
};

export default AddCustomer;

import { Box, CardProps, Typography, Divider } from '@mui/material';
import React from 'react';
import { Controller } from 'react-hook-form';
import {
  ActionReservationType,
  useReservationActionContext,
} from '../../context';
import MenuItem from '@mui/material/MenuItem';
import { Trans, useTranslation } from 'next-i18next';
import { PaymentRequestEditProps } from './type';
import Button from '@mui/material/Button';
import PaymentAlert from '@/common/components/payment-aleft';
import Menu, { MenuProps } from '@mui/material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Chip } from '@mui/material';
import { PaymentStatusResponse } from '@/common/types/transaction.base';
import { styled, alpha } from '@mui/material';
import useTransactionUtils from '@/feat/transaction/util';
import CustomSelectWithTooltip from './custom-select-time-expire';
import { useAtom } from 'jotai';
import { isErrorTimeExpireAtom } from '../../state';
import { calculateTimeDifference } from '../../utils';

export type PaymentRequestPaxProps = {
  actionType: ActionReservationType;
  editedReservationPaymentRequest: PaymentRequestEditProps;
  isTicketGuaranteed: boolean;
};

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 8,
    marginTop: theme.spacing(1),
    minWidth: 200,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      pt: '8px',
      pb: '4px',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function PaymentRequestOtherDetail({
  actionType,
  editedReservationPaymentRequest,
  isTicketGuaranteed,
}: PaymentRequestPaxProps & CardProps) {
  const { control, watch, setValue } =
    useReservationActionContext().reservationForm;
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isErrorTimeExpire] = useAtom(isErrorTimeExpireAtom);

  const watchPaymentStatus = watch('paymentStatus');
  const { capitalizeStatusLabel } = useTransactionUtils();

  const expireDateTime = editedReservationPaymentRequest?.expireDateTime || '';

  const { hoursDifference, minutesDifference } =
    calculateTimeDifference(expireDateTime);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const CustomMenuItem = ({
    paymentMode,
  }: {
    paymentMode: PaymentStatusResponse;
  }) => {
    return (
      <MenuItem
        disableRipple
        onClick={() => {
          setValue('paymentStatus', paymentMode);
          handleClose();
        }}
      >
        <Chip
          label={capitalizeStatusLabel(paymentMode)}
          variant="outlined"
          sx={{
            borderRadius: '8px',
            background:
              paymentMode === 'paid'
                ? 'rgba(78, 173, 19, 0.16) !important'
                : 'rgba(255, 149, 2, 0.16) !important ',
            color: paymentMode === 'paid' ? '#277C09' : '#B75B01',
            borderColor: 'transparent !important',
            fontWeight: 700,
          }}
        />
      </MenuItem>
    );
  };

  return (
    <Box sx={{ paddingBottom: '4px' }}>
      <Divider sx={{ marginBottom: '24px' }} />
      <Typography
        sx={{
          color: '#919EAB',
          fontSize: '12px',
          fontWeight: '700',
        }}
      >
        <Trans>PAYMENT STATUS</Trans>
      </Typography>
      <Box
        sx={{
          padding: '12px 0px',
        }}
      >
        <Typography
          sx={{
            color: '#212B36',
            fontSize: '14px',
            fontWeight: '600',
            marginBottom: '12px',
          }}
        >
          <Trans>Manual Payment Status</Trans>
        </Typography>

        <Controller
          control={control}
          name="paymentStatus"
          defaultValue="paid"
          render={({ field }) => (
            <Button
              {...field}
              id="payment-customized-button"
              aria-controls={open ? 'payment-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="contained"
              disableElevation
              disabled={
                actionType === 'Edit' &&
                (editedReservationPaymentRequest?.status === 'paid' ||
                  editedReservationPaymentRequest?.status === 'guaranteed')
              }
              onClick={handleClick}
              endIcon={<ArrowDropDownIcon />}
              style={{
                background:
                  watchPaymentStatus === 'paid'
                    ? 'rgba(78, 173, 19, 0.16)'
                    : 'rgba(255, 149, 2, 0.16)',
                color: watchPaymentStatus === 'paid' ? '#277C09' : '#B75B01',
              }}
            >
              {watchPaymentStatus && capitalizeStatusLabel(watchPaymentStatus)}
            </Button>
          )}
        />
        <StyledMenu
          id="customized-menu"
          MenuListProps={{
            'aria-labelledby': 'customized-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          <CustomMenuItem
            paymentMode={isTicketGuaranteed ? 'guaranteed' : 'paid'}
          />

          <CustomMenuItem paymentMode="pending" />
        </StyledMenu>

        <Box sx={{ paddingTop: '12px' }}>
          {watchPaymentStatus === 'paid' ||
          watchPaymentStatus === 'guaranteed' ? (
            <Typography
              sx={{
                color: '#212B36',
                fontSize: '14px',
                fontWeight: '400',
              }}
            >
              <Trans>
                Please verify guest payment or adjust the status to pending as
                required.
              </Trans>
            </Typography>
          ) : (
            <>
              <Box sx={{ paddingTop: '12px' }}>
                <PaymentAlert
                  mode={isErrorTimeExpire ? 'error' : 'secondary'}
                  title={isErrorTimeExpire ? '' : t('Status Update Reminder')}
                  description={
                    isErrorTimeExpire
                      ? t(
                          'Reservation timing is too close to the minimum validity period (30 minutes before). Please adjust the reservation time or choose manual payment.'
                        )
                      : t(
                          'Update the status when the guest pays to prevent reservation expiration and booking loss'
                        )
                  }
                />
              </Box>
              {actionType === 'Edit' ? (
                <Box sx={{ paddingTop: '12px' }}>
                  <Trans>Pending Reservation</Trans>

                  <Box sx={{ paddingTop: '6px' }}>
                    <Typography
                      sx={{
                        color: '#637381',
                        fontSize: '14px',
                        fontWeight: '500',
                      }}
                    >
                      {t(
                        ` Expire in {{hoursDifference}} hours {{minutesDifference}} minutes`,
                        {
                          hoursDifference,
                          minutesDifference,
                        }
                      )}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <>
                  <Box sx={{ padding: '12px 0 12px 0' }}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: '#212B36',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      <Trans>Holding Pending Reservation For</Trans>
                    </Typography>
                  </Box>
                  <CustomSelectWithTooltip />
                </>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

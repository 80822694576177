import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import { css } from '@emotion/react';
import Typography from '@mui/material/Typography';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import CustomerReservationInfor from '../customer-reservation-infor';
import { ReservationItem } from '@/common/types/reservation';
import { useCallback, useEffect, useMemo, useState } from 'react';
import CustomerNotifications from '../customer-notifications';
import {
  intervalToDuration,
  fromUnixTime,
  differenceInSeconds,
} from 'date-fns';
import { Trans, useTranslation } from 'next-i18next';
import { Box, Drawer, Grid, useTheme } from '@mui/material';
import {
  useMakeNotificationCall,
  useMerchantReservation,
  useReservation,
} from '@/common/hooks/use-reservations';
import LoadingScreen from '@/common/components/loading-screen';
import { AutoRsrvStatusBtn } from '@/common/components/reservation-status-btn';
import useCustomerNotificationSettings from '@/common/hooks/use-customer-notification-settings';
import { SecondaryButton } from '@/common/components/buttons';
import EditIcon from '@mui/icons-material/EditTwoTone';
import { TouchTooltip } from '@/common/components/tooltip';
import { isNotificationEnable } from '../../utils';
import React from 'react';
import TransactionDetail from '@/feat/transaction/transaction-detail';
import StickyBottomToolbar from '@/common/components/sticky-bottom-toolbar';
import { Stack } from '@mui/material';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import PhoneMissedOutlined from '@mui/icons-material/PhoneMissed';
import MailOutline from '@mui/icons-material/MailOutline';
import last from 'lodash/last';
import orderBy from 'lodash/orderBy';
import { LoadingButton } from '@mui/lab';
import {
  ConfirmationNotificationChannelType,
  ReservationNotificationResult,
} from '@/common/types/confirmation-notification';
import useEmitterEvent from '@/common/hooks/use-emitter-event';
import { globalEmitter } from '@/common/lib/global-emitter';
import CallingAnimation from '@/common/components/calling-animated';
import { getFormattedPhone } from '@/common/lib/phone-number';
import { useFeatureFlags } from '@/common/hooks/use-feature-flag';
import { ReservationDetailPanel } from './reservation-detail-panel';
import { DrawerTitle } from './drawer-title';
import { PaymentDetailsPanel } from './payment-details-panel';
import { ReservationUpdateLog } from './reservation-update-log';
import { MobileDrawerTitle } from './mobile-drawer-title';
import useI18nTimeUtils from '@/common/i18n-time-utils';
import { debounce } from 'lodash-es';
import PaymentRequestPanel from './payment-request-panel';
import { ConfirmationResendReservation } from '../confirmation/resend-reservation-confirmation';
import { usePwaOrMobileView } from '@/common/hooks/use-pwa';

const renderEditBtn = (
  reservation?: ReservationItem,
  onEdit?: ViewReservationModalProps['onEdit']
) => {
  const buttonDisabled = (
    <Button size="medium" variant="outlined" disabled>
      <EditTwoToneIcon sx={{ fontSize: '20px', mr: 1 }} />
      {reservation?.isWalkIn ? (
        <Trans>Edit Walk-in</Trans>
      ) : (
        <Trans>Edit Reservation</Trans>
      )}
    </Button>
  );

  if (!reservation?.isEditable) {
    if (reservation?.rTimeState === 'PAST')
      return (
        <TouchTooltip
          title={
            <Trans>Reservation is in the past and cannot be edited.</Trans>
          }
          arrow
          enterTouchDelay={0}
        >
          <div>{buttonDisabled}</div>
        </TouchTooltip>
      );
    return (
      <TouchTooltip
        title={
          <Trans>To edit reservation, update reservation status first.</Trans>
        }
        arrow
        enterTouchDelay={0}
      >
        <div>{buttonDisabled}</div>
      </TouchTooltip>
    );
  }
  return (
    <SecondaryButton
      variant="outlined"
      startIcon={<EditIcon sx={{ color: 'grey.600' }} />}
      onClick={() => {
        onEdit && onEdit(reservation?.id || '');
      }}
    >
      {reservation?.isWalkIn ? (
        <Trans>Edit Walk-in</Trans>
      ) : (
        <Trans>Edit Reservation</Trans>
      )}
    </SecondaryButton>
  );
};

export type ViewReservationModalProps = {
  close: () => void;
  reservationId: string | null;
  onEdit?: (reservationId: string) => void;
  onResend?: (data: ReservationItem) => void;
  onStatusChangeSuccess?: (newReservation: ReservationItem) => void;
  disableTransition?: boolean;
};
export default function ViewReservationModal({
  close,
  reservationId,
  onEdit,
  onResend,
  onStatusChangeSuccess,
  disableTransition,
}: ViewReservationModalProps) {
  const { t } = useTranslation();
  const isOpen = !!reservationId;
  const theme = useTheme();
  const [selectedReservationId, setSelectedReservationId] = useState<
    string | null
  >(null);
  const [isOpenModalConfirm, setIsOpenModalConfirm] = useState(false);
  const { isMobileView } = usePwaOrMobileView();
  const [reservationIdCache, setReservationIdCache] = useState(reservationId);

  useEffect(() => {
    if (reservationId) {
      setReservationIdCache(reservationId);
    } else {
      setSelectedReservationId(null);
    }
  }, [reservationId]);

  const {
    data: reservationData,
    isLoading,
    error,
    refetch,
  } = useReservation({
    reservationId: reservationId || reservationIdCache,
    config: { enabled: isOpen && !!reservationId },
  });

  const { smsNotify, emailNotify, automatedCallNotify } =
    useCustomerNotificationSettings(reservationData);

  const { data: dataMerchantReservation, isFetching: isFetchingMasterData } =
    useMerchantReservation({
      config: {
        enabled: isOpen,
      },
    });

  const makeCallOnDemandMutation = useMakeNotificationCall();

  const { confirmationNotificationEnabled } = useFeatureFlags();

  const lastConfirmationNotification = React.useMemo(() => {
    if (
      !reservationData ||
      !reservationData.confirmationNotifications ||
      !reservationData.confirmationNotifications.length
    ) {
      return null;
    }
    return last(
      orderBy(
        reservationData.confirmationNotifications.filter((it) => it.isSent),
        (it) => it.sendAt
      )
    );
  }, [reservationData]);

  const customerPhone = useMemo(() => {
    if (!reservationData) {
      return '';
    }
    return getFormattedPhone(
      reservationData.customerPhone || reservationData.user?.phone
    );
  }, [reservationData]);

  const isPhoneCallNotification = useMemo(() => {
    return (
      !!lastConfirmationNotification &&
      lastConfirmationNotification.channel ===
        ConfirmationNotificationChannelType.Phone
    );
  }, [lastConfirmationNotification]);

  const { compactDuration, formatDuration } = useI18nTimeUtils();

  const lastNotificationTime = useMemo(() => {
    if (!lastConfirmationNotification) {
      return null;
    }
    const notificationTime = fromUnixTime(lastConfirmationNotification.sendAt);
    const now = new Date();
    const diff = intervalToDuration({ start: notificationTime, end: now });

    if (diff.years) {
      const time = diff.years;
      return isPhoneCallNotification ? (
        <Trans>called {{ time }} year(s) ago</Trans>
      ) : (
        <Trans>sent {{ time }} year(s) ago</Trans>
      );
    } else if (diff.months) {
      const time = diff.months;
      return isPhoneCallNotification ? (
        <Trans>called {{ time }} month(s) ago</Trans>
      ) : (
        <Trans>sent {{ time }} month(s) ago</Trans>
      );
    } else if (diff.weeks) {
      const time = diff.weeks;
      return isPhoneCallNotification ? (
        <Trans>called {{ time }} week(s) ago</Trans>
      ) : (
        <Trans>sent {{ time }} week(s) ago</Trans>
      );
    } else if (diff.days) {
      const time = diff.days;
      return isPhoneCallNotification ? (
        <Trans>called {{ time }} day(s) ago</Trans>
      ) : (
        <Trans>sent {{ time }} day(s) ago</Trans>
      );
    } else if (diff.hours || diff.minutes) {
      // don't care about seconds
      diff.seconds = 0;
      const time = compactDuration(formatDuration(diff));
      return isPhoneCallNotification ? (
        <Trans>called {{ time }} ago</Trans>
      ) : (
        <Trans>sent {{ time }} ago</Trans>
      );
    } else {
      return isPhoneCallNotification ? (
        <Trans>called just now</Trans>
      ) : (
        <Trans>sent just now</Trans>
      );
    }
  }, [
    compactDuration,
    formatDuration,
    isPhoneCallNotification,
    lastConfirmationNotification,
  ]);

  const isMissedCallNotification = useMemo(() => {
    return (
      isPhoneCallNotification &&
      lastConfirmationNotification?.result ===
        ReservationNotificationResult.Missed
    );
  }, [isPhoneCallNotification, lastConfirmationNotification]);

  const phoneCallCount = useMemo(() => {
    if (
      lastConfirmationNotification?.channel !==
      ConfirmationNotificationChannelType.Phone
    ) {
      return 0;
    }
    return (
      reservationData?.confirmationNotifications?.filter(
        (it) =>
          it.channel === ConfirmationNotificationChannelType.Phone && it.isSent
      ).length || 0
    );
  }, [
    lastConfirmationNotification?.channel,
    reservationData?.confirmationNotifications,
  ]);

  const isCalling = useMemo(() => {
    const CALL_TIME_OUT = 300;
    return Boolean(
      isNotificationEnable(reservationData) &&
        lastConfirmationNotification &&
        isPhoneCallNotification &&
        !lastConfirmationNotification.result &&
        Math.abs(
          differenceInSeconds(
            fromUnixTime(lastConfirmationNotification.sendAt),
            new Date()
          )
        ) < CALL_TIME_OUT
    );
  }, [isPhoneCallNotification, lastConfirmationNotification, reservationData]);

  const allowNameNotify = useMemo(() => {
    if (!reservationData) {
      return null;
    }

    if (reservationData.allowEmailNotify && reservationData.allowSmsNotify) {
      return t('Email/SMS');
    } else if (reservationData.allowEmailNotify) {
      return t('Email');
    } else if (reservationData.allowSmsNotify) {
      return t('SMS');
    }

    return null;
  }, [reservationData, t]);

  const handleMakeCall = useCallback(() => {
    if (reservationData?.id && !isCalling) {
      makeCallOnDemandMutation.mutateAsync(reservationData.id);
    }
  }, [isCalling, makeCallOnDemandMutation, reservationData?.id]);

  const debouncedRefetch = useMemo(() => debounce(refetch, 1000), [refetch]);
  useEmitterEvent(globalEmitter, 'SINGLE_RESERVATION_UPDATED', (id) => {
    if (id === reservationId) {
      debouncedRefetch();
    }
  });
  useEmitterEvent(globalEmitter, 'SINGLE_TABLE_UPDATED', ({ tableId }) => {
    if (tableId === reservationData?.tables?.[0]?.id) {
      debouncedRefetch();
    }
  });
  const handleSubmitConfirm = () => {
    if (onResend) {
      setIsOpenModalConfirm(false);
      onResend(reservationData as ReservationItem);
    }
  };
  const handleCancelConfirm = () => {
    setIsOpenModalConfirm(false);
  };
  return (
    <>
      <Drawer
        transitionDuration={disableTransition ? 0 : undefined}
        open={isOpen}
        onClose={close}
        anchor="right"
        css={css`
          .MuiDrawer-paper {
            width: calc(100% - 80px);
            min-width: 880px;
            max-width: 1080px;
          }
          ${theme.breakpoints.down('md')} {
            .MuiDrawer-paper {
              min-width: 0;
              width: min(880px, 100%);
            }
          }
        `}
      >
        {isLoading || isFetchingMasterData || error ? (
          <LoadingScreen />
        ) : (
          <Box
            css={css`
              width: auto;
              display: flex;
              flex-direction: column;
              flex: 0 0 auto;
              height: 100%;
            `}
          >
            <DrawerTitle onClose={close} reservationData={reservationData} />
            <MobileDrawerTitle
              onClose={close}
              reservationData={reservationData}
            />
            <DialogContent
              dividers
              sx={{
                padding: '16px',
              }}
            >
              {reservationData && (
                <Box sx={{ mb: 2, display: { xs: 'none', md: 'block' } }}>
                  <AutoRsrvStatusBtn
                    reservationItem={reservationData}
                    onStatusChangeSuccess={(newData: ReservationItem) => {
                      refetch();
                      onStatusChangeSuccess && onStatusChangeSuccess(newData);
                    }}
                    showFullText
                  />
                </Box>
              )}
              <Grid container spacing={2}>
                <Grid item md={7} xs={12}>
                  <ReservationDetailPanel
                    reservationData={reservationData}
                    dataMerchantReservation={dataMerchantReservation}
                    onEdit={onEdit}
                  />

                  <PaymentDetailsPanel
                    onViewFullTransaction={() =>
                      setSelectedReservationId(reservationId)
                    }
                    reservationData={reservationData}
                  />

                  <ReservationUpdateLog
                    reservationData={reservationData}
                    dataMerchantReservation={dataMerchantReservation}
                  />
                </Grid>

                <Grid item md={5} xs={12}>
                  <Box mb="24px" display={{ xs: 'block', md: 'block' }}>
                    {reservationData?.user ? (
                      <CustomerReservationInfor
                        customerAtTimeOfReservation={reservationData.user}
                        action="SEE_DETAIL"
                        changeCustomerProvided={isMobileView ? true : false}
                        idCustomerToSee={reservationData?.user?.mcaId || ''}
                        redirectToEditModal={() => {
                          onEdit && onEdit(reservationData?.id || '');
                        }}
                        isEditCustomer={!reservationData?.isEditable}
                      />
                    ) : (
                      <CustomerReservationInfor
                        action="SEARCH"
                        isWalkInCustomerAdded={false}
                        requireCustomer={false}
                        redirectToEditModal={() => {
                          onEdit && onEdit(reservationData?.id || '');
                        }}
                      />
                    )}
                  </Box>
                  {reservationData?.source !== 'walkIn' && (
                    <CustomerNotifications
                      readOnlyPanel
                      emailEnabled={emailNotify}
                      smsEnabled={smsNotify}
                      automatedCallEnabled={automatedCallNotify}
                    />
                  )}
                  {reservationData?.reservationPaymentRequest && (
                    <Box sx={{ mt: '24px' }}>
                      <PaymentRequestPanel
                        dataReservation={reservationData}
                        onResendAction={() => setIsOpenModalConfirm(true)}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </DialogContent>
            <StickyBottomToolbar sx={{ display: { xs: 'none', md: 'flex' } }}>
              <Stack
                sx={{ flex: 1 }}
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                {confirmationNotificationEnabled &&
                lastConfirmationNotification ? (
                  <Stack flexDirection="row" alignItems="center" gap={0.5}>
                    {isMissedCallNotification ? (
                      <PhoneMissedOutlined fontSize="medium" color="error" />
                    ) : isPhoneCallNotification ? (
                      <CallOutlinedIcon fontSize="medium" color="success" />
                    ) : (
                      <MailOutline fontSize="medium" color="disabled" />
                    )}
                    <Typography
                      sx={{ color: theme.palette.text.disabled, fontSize: 13 }}
                    >
                      {isPhoneCallNotification ? (
                        customerPhone
                      ) : (
                        <Trans>{allowNameNotify}</Trans>
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        color: theme.palette.text.disabled,
                        fontSize: 13,
                      }}
                    >
                      {lastNotificationTime}
                    </Typography>
                    {isPhoneCallNotification && (
                      <Typography
                        sx={{
                          color: theme.palette.text.disabled,
                          fontSize: 13,
                        }}
                      >
                        ({phoneCallCount})
                      </Typography>
                    )}
                  </Stack>
                ) : (
                  <Box></Box>
                )}
                <Stack flexDirection="row" alignItems="center" gap={2}>
                  {renderEditBtn(reservationData, onEdit)}
                  {confirmationNotificationEnabled &&
                    isNotificationEnable(reservationData) && (
                      <LoadingButton
                        variant="contained"
                        loading={makeCallOnDemandMutation.isLoading}
                        sx={{ color: theme.palette.common.white }}
                        color="success"
                        startIcon={<CallOutlinedIcon />}
                        onClick={handleMakeCall}
                      >
                        {isCalling ? (
                          <Stack flexDirection="row" gap={1}>
                            <Trans>Calling</Trans>
                            <CallingAnimation />
                          </Stack>
                        ) : (
                          <Trans>Call Customer</Trans>
                        )}
                      </LoadingButton>
                    )}
                </Stack>
              </Stack>
            </StickyBottomToolbar>
          </Box>
        )}
      </Drawer>
      {reservationId && (
        <TransactionDetail
          isOpen={!!selectedReservationId}
          reservationId={reservationId}
          onClose={() => setSelectedReservationId(null)}
        />
      )}
      <ConfirmationResendReservation
        isOpen={isOpenModalConfirm}
        onClose={handleCancelConfirm || function () {}}
        onSubmit={handleSubmitConfirm || function () {}}
      />
    </>
  );
}
